import api from '../api';
import axios from 'axios';

//user: 07.191.228/0001-55
// user: 80.798.499/0001-63
// password: 123456


export const authenticate = () => {
  const login = async (user, password) => {
    if (user.length <= 0) {
      return;
    }

    const formatedUser = user.match(/\d+/g).join('');

    try {
      const res = await api.post('Login', {
        grant_type: 'password',
        userOrigin: 'Todas',
        username: formatedUser,
        password: password,
      });
      if (res.data.token) {
        localStorage.setItem('sementesToken', res.data.token);
        localStorage.setItem('party_id', res.data.party_id);
      }
      // return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    login,
  };
};

export default authenticate();

import React from 'react';
import Loader from 'react-loader-spinner';

import { LoaderArea } from './style';

export default function index() {
  return (
    <>
      <LoaderArea>
        <img src={require('../../assets/logo.svg')} alt="Sementes Batavo" />
        <Loader type="Oval" color="#adbf22" height={100} width={100} />
      </LoaderArea>
    </>
  );
}

import axios from 'axios';

const consulta_anexos_nfs = axios.create({
  baseURL:
    'https://frisiaintegration-idvjwsau7j9l-gr.integration.ocp.oraclecloud.com/ic/api/integration/v1/flows/rest/API_PORTAL_NOTAS_FISCAI_LINHAS/1.0',
  auth: {
    username: 'oic.invoke',
    password: 'Pa$$w0rdFr151@',
  },
});

export default consulta_anexos_nfs;

import styled, { keyframes } from 'styled-components';

const heartBeat = keyframes`
    0%, 100%, 40%, 60% {
        transform: scale(1);
    }
    20%, 80% {
        transform: scale(1.3);
    }
`;

export const Contato = styled.section`
  position: relative;
  padding-top: 150px;

  @media (max-width: 750px) {
    padding-top: 100px;
  }

  .title {
    padding-left: 9%;
    position: relative;
    z-index: 1;

    @media (max-width: 1160px) {
      padding-left: 5%;
    }

    @media (max-width: 750px) {
      padding-left: 20px;
    }

    h2 {
      margin-bottom: 20px;

      @media (max-width: 750px) {
        margin-bottom: 15px;
      }
    }

    p {
      font-size: 20px;
      line-height: 23px;
      color: #002762;
      margin: 0;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 19px;
      }

      span {
        font-weight: bold;
        color: #aabc00;
      }
    }
  }

  .formulario {
    padding: 50px 20px 110px 20px;
    background-color: #002762;
    position: relative;
    margin-top: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @media (max-width: 1160px) {
      align-items: center;
      flex-direction: column;
    }

    @media (max-width: 750px) {
      padding: 25px 20px 60px 20px;
      margin-top: 50px;
    }

    form {
      display: flex;
      flex-direction: column;
      width: 37%;
      position: relative;
      z-index: 1;

      @media (max-width: 1160px) {
        width: 100%;
        max-width: 695px;
      }

      span {
        color: red;
        font-size: 16px;
        line-height: 18px;
      }

      input,
      textarea {
        border-radius: 36px;
        border: none;
        background-color: #fff;
        font-size: 20px;
        line-height: 20px;
        font-family: 'Palanquin', sans-serif;
        color: #1d1d1d;
        margin-bottom: 3px;
        position: relative;
        margin-top: 22px;

        @media (max-width: 750px) {
          font-size: 16px;
          margin-top: 15px;
          line-height: 16px;
        }
      }

      input {
        padding: 0 25px;
        height: 70px;
        display: flex;
        align-items: center;

        @media (max-width: 750px) {
          height: 50px;
        }
      }

      textarea {
        padding: 15px 25px;
        height: 275px;
        resize: none;

        @media (max-width: 750px) {
          height: 200px;
        }
      }

      button {
        margin: auto;
        margin-top: 27px;
      }
    }

    .infos {
      margin-left: 80px;
      display: flex;
      justify-content: space-between;
      width: 42%;

      @media (max-width: 1581px) {
        width: 50%;
      }

      @media (max-width: 1160px) {
        margin-left: 0px;
        width: 80%;
        margin-top: 100px;
      }

      @media (max-width: 930px) {
        width: 100%;
      }

      @media (max-width: 750px) {
        margin-top: 60px;
      }

      @media (max-width: 590px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .logo {
        display: flex;
        width: 200px;
        height: auto;

        @media (max-width: 750px) {
          width: 150px;
        }

        img {
          object-fit: contain;
          width: 100%;
          height: auto;
        }
      }

      .line {
        width: 1px;
        height: 30px;
        background-color: #ffffff;
        position: relative;
        top: 15px;
        margin: 0 20px;

        @media (max-width: 590px) {
          width: 30px;
          height: 1px;
          margin: 20px 0;
          top: 0px;
        }
      }

      .endereco {
        display: flex;
        flex-direction: column;

        a,
        p {
          font-size: 18px;
          line-height: 20px;
          color: #fff;
          text-decoration: none;
          margin: 0;

          @media (max-width: 750px) {
            font-size: 16px;
            line-height: 18px;
          }
        }

        p {
          font-weight: bold;
        }

        a {
          transition: all ease 0.5s;

          :hover {
            color: #adbf00;
          }
        }
      }

      .social {
        a {
          font-size: 41px;
          color: #adbf00;
          transition: all ease 0.5s;
          margin: 0 5px;

          @media (max-width: 750px) {
            font-size: 30px;
          }

          :hover {
            color: #fff;
          }
        }
      }
    }
  }

  .bg-img {
    position: absolute;
    right: 0;
    z-index: 0;
    bottom: 330px;
    width: 67%;
    height: auto;

    @media (max-width: 1160px) {
      top: -125px;
      bottom: unset;
    }

    @media (max-width: 860px) {
      width: 60%;
    }

    @media (max-width: 590px) {
      top: -35px;
      width: 80%;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
`;

export const Direitos = styled.div`
  display: flex;
  padding: 35px 20px;
  max-width: 92%;
  margin: auto;
  justify-content: space-evenly;

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    max-width: 100%;
  }

  p,
  a {
    color: #000;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    margin: 0;
    font-family: 'Libre Franklin', sans-serif;
    font-weight: 300;

    @media (max-width: 750px) {
      font-size: 14px;
    }
  }

  a {
    @media (max-width: 900px) {
      margin-top: 15px;
    }
  }

  img {
    margin: 0 3px;
    height: 15px;

    animation-name: ${heartBeat};
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }
`;

import React from 'react';
import { Helmet } from 'react-helmet';
import {
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';

import InputMask from 'react-input-mask';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Container } from './styles';

import ademail from '../../../services/ademail';
import { notification } from 'antd';

import FixedHeader from '../../../components/Header/FixedHeader';
import Footer from '../../../components/FooterAreaRestrita/Footer';

function SolicitarConta() {
  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      cpf_cnpj: '',
      tipo_pessoa: '',
      mensagem: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('*Campo nome é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('*Campo e-mail é obrigatório'),
      cpf_cnpj: Yup.string().required('*Campo CPF/CNPJ é obrigatório'),
      tipo_pessoa: Yup.string().required('*Campo tipo de pessoa é obrigatório'),
      mensagem: Yup.string().required('*Campo mensagem é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
                        <p>Nome: ${values.nome}</p>
                        <p>CPF/CNPJ: ${values.cpf_cnpj}</p>
                        <p>Tipo de Pessoa: ${values.tipo_pessoa}</p>
                        <p>Mensagem: ${values.mensagem}</p>
                      `;

      const mail = {
        to: 'sementes@frisia.coop.br',
        from: values.email,
        as: values.nome,
        bcc: JSON.stringify(['web@agenciaade.com.br']),
        subject: '[Requisição de Conta Área Restrita] Sementes Batavo',
        message: body,
      };

      try {
        // Sucesso ao enviar
        await ademail.post('/email', mail);

        notification.success({
          message: 'Requisição enviada com sucesso.',
          placement: 'bottomRight',
        });

        resetForm();
      } catch (error) {
        // Erro ao enviar

        notification.error({
          message: 'Não foi possivel enviar a requisição',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>Sementes Batavo</title>
        <meta
          name="description"
          content="Conheça as nossas cultivares e leve mais qualidade para o seu
          plantio"
        />
        <meta
          name="keywords"
          content="sementes, batavo, frísia, agroindustria, agronegocio, soja, trigo"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot1.png'}
        />
      </Helmet>
      <FixedHeader />
      <Container>
        <div className="card">
          <h3>
            Nos envie uma mensagem e iremos analisar a criação do seu cadastro.
          </h3>

          <form onSubmit={formik.handleSubmit}>
            <TextField
              label="Nome"
              type="text"
              name="nome"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.nome}
            />
            {formik.touched.nome && formik.errors.nome ? (
              <span>{formik.errors.nome}</span>
            ) : null}

            <TextField
              label="Email"
              type="text"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <span>{formik.errors.email}</span>
            ) : null}

            <InputMask
              name="cpf_cnpj"
              mask={
                formik.values.cpf_cnpj.length <= 14
                  ? '999.999.999-99?'
                  : '99.999.999/999-99'
              }
              maskChar={null}
              formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
              value={formik.values.cpf_cnpj}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {(inputProps) => (
                <TextField {...inputProps} type="text" label="CPF/CNPJ" />
              )}
            </InputMask>
            {formik.touched.cpf_cnpj && formik.errors.cpf_cnpj ? (
              <span>{formik.errors.cpf_cnpj}</span>
            ) : null}

            <RadioGroup
              aria-label="tipo-de-pessoa"
              row
              required
              name="tipo_pessoa"
              value={formik.values.tipo_pessoa}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <FormControlLabel
                value="Pessoa Física"
                control={<Radio color="primary" />}
                label="Pessoa Física"
              />
              <FormControlLabel
                value="Pessoa Jurídica"
                control={<Radio color="primary" />}
                label="Pessoa Jurídica"
              />
            </RadioGroup>

            <TextField
              label="Mensagem"
              multiline
              minRows="2"
              name="mensagem"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mensagem}
            />
            {formik.touched.mensagem && formik.errors.mensagem ? (
              <span>{formik.errors.mensagem}</span>
            ) : null}

            <div className="button-container">
              <button type="submit">Enviar</button>
              <a href="/area-restrita">Voltar</a>
            </div>
          </form>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default SolicitarConta;

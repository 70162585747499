import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 74px;

  @media (max-width: 1200px) {
    padding-top: 92.17px;
  }

  @media (max-width: 815px) {
    padding-top: 82.17px;
  }

  @media (max-width: 750px) {
    padding-top: 76.61px;
  }

  .banner {
    height: 300px;
    width: 100%;

    @media (max-width: 750px) {
      height: 200px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  p {
    font-size: 20px;
    line-height: 37px;
    color: #1d1d1d;
    margin-bottom: 40px;

    @media (max-width: 750px) {
      font-size: 16px;
      margin-bottom: 25px;
      line-height: 33px;
    }
  }
`;

export const Sobre = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 65px 20px 125px 20px;

  h2 {
    text-align: center;
  }

  p {
    max-width: 1050px;
    text-align: center;
    margin-bottom: 110px;

    @media (max-width: 750px) {
      margin-bottom: 60px;
    }
  }

  .carousel {
    width: 85%;
    padding: 65px 0;
    position: relative;

    @media (max-width: 1440px) {
      width: 95%;
    }

    @media (max-width: 1000px) {
      width: 100%;
    }

    @media (max-width: 900px) {
      padding: 0 20px;
    }

    .bg {
      position: absolute;
      height: 100%;
      width: 80%;
      background-color: #f8fbfd;
      border-radius: 50px;
      top: 0;
      right: 0;
      z-index: -1;

      @media (max-width: 900px) {
        width: 100%;
        height: 75%;
        bottom: 0;
        top: unset;
      }

      @media (max-width: 500px) {
        height: 85%;
      }
    }

    .btns {
      position: absolute;
      left: calc(44% + 90px);
      bottom: 65px;

      @media (max-width: 1440px) {
        left: calc(44% + 50px);
      }

      @media (max-width: 1000px) {
        left: calc(44% + 30px);
      }

      @media (max-width: 900px) {
        bottom: 25px;
        left: 30px;
      }

      @media (max-width: 750px) {
        left: 0;
      }

      button {
        height: fit-content;
        border: none;
        background-color: transparent;
        cursor: pointer;
        position: relative;
        z-index: 2;
        margin: 0 20px;

        :hover {
          svg {
            fill: #afc007;
          }
        }

        svg {
          height: 45px;
          width: auto;
          fill: #002762;
          transition: all ease 0.5s;
        }
      }
    }

    .slider {
      display: flex !important;
      align-items: center;

      @media (max-width: 900px) {
        flex-direction: column;
      }

      .img {
        border-radius: 50px;
        width: 44%;
        height: 430px;

        @media (max-width: 900px) {
          width: 80%;
        }

        @media (max-width: 750px) {
          height: 300px;
        }

        @media (max-width: 500px) {
          width: 90%;
          height: 200px;
        }

        img {
          border-radius: 50px;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .text {
        padding: 0 80px 50px 110px;
        width: 56%;

        @media (max-width: 1440px) {
          padding: 0 80px 50px 80px;
        }

        @media (max-width: 1000px) {
          padding: 0 50px 50px 50px;
        }

        @media (max-width: 900px) {
          width: 100%;
          padding: 100px 50px;
        }

        @media (max-width: 750px) {
          padding: 50px 20px 100px 20px;
        }

        @media (max-width: 500px) {
          padding: 50px 0px 100px 0px;
        }

        h5 {
          font-size: 100px;
          line-height: 80px;
          margin-bottom: 55px;

          @media (max-width: 750px) {
            margin-bottom: 35px;
            line-height: 50px;
            font-size: 70px;
          }
        }

        p {
          margin-bottom: 0;
          text-align: start;
        }
      }
    }
  }
`;

export const Curiosidades = styled.section`
  width: 100%;
  position: relative;
  padding-bottom: 200px;

  @media (max-width: 750px) {
    padding-bottom: 120px;
  }

  .text {
    width: 75%;
    background-color: #fff;
    border-radius: 50px;
    padding: 75px 125px 55px 125px;
    margin: auto;

    @media (max-width: 1440px) {
      padding: 75px 80px 55px 80px;
    }

    @media (max-width: 1100px) {
      width: 90%;
    }

    @media (max-width: 900px) {
      width: 95%;
    }

    @media (max-width: 750px) {
      padding: 0px 20px 45px 20px;
    }

    .line {
      width: 100%;
      height: 4px;
      background-color: #adbf00;
    }
  }

  .bg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 83%;
    z-index: -1;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;

export const Images = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  margin-bottom: 250px;
  padding: 0 20px;

  @media (max-width: 750px) {
    margin-top: 60px;
    margin-bottom: 50px;
  }

  .images {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(3, 340px);
    grid-auto-rows: 350px;
    grid-gap: 25px;

    @media (max-width: 1110px) {
      grid-template-columns: repeat(2, 340px);
    }

    @media (max-width: 750px) {
      margin-top: 30px;
      grid-template-columns: repeat(2, 48%);
      grid-auto-rows: 250px;
    }

    @media (max-width: 545px) {
      grid-template-columns: 100%;
    }

    > div {
      width: 100%;
      height: 100%;
      cursor: pointer;
      overflow: hidden;

      :hover {
        img {
          transform: scale(1.2);
        }
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: all ease 0.5s;
      }
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1200px) {
    padding-top: 92.17px;
  }

  @media (max-width: 815px) {
    padding-top: 82.17px;
  }

  @media (max-width: 750px) {
    padding-top: 76.61px;
  }

  .banner {
    height: 300px;
    width: 100%;
    margin-bottom: 65px;

    @media (max-width: 750px) {
      height: 200px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  h2,
  h5 {
    padding: 0 20px;
    text-align: center;
  }

  p {
    font-size: 20px;
    line-height: 37px;
    color: #1d1d1d;
    margin-bottom: 40px;

    @media (max-width: 750px) {
      font-size: 16px;
      margin-bottom: 25px;
      line-height: 33px;
    }
  }

  .line {
    width: 72%;
    height: 4px;
    background-color: #adbf00;
    position: absolute;
    bottom: 0;
    margin: 0 14%;

    @media (max-width: 600px) {
      width: 85%;
    }
  }

  .secao-impar {
    display: flex;
    padding: 125px 0px 80px 20px;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    @media (max-width: 1150px) {
      flex-direction: column-reverse;
      justify-content: center;
    }

    @media (max-width: 750px) {
      padding: 60px 0px 40px 20px;
    }

    .text {
      width: 40%;
      padding-right: 60px;

      @media (max-width: 1440px) {
        width: 50%;
      }

      @media (max-width: 1150px) {
        width: 75%;
        margin-top: 50px;
        padding-right: 20px;
      }

      @media (max-width: 600px) {
        width: 100%;
      }

      h2 {
        text-align: start;
        padding: 0;
      }
    }

    .img {
      width: 45%;

      @media (max-width: 1150px) {
        align-self: flex-end;
        width: 55%;
      }

      @media (max-width: 550px) {
        width: 80%;
      }

      img {
        object-fit: contain;
        width: 100%;
        height: auto;
      }
    }

    .line {
      @media (max-width: 600px) {
        right: -20px;
      }
    }
  }

  .secao-par {
    display: flex;
    padding: 125px 20px 80px 0px;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    @media (max-width: 1150px) {
      justify-content: center;
      flex-direction: column;
    }

    @media (max-width: 750px) {
      padding: 60px 20px 40px 0px;
    }

    .text {
      width: 40%;
      padding-left: 60px;

      @media (max-width: 1440px) {
        width: 50%;
      }

      @media (max-width: 1150px) {
        width: 75%;
        margin-top: 50px;
        padding-left: 20px;
      }

      @media (max-width: 600px) {
        width: 100%;
      }

      .sementes {
        max-width: 520px;

        @media (max-width: 750px) {
          max-width: 370px;
        }
      }

      h2 {
        max-width: 625px;
        text-align: start;
        padding: 0;

        @media (max-width: 750px) {
          max-width: 470px;
        }
      }
    }

    .img {
      width: 45%;

      @media (max-width: 1150px) {
        align-self: flex-start;
        width: 55%;
      }

      @media (max-width: 550px) {
        width: 80%;
      }

      img {
        object-fit: contain;
        width: 100%;
        height: auto;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }
    }

    .line {
      @media (max-width: 600px) {
        left: -20px;
      }
    }
  }
`;

export const Vantagens = styled.section`
  margin-top: 175px;
  margin-bottom: 195px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 750px) {
    margin-bottom: 50px;
    margin-top: 100px;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(4, 19%);
    grid-auto-rows: 330px;
    grid-column-gap: 15px;
    grid-row-gap: 50px;
    width: 100%;
    margin-top: 130px;
    justify-content: center;

    @media (max-width: 1530px) {
      grid-template-columns: repeat(4, 23%);
    }

    @media (max-width: 1000px) {
      grid-template-columns: repeat(3, 30%);
    }

    @media (max-width: 770px) {
      grid-template-columns: repeat(2, 46%);
    }

    @media (max-width: 750px) {
      margin-top: 70px;
      grid-auto-rows: 280px;
    }

    @media (max-width: 500px) {
      grid-template-columns: 100%;
      grid-auto-rows: 250px;
      padding: 0 20px;
    }

    div:nth-child(9) {
      grid-area: 3 / 2 / span 1 / span 1;

      @media (max-width: 1000px) {
        grid-area: 4 / 2 / span 1 / span 1;
      }

      @media (max-width: 770px) {
        grid-area: initial;
      }
    }

    div:last-child {
      grid-area: 3 / 3 / span 1 / span 1;

      @media (max-width: 1000px) {
        grid-area: initial;
      }
    }

    > div {
      width: 100%;
      height: 100%;
      background-color: #f8fbfd;
      border-radius: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 80px 20px 25px 20px;
      position: relative;

      @media (max-width: 750px) {
        padding: 40px 20px 20px 20px;
      }

      .detail {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -27px;
        width: 100%;
        z-index: 1;

        svg {
          height: 52px;
          width: 57px;
          fill: #adbf00;
          background-color: #fff;
        }

        .line {
          height: 8px;
          width: 75%;
          background-color: #002762;
          border-radius: 20px;
          position: absolute;
          top: 47%;
          z-index: -1;
          margin: 0;
        }
      }

      p {
        max-width: 275px;
        text-align: center;
      }
    }
  }
`;

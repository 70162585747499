import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  InputAdornment,
  IconButton,
  Checkbox,
  Button,
  FormControlLabel,
} from '@material-ui/core';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

import InputMask from 'react-input-mask';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Container } from './styles';

import FixedHeader from '../../components/Header/FixedHeader';
import Footer from '../../components/FooterAreaRestrita/Footer';

import authenticate from '../../services/api_externa/login';

function AreaRestrita() {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessageError, setAlertMessageError] = useState();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      login: '',
      senha: '',
    },

    validationSchema: Yup.object({
      login: Yup.string().required('*Campo de CPF/CNPJ é obrigatório'),
      senha: Yup.string().required('*Informe sua senha'),
    }),
  });

  const handleSubmit = async () => {
    setLoading(true);
    await authenticate
      .login(formik.values.login, formik.values.senha)
      .then((response) => {
        setLoading(false);
        history.push('/area-restrita/home');
      });
  };

  const formatCpfOrCnpj = (value) => {
    const numericValue = value.replace(/\D/g, '');
    if (numericValue.length <= 11) {
      return numericValue.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        '$1.$2.$3-$4'
      );
    } else {
      return numericValue.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Sementes Batavo</title>
        <meta
          name="description"
          content="Conheça as nossas cultivares e leve mais qualidade para o seu
          plantio"
        />
        <meta
          name="keywords"
          content="sementes, batavo, frísia, agroindustria, agronegocio, soja, trigo"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot1.png'}
        />
      </Helmet>
      <FixedHeader />
      <Container>
        <div className="card">
          <h3>
            Bem-vindo ao portal do cliente!
            <br /> Por favor faça o login para acessar sua conta.
          </h3>

          <form onSubmit={handleSubmit}>
            {/* <InputMask
              name="login"
              mask={
                formik.values.login.length < 15
                  ? '999.999.999-99?'
                  : '99.999.999/9999-99'
              }
              maskChar={null}
              formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
              value={formik.values.login}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {(inputProps) => (
                <TextField {...inputProps} type="text" label="CPF/CNPJ" />
              )}
            </InputMask> */}
            <TextField
              type="text"
              value={formik.values.login}
              onChange={(e) => {
                const formattedValue = formatCpfOrCnpj(e.target.value);
                formik.setFieldValue('login', formattedValue);
              }}
              onBlur={formik.handleBlur}
              label="CPF/CNPJ"
            />
            <TextField
              type={showPassword ? 'text' : 'password'}
              name="senha"
              label="Senha"
              value={formik.values.senha}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="alterar visibilidade da senha"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {alertMessageError ? (
              <p style={{ color: 'red' }}>{alertMessageError}</p>
            ) : (
              ''
            )}

            <a href="/area-restrita/esqueci-a-senha">
              Pirmeiro acesso ou esqueceu a senha? Clique aqui!
            </a>
            <div className="password-container">
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Lembrar minha senha"
              />
            </div>

            <FormControlLabel
              control={<Checkbox color="primary" />}
              label={
                <span className="termos-text">
                  Termos de uso e{' '}
                  <a href="/" target="_blank">
                    Política de privacidade
                  </a>
                </span>
              }
            />

            <div className="button-container">
              <Button type="button" onClick={handleSubmit}>
                {loading ? 'Aguarde...' : 'Entrar'}
              </Button>
              {/* <a href="/area-restrita/solicitar-conta">Solicitar conta</a> */}
            </div>
          </form>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default AreaRestrita;

import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  @media (max-width: 1200px) {
    padding-top: 92.17px;
  }

  @media (max-width: 815px) {
    padding-top: 82.17px;
  }

  @media (max-width: 750px) {
    padding-top: 76.61px;
    margin-bottom: 0px;
  }

  .banner {
    height: 300px;
    width: 100%;
    margin-bottom: 65px;

    @media (max-width: 750px) {
      height: 200px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  h2 {
    padding: 0 20px;
    text-align: center;
  }

  .tipo-cultivares {
    display: grid;
    grid-template-columns: repeat(2, 42%);
    grid-gap: 20px;
    position: relative;
    grid-auto-rows: 215px;
    width: 100%;
    justify-content: center;
    margin-top: 100px;
    position: relative;
    z-index: 1;
    padding: 0 20px;
    margin-bottom: 160px;

    @media (max-width: 750px) {
      margin-top: 50px;
      grid-auto-rows: 150px;
      margin-bottom: 20px;
      grid-template-columns: repeat(2, 48%);
    }

    a {
      position: relative;
      z-index: 11;
      width: 100%;
      border-radius: 30px;
      height: auto;
      overflow: hidden;
      border: none;
      background-color: transparent;
      cursor: pointer;

      .show {
        opacity: 1 !important;
      }

      :hover {
        .img {
          transform: scale(1.2);
        }
      }

      .img {
        width: 100%;
        height: 100%;
        transition: all ease 0.5s;

        img {
          border-radius: 30px;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .texto {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        background-color: rgba(0, 0, 0, 0.6);
        top: 0;
        left: 0;
        opacity: 1;
        transition: all ease 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-size: 90px;
          color: #fff;
          font-weight: bold;
          margin: 0;
          position: relative;
          z-index: 1;
          bottom: 30px;

          @media (max-width: 750px) {
            font-size: 65px;
          }

          @media (max-width: 500px) {
            font-size: 45px;
          }

          @media (max-width: 370px) {
            font-size: 35px;
          }

          :after {
            content: '';
            position: absolute;
            height: 80px;
            width: 235px;
            background-color: #afc007;
            border-radius: 40px;
            bottom: -30px;
            left: -1px;
            z-index: -1;

            @media (max-width: 750px) {
              height: 40px;
              bottom: -5px;
              width: 120%;
            }

            @media (max-width: 500px) {
              height: 30px;
            }

            @media (max-width: 370px) {
              bottom: 0px;
              height: 20px;
            }
          }
        }
      }
    }
  }

  .novostyle {
    background-color: #f8fbfd;
  }

  .mapa {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 85px 20px;
    padding-bottom: 20px;

    @media (max-width: 750px) {
      margin-bottom: 0px;
      padding: 50px 20px;
      margin-top: 50px;
    }

    @media (max-width: 1050px) {
      flex-direction: column-reverse;
      align-items: center;
    }

    .newtitle2 {
      display: inline-block !important;

      @media (max-width: 1050px) {
        display: none !important;
      }
    }

    .newtitle {
      display: none;

      @media (max-width: 1050px) {
        display: inline-block;
      }
    }

    .titulo {
      display: none;
      font-size: 54px;
      line-height: 60px;
      font-weight: bold;
      color: #002762;
      margin-bottom: 50px;
      max-width: 500px;

      span {
        color: #adbf00;
      }

      @media (max-width: 1050px) {
        display: inline-block;
        align-self: flex-start;
        position: relative;
        left: 5%;
      }

      @media (max-width: 750px) {
        font-size: 20px;
        line-height: 19px;
        max-width: 350px;
        left: 0;
      }
    }

    .infos {
      width: 37%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1050px) {
        width: 60%;
        margin-bottom: 50px;
      }

      @media (max-width: 750px) {
        width: 80%;
      }

      @media (max-width: 450px) {
        width: 100%;
      }

      svg {
        object-fit: contain;
        width: 100%;
      }

      /* .hover {
        fill: #adbf00;
      } */

      .bg {
        background-color: #f8fbfd;
        border-radius: 50px;
        position: absolute;
        width: 80%;
        height: 70%;
        z-index: -1;
      }
    }

    .text {
      margin-top: -110px;

      @media (max-width: 1050px) {
        width: 100%;
        margin-right: 0;
      }

      @media (max-width: 750px) {
        padding-top: 90px;
        margin-top: -90px;
      }

      .descricao {
        font-size: 20px;
        line-height: 27px;
        color: #1d1d1d;
        margin: 40px 0;

        @media (max-width: 1050px) {
          left: 5%;
          position: relative;
        }

        @media (max-width: 750px) {
          left: 0%;
          font-size: 18px;
          line-height: 25px;
        }
      }

      /* p {
        display: inline-block;

        @media (max-width: 1050px) {
          display: none;
        }
      } */

      h2 {
        position: relative;
        width: fit-content;
        margin: 0;
        padding: 0 0 20px 0;

        :after {
          content: '';
          position: absolute;
          width: 60%;
          height: 4px;
          background-color: #adbf00;
          bottom: 0;
          left: 0;
        }

        @media (max-width: 1050px) {
          align-self: flex-start;
          position: relative;
          left: 5%;
        }

        @media (max-width: 750px) {
          left: 0;
        }
      }

      .erro {
        margin: 0;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: #002762;
        font-weight: bold;
        display: inline-block;
      }

      .scroll {
        background-color: #fff;
        padding: 30px 25px;
        border-radius: 50px;

        @media (max-width: 470px) {
          width: 100%;
        }
      }

      .cultivares-encontrados {
        max-height: 635px;
        overflow-y: scroll;
        position: relative;
        padding-right: 25px;
        min-height: 170px;

        ::-webkit-scrollbar-track {
          border-radius: 50px;
          background-color: #f8fbfd;
        }
        ::-webkit-scrollbar {
          width: 10px;
          background: #f8fbfd;
          border-radius: 50px;
        }
        ::-webkit-scrollbar-thumb {
          border-radius: 50px;
          background: #002762;
        }
      }

      .cards {
        display: grid;
        grid-template-columns: repeat(7, 165px);
        grid-auto-rows: 165px;
        grid-column-gap: 15px;
        grid-row-gap: 35px;

        @media (max-width: 1340px) {
          grid-template-columns: repeat(5, 165px);
        }

        @media (max-width: 1050px) {
          grid-template-columns: repeat(4, 165px);
          justify-content: center;
        }

        @media (max-width: 830px) {
          grid-template-columns: repeat(3, 165px);
        }

        @media (max-width: 650px) {
          grid-template-columns: repeat(2, 165px);
        }

        @media (max-width: 470px) {
          grid-template-columns: repeat(1, 100%);
        }

        .newborder {
          border: 2px solid #002762;
        }

        > a {
          transition: all ease 0.5s;
          border-radius: 25px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 5px;

          :hover {
            border: 2px solid #002762;
          }

          .img {
            width: 130px;
            border-radius: 25px;
            height: auto;

            img {
              object-fit: contain;
              width: 100%;
              height: auto;
            }
          }

          p {
            margin: 0;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            color: #002762;
            margin-top: 8px;
            font-weight: bold;
            display: inline-block;
          }
        }
      }
    }
  }
`;

export const Caracteristicas = styled.section`
  margin-bottom: 195px;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;

  @media (max-width: 1200px) {
    padding-top: 92.17px;
  }

  @media (max-width: 815px) {
    padding-top: 82.17px;
  }

  @media (max-width: 750px) {
    padding-top: 76.61px;
    margin-bottom: 50px;
  }

  .logo {
    display: flex !important;
    height: 316px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 25px;
    padding: 60px;
    margin: auto;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    width: 85%;

    img {
      object-fit: contain;
      height: 100%;
    }

    p {
      font-size: 35px;
      line-height: 35px;
      font-weight: bold;
      color: #002762;
      margin: 0;
      text-align: center;

      @media (max-width: 750px) {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }

  .infos {
    width: 85%;
    background-color: #f8fbfd;
    border-radius: 50px;
    margin: auto;
    padding: 85px 20px;
    position: relative;

    @media (max-width: 1650px) {
      width: 100%;
    }

    @media (max-width: 750px) {
      padding: 50px 15px;
    }

    .backbtn {
      position: absolute;
      width: 55px;
      height: 55px;
      border-radius: 100%;
      background-color: #adbf00;
      bottom: -27.5px;
      border: none;
      margin-left: calc(50% - 27.5px);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 30px;
        height: auto;
        fill: #fff;
      }
    }

    .video {
      display: inline-block;
      margin-top: 20px;
      width: 100%;
      background-color: #fff;
      border-radius: 25px;
      padding: 60px 40px;
      height: 943px;

      @media (max-width: 1440px) {
        padding: 40px 20px;
        height: 822px;
      }

      @media (max-width: 1250px) {
        height: 715px;
      }

      @media (max-width: 1000px) {
        height: 575px;
      }

      @media (max-width: 750px) {
        padding: 25px 10px;
        height: 421px;
      }

      @media (max-width: 600px) {
        height: 337px;
      }

      @media (max-width: 500px) {
        height: 281px;
      }

      @media (max-width: 425px) {
        height: 239px;
      }

      @media (max-width: 375px) {
        height: 211px;
      }
    }

    > div {
      display: grid;
      grid-template-columns: 29% 25% 39%;
      grid-gap: 20px;
      justify-content: center;
      margin: auto;
      position: relative;

      @media (max-width: 1650px) {
        grid-template-columns: 30% 26% 40%;
      }

      @media (max-width: 1250px) {
        grid-template-columns: 46% 50%;
      }

      @media (max-width: 750px) {
        grid-template-columns: 100%;
      }

      > div {
        width: 100%;
        background-color: #fff;
        border-radius: 25px;
        padding: 60px 40px;
        height: 100%;

        @media (max-width: 1440px) {
          padding: 40px 20px;
        }

        @media (max-width: 750px) {
          padding: 25px 10px;
        }

        .title {
          font-size: 30px;
          line-height: 28px;
          color: #002762;
          font-weight: bold;
          margin-bottom: 30px;

          @media (max-width: 1440px) {
            font-size: 25px;
            line-height: 23px;
          }
        }

        ul {
          list-style: none;
        }

        li {
          position: relative;
          padding-left: 20px;

          svg {
            width: 30px;
            height: auto;
            position: absolute;
            left: -10px;
          }
        }

        p,
        li {
          margin: 0;
          font-size: 18px;
          line-height: 30px;
          color: #1d1d1d;

          @media (max-width: 1440px) {
            font-size: 16px;
            line-height: 28px;
          }
        }
      }

      .pontos-fortes {
        @media (max-width: 1250px) {
          grid-area: 2 / 1 / span 1 / span 1;
        }

        @media (max-width: 750px) {
          grid-area: 3 / 1 / span 1 / span 1;
        }
      }

      .doencas {
        @media (max-width: 1250px) {
          grid-area: 3 / 1 / span 1 / span 1;
        }

        @media (max-width: 750px) {
          grid-area: 4 / 1 / span 1 / span 1;
        }
      }

      .estado {
        grid-area: 1 / 3 / span 1 / span 1;

        @media (max-width: 1250px) {
          grid-area: 1 / 1 / span 1 / span 2;
        }

        @media (max-width: 750px) {
          grid-area: 2 / 1 / span 1 / span 1;
        }

        svg {
          max-height: 450px;
          width: 100%;
          margin: auto;
          margin-top: -4px;
          margin-bottom: -44px;
          @media (max-width: 750px) {
            margin-top: 40px;
          }
        }
      }

      .infos-semente {
        @media (max-width: 1250px) {
          grid-area: 3 / 2 / span 1 / span 1;
        }

        @media (max-width: 750px) {
          grid-area: 5 / 1 / span 1 / span 1;
        }

        .cards {
          display: grid;
          grid-template-columns: repeat(2, 46%);
          grid-gap: 20px;

          .campo {
            font-size: 15px;
            line-height: 19px;
            font-weight: bold;
            color: #fff;
            width: 100%;
            border-radius: 25px;
            background-color: #adbf00;
            text-align: center;
            margin: 0;
            padding: 8px 5px;
            text-transform: uppercase;
          }

          > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
      }

      .epoca {
        grid-area: 2 / 1 / span 1 / span 3;

        @media (max-width: 1250px) {
          grid-area: 4 / 1 / span 1 / span 2;
        }

        @media (max-width: 750px) {
          grid-area: 6 / 1 / span 1 / span 1;
        }

        .title {
          text-align: center;
        }

        .ant-table-cell {
          border: 1px solid #000;
        }

        .ant-table-thead {
          > tr > th {
            padding: 10px;
            font-size: 18px;
            line-height: 19px;
            color: #002762;
            font-weight: bold;
            text-align: center;
            background-color: transparent;

            @media (max-width: 1440px) {
              font-size: 17px;
              line-height: 18px;
            }
          }
        }

        .numero {
          width: 30px;
          padding: 0 !important;
        }

        .ant-table-tbody {
          > tr > td {
            font-size: 16px;
            line-height: 16px;
            color: #000;
            padding: 6px 10px;
            text-align: center;

            @media (max-width: 1440px) {
              font-size: 15px;
              line-height: 15px;
            }
          }

          .numero {
            position: relative;

            > div {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            }
          }
        }

        .legenda {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 30px;

          @media (max-width: 420px) {
            flex-direction: column;
          }

          .tolerada {
            display: flex;
            margin-right: 30px;
            align-items: center;

            @media (max-width: 420px) {
              margin-right: 0px;
              margin-bottom: 20px;
              width: 122.34px;
            }

            > div {
              width: 30px;
              height: 30px;
              background-color: #adbf00;
              margin-right: 10px;
            }
          }

          .preferencial {
            display: flex;
            align-items: center;

            > div {
              width: 30px;
              height: 30px;
              background-color: #002762;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
`;

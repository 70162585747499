import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Button,
  // TextField,
  // InputAdornment,
} from '@material-ui/core';
import {
  MdNotifications,
  MdNotificationsNone,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  // MdSearch,
  MdChat,
  MdChatBubbleOutline,
} from 'react-icons/md';
import { BsDot } from 'react-icons/bs';
import { RiCloseCircleFill } from 'react-icons/ri';

import { Container, useStyles } from './styles';

function Navbar({ isIndicadores, dadosCadastrais }) {
  const anchorRef1 = useRef();
  const anchorRef2 = useRef();
  const anchorRef3 = useRef();
  const anchorRef4 = useRef();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const [notifications, setNotifications] = useState(true);
  const [messages, setMessages] = useState(true);
  const classes = useStyles();
  const history = useHistory();

  const handleToggle = (position) => {
    switch (position) {
      case 1:
        setOpen1(!open1);
        setOpen2(false);
        setOpen3(false);
        setOpen4(false);
        break;
      case 2:
        setOpen1(false);
        setOpen2(!open2);
        setOpen3(false);
        setOpen4(false);
        break;
      case 3:
        setOpen1(false);
        setOpen2(false);
        setOpen3(!open3);
        setOpen4(false);
        break;
      case 4:
        setOpen1(false);
        setOpen2(false);
        setOpen3(false);
        setOpen4(!open4);
        break;
      default:
        return;
    }
  };

  const handleClose = (event) => {
    if (anchorRef1.current && anchorRef1.current.contains(event.target)) {
      return;
    }
    if (anchorRef2.current && anchorRef2.current.contains(event.target)) {
      return;
    }
    if (anchorRef3.current && anchorRef3.current.contains(event.target)) {
      return;
    }
    if (anchorRef4.current && anchorRef4.current.contains(event.target)) {
      return;
    }

    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
  };

  const handleExit = () => {
    localStorage.removeItem('token');
    history.push('/area-restrita');
  };

  const handleToAccount = () => {
    history.push('/area-restrita/cadastro');
  };

  const handleToContract = () => {
    history.push('/area-restrita/contrato');
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen1(false);
      setOpen2(false);
      setOpen3(false);
      setOpen4(false);
    }
  }

  const prevOpen = useRef(open3);
  const prevChat = useRef(open2);

  useEffect(() => {
    if (prevOpen.current === true && open3 === false) {
      anchorRef3.current.focus();
      setNotifications(!notifications);
    }
    prevOpen.current = open3;
    if (prevChat.current === true && open2 === false) {
      anchorRef2.current.focus();
      setMessages(!messages);
    }
    prevChat.current = open2;
  }, [open3, notifications, open2, messages]);

  let notificationsArr = [
    {
      id: 0,
      text: 'Você não possui notificações no momento.',
      read: false,
    },
  ];

  return (
    <>
      <Container>
        <div className="container-indicadores">
          <div className="options-container">
            <div className="chat">
              <IconButton ref={anchorRef2} onClick={() => handleToggle(2)}>
                {messages ? (
                  <MdChat className={classes.iconSupport} />
                ) : (
                  <MdChatBubbleOutline className={classes.iconSupport} />
                )}
              </IconButton>
              <Popper
                open={open2}
                anchorEl={anchorRef2.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <div className="container-chat">
                        <div className="text-chat-header">
                          <h1>Acompanhamento da Criação de Serviços</h1>
                          <button className="close" onClick={handleClose}>
                            Fechar
                            <RiCloseCircleFill className="RiCloseCircleFill" />
                          </button>
                        </div>
                        <div className="text-chat-content">
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open2}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem>
                                {/* <p className="user-chat">
                                  Essa mensagem é a descrição do usuário. Essa
                                  parte aqui é para encher a linguiça, caso
                                  alguma pessoa se empolgue na hora de descrever
                                  o serviço
                                </p> */}
                              </MenuItem>
                              <MenuItem>
                                <p className="bot-chat">
                                  Você não possui mensagens para serem exibidas.
                                </p>
                              </MenuItem>
                              {/* <MenuItem>
                                <div className="input-content">
                                  <input
                                    className="user-input"
                                    placeholder="Digite sua mensagem"
                                  />
                                  <button
                                    onClick={handleClose}
                                    className="sender"
                                  >
                                    Enviar
                                  </button>
                                </div>
                              </MenuItem> */}
                            </MenuList>
                          </ClickAwayListener>
                        </div>
                      </div>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>

            <div className="notifications">
              <IconButton ref={anchorRef3} onClick={() => handleToggle(3)}>
                {notifications ? (
                  <MdNotifications className={classes.iconSupport} />
                ) : (
                  <MdNotificationsNone className={classes.iconSupport} />
                )}
              </IconButton>
              <Popper
                open={open3}
                anchorEl={anchorRef3.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open3}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          {notificationsArr.map((item, id) =>
                            item.read === false ? (
                              <MenuItem onClick={handleClose}>
                                <div className="notification-preview">
                                  <BsDot className="BsDot-green" />
                                  <p>{item.text}</p>
                                </div>
                              </MenuItem>
                            ) : (
                              <MenuItem onClick={handleClose}>
                                <div className="notification-preview">
                                  <BsDot className="BsDot-grey" />
                                  <p>{item.text}</p>
                                </div>
                              </MenuItem>
                            )
                          )}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>

            <div className="line" />

            {isMobile ? (
              ''
            ) : (
              <div className="profile-wrapper">
                <Button
                  aria-haspopup="true"
                  aria-expanded={open4 ? 'true' : undefined}
                  disableElevation
                  onClick={() => handleToggle(4)}
                  endIcon={
                    open4 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />
                  }
                  className={classes.buttonProfile}
                  ref={anchorRef4}
                >
                  {dadosCadastrais.nm_cliente}
                </Button>
                <Popper
                  open={open4}
                  anchorEl={anchorRef4.current}
                  role={undefined}
                  transition
                  disablePortal
                  className={classes.popper}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom',
                      }}
                    >
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open4}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleToContract}>
                            Contratos
                          </MenuItem>
                          <MenuItem onClick={handleToAccount}>
                            Minha Conta
                          </MenuItem>
                          <MenuItem onClick={handleExit}>Sair</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Grow>
                  )}
                </Popper>
              </div>
            )}

            {isMobile ? (
              <div className="profile-picture">
                <IconButton ref={anchorRef4} onClick={() => handleToggle(4)}>
                  <img
                    src={require('../../assets/img/avatar.png')}
                    alt="Imagem de perfil"
                  />
                </IconButton>
                <Popper
                  open={open4}
                  anchorEl={anchorRef4.current}
                  role={undefined}
                  transition
                  disablePortal
                  className={classes.popper}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom',
                      }}
                    >
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open4}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleToContract}>
                            Contratos
                          </MenuItem>
                          <MenuItem onClick={handleToAccount}>
                            Minha Conta
                          </MenuItem>
                          <MenuItem onClick={handleExit}>Sair</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Grow>
                  )}
                </Popper>
              </div>
            ) : (
              <div className="profile-picture">
                <img
                  src={require('../../assets/img/avatar.png')}
                  alt="Imagem de perfil"
                />
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}

export default Navbar;

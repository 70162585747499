import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1200px) {
    padding-top: 92.17px;
  }

  @media (max-width: 815px) {
    padding-top: 82.17px;
  }

  @media (max-width: 750px) {
    padding-top: 76.61px;
  }

  .banner {
    height: 300px;
    width: 100%;
    margin-bottom: 65px;

    @media (max-width: 750px) {
      height: 200px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  h2,
  h5 {
    padding: 0 20px;
    text-align: center;
  }

  h5 {
    @media (max-width: 750px) {
      line-height: 33px;
    }
  }

  p {
    font-size: 20px;
    line-height: 37px;
    color: #1d1d1d;
    margin-bottom: 40px;

    @media (max-width: 750px) {
      font-size: 16px;
      margin-bottom: 25px;
      line-height: 33px;
    }
  }

  .tratamento {
    display: flex;
    padding: 80px 0px 80px 20px;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    @media (max-width: 1150px) {
      flex-direction: column-reverse;
      justify-content: center;
    }

    @media (max-width: 750px) {
      padding: 60px 0px 40px 20px;
    }

    .text {
      width: 40%;
      padding-right: 60px;

      @media (max-width: 1440px) {
        width: 50%;
      }

      @media (max-width: 1150px) {
        width: 75%;
        margin-top: 50px;
        padding-right: 20px;
      }

      @media (max-width: 600px) {
        width: 100%;
      }

      h2 {
        text-align: start;
        padding: 0;
        max-width: 465px;

        @media (max-width: 750px) {
          max-width: 390px;
        }
      }
    }

    .img {
      width: 45%;

      @media (max-width: 1150px) {
        align-self: flex-end;
        width: 55%;
      }

      @media (max-width: 550px) {
        width: 80%;
      }

      img {
        object-fit: contain;
        width: 100%;
        height: auto;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
      }
    }

    .line {
      width: 72%;
      height: 4px;
      background-color: #adbf00;
      position: absolute;
      bottom: 0;
      margin: 0 14%;

      @media (max-width: 600px) {
        right: -20px;
        width: 85%;
      }
    }
  }
`;

export const Vantagens = styled.section`
  margin: 160px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 750px) {
    margin-bottom: 50px;
    margin-top: 100px;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(4, 19%);
    grid-auto-rows: 330px;
    grid-column-gap: 15px;
    grid-row-gap: 50px;
    width: 100%;
    margin-top: 130px;
    justify-content: center;

    @media (max-width: 1530px) {
      grid-template-columns: repeat(4, 23%);
    }

    @media (max-width: 1000px) {
      grid-template-columns: repeat(3, 30%);
    }

    @media (max-width: 770px) {
      grid-template-columns: repeat(2, 46%);
    }

    @media (max-width: 750px) {
      margin-top: 70px;
      grid-auto-rows: 280px;
    }

    @media (max-width: 500px) {
      grid-template-columns: 100%;
      grid-auto-rows: 180px;
      padding: 0 20px;
    }

    div:nth-child(5) {
      grid-area: 2 / 2 / span 1 / span 1;

      @media (max-width: 1000px) {
        grid-area: initial;
      }
    }

    div:last-child {
      grid-area: 2 / 3 / span 1 / span 1;

      @media (max-width: 1000px) {
        grid-area: initial;
      }
    }

    > div {
      width: 100%;
      height: 100%;
      background-color: #f8fbfd;
      border-radius: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 80px 20px 25px 20px;
      position: relative;

      @media (max-width: 750px) {
        padding: 40px 20px 20px 20px;
      }

      .detail {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -27px;
        width: 100%;
        z-index: 1;

        svg {
          height: 52px;
          width: 57px;
          fill: #adbf00;
          background-color: #fff;
        }

        .line {
          height: 8px;
          width: 75%;
          background-color: #002762;
          border-radius: 20px;
          position: absolute;
          top: 47%;
          z-index: -1;
          margin: 0;
        }
      }

      p {
        max-width: 275px;
        text-align: center;
      }
    }
  }
`;

export const Imagens = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 250px;
  padding: 0 20px;

  @media (max-width: 750px) {
    margin-top: 60px;
    margin-bottom: 50px;
  }

  .images {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(4, 19%);
    grid-auto-rows: 350px;
    grid-gap: 25px;
    justify-content: center;
    width: 100%;

    @media (max-width: 1530px) {
      grid-template-columns: repeat(4, 23%);
    }

    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, 30%);
    }

    @media (max-width: 950px) {
      grid-template-columns: repeat(2, 46%);
    }

    @media (max-width: 750px) {
      margin-top: 30px;
      grid-auto-rows: 250px;
    }

    @media (max-width: 545px) {
      grid-template-columns: 100%;
    }

    > div {
      width: 100%;
      height: 100%;
      cursor: pointer;
      overflow: hidden;

      :hover {
        img {
          transform: scale(1.2);
        }
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: all ease 0.5s;
      }
    }
  }
`;

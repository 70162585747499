import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const Container = styled.div`
  padding-top: 90px;
  width: 100%;
  /* height: 100%; */
  background: #f7fbfd;

  display: grid;
  grid-template-areas: 'sidebar home home';
  grid-template-columns: 260px 1fr 1fr;

  @media (max-width: 1080px) {
    grid-template-columns: 160px 1fr 1fr;
  }

  @media (max-width: 960px) {
    padding-top: 0px;
    grid-template-columns: 1fr;
    grid-template-areas: 'home';
  }
`;

export const HomeContainer = styled.div`
  grid-area: home;
  display: flex;
  flex-direction: column;
  align-items: center;

  .navbar-div {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
  }

  .novidade-container {
    margin-top: 45px;
    max-width: 1120px;
    width: 90%;
    height: 315px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    @media (max-width: 960px) {
      margin-top: 120px;
    }

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  .indicadores-wrapper {
    margin-top: 50px;
    margin-bottom: 40px;
    width: 90%;
    /* max-width: 1120px; */

    .title-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 960px) {
        align-items: flex-start;
        flex-direction: column;
      }

      h2 {
        margin-left: 20px;
        font-size: 32px;
        color: #43425d;

        @media (max-width: 960px) {
          margin-left: 0;
        }
      }

      .MuiOutlinedInput-root {
        background: #fff;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);

        .MuiOutlinedInput-notchedOutline {
          border-color: #d7dae2;
        }
      }
    }

    .indicadores-container {
      justify-items: center;
      margin-top: 30px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;

      @media (max-width: 960px) {
        grid-template-columns: 1fr;
      }

      .card {
        background: #fff;
        width: 100%;
        padding: 18px 0px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);

        @media (max-width: 960px) {
          max-width: 100%;
        }

        .card-title {
          padding: 16px 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          > div {
            display: flex;
            align-items: center;

            h3 {
              font-weight: bold;
              font-size: 18px;
              color: #4d4f5c;
            }
          }

          img {
            width: 70px;
            height: 70px;
            margin-right: 12px;
          }
        }

        .card-content {
          padding: 0 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media (max-width: 960px) {
            flex-direction: row;
          }

          ul {
            list-style: none;
            /* margin-left: 12px; */

            li {
              a {
                font-size: 16px;
                text-decoration: underline;
              }

              &::before {
                content: '';
                border-color: transparent #adbf22;
                border-style: solid;
                border-width: 0.55em 0 0.55em 0.75em;
                display: block;
                height: 0;
                width: 0;
                left: -1.25em;
                top: 1.25em;
                position: relative;
              }
            }

            .title-aberto::before {
              border-color: transparent #fad62a;
            }

            .title-vencido a {
              color: #fe4e4e;
            }
            .title-vencido::before {
              border-color: transparent #fe4e4e;
            }
          }
        }
      }
    }
  }

  .grafico-container {
    margin-top: 40px;
    margin-bottom: 160px;
    padding: 35px;
    width: 90%;
    /* max-width: 1120px; */
    background: #fff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
      padding: 12px;
      margin-bottom: 40px;
    }

    .grafico-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
      }

      h2 {
        margin-left: 20px;
        font-size: 28px;
        color: #43425d;
      }
    }

    .xAxis tspan {
      font-weight: bold;
      fill: #43425d !important;
    }
  }
`;

export const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
  },
}));

import api from '../api';

const RequestsApi = () => {
  const getRequests = (partyId) => {
    return api.get(`/Solicitacoes/Listar?party_id=${partyId}`);
  };

  const getRequestsAnswers = (solicitationId) => {
    return api.get(`/Solicitacoes/Respostas?id_solicitacao=${solicitationId}`);
  };

  const createRequest = (serviceBody) => {
    return api.post('/Solicitacoes/Criar', serviceBody);
  };

  const getDownload = (solicitationId) => {
    return api.get(
      `/Solicitacoes/Base64Download?id_solicitacao=${solicitationId}`
    );
  };

  const getTypesService = () => {
    return api.get('/Solicitacoes/Atendimento');
  };

  const getTypesCategory = () => {
    return api.get('/Solicitacoes/Categoria');
  };

  return {
    getRequests,
    getRequestsAnswers,
    createRequest,
    getDownload,
    getTypesService,
    getTypesCategory,
  };
};

export default RequestsApi();

import axios from 'axios';
import api from '../api'

const dados_indicadores = axios.create({
  baseURL:
    'https://frisiaintegration-idvjwsau7j9l-gr.integration.ocp.oraclecloud.com/ic/api/integration/v1/flows/rest/API_PORTALSEME_INDICADORE/1.0',
  auth: {
    username: 'oic.invoke',
    password: 'Pa$$w0rdFr151@',
  },
});


const indicatorsApi = () => {

  const generalIndicators = (partyId) => {
    return api.get(`/Indicadores/Geral?party_id=${partyId}`);
  };

  const volumeIndicators = (partyId, cdCrops) => {
    return api.get(
      `/Indicadores/Volumes?party_id=${partyId}&cd_safra=${cdCrops}`
    );
  };

  const getSafras = () => {
    return api.get('/Safras/getAll');
  }

    const getDados = (partyId) => {
      return api.get(`/DadosCadastrais/Detalhes?party_id=${partyId}`);
    };


  return {
    generalIndicators,
    volumeIndicators,
    getSafras,
    getDados,
  };
};

export default indicatorsApi();


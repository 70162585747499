import api from '../api';

const taxesApi = () => {
  const getTaxes = (partyId, cdCrops) => {
    return api.get(`/NFs/Resumo?party_id=${partyId}&cd_safra=${cdCrops}`);
  };

  const getSafras = () => {
    return api.get('/Safras/getAll');
  };

  const getTaxesDetalhes = (partyId, trxId) => {
    return api.get(
      `/NFs/Detalhes?party_id=${partyId}&customer_trx_id=${trxId}`
    );
  };

  return {
    getTaxes,
    getSafras,
    getTaxesDetalhes,
  };
};

export default taxesApi();


import ReactGA from 'react-ga';

export const initGA = () => {
  ReactGA.initialize('UA-180421237-1');
  ReactGA.ga('create', 'UA-52017238-3', 'auto', { name: 'trackerName' });

  ReactGA.pageview(window.location.pathname);
  ReactGA.ga('trackerName.send', 'pageview', {
    page: window.location.pathname,
  });
};

import api from '../api';

const contratosApi = () => {
  const getContratos = (partyId, cdCrops) => {
    return api.get(`/Contratos/Resumo?party_id=${partyId}&cd_safra=${cdCrops}`);
  };

  const getSafras = () => {
    return api.get('/Safras/getAll');
  };

  const getContratosDetalhes = (partyId, sellId) => {
    return api.get(
      `/Contratos/Detalhes?party_id=${partyId}&id_ordem_venda=${sellId}`
    );
  };

  return {
    getContratos,
    getSafras,
    getContratosDetalhes,
  };
};

export default contratosApi();

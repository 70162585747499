import React from 'react';
import { Helmet } from 'react-helmet';
// import Lightbox, { Modal, ModalGateway } from 'react-images';
import { ImCheckboxChecked } from 'react-icons/im';

import { Container, Vantagens } from './style';

import FixedHeader from '../../components/Header/FixedHeader';
import Footer from '../../components/Footer/Footer';

export default function Tsi() {
  // const [modalisopen, setModalIsOpen] = useState(false);
  // const [selectedIndex, setSelectedIndex] = useState(0);
  // const images = [
  //   {
  //     src: `${require('../../assets/img/tsi1.jpg')}`,
  //   },
  //   {
  //     src: `${require('../../assets/img/tsi2.png')}`,
  //   },
  //   {
  //     src: `${require('../../assets/img/tsi3.png')}`,
  //   },
  //   {
  //     src: `${require('../../assets/img/tsi4.jpg')}`,
  //   },
  //   {
  //     src: `${require('../../assets/img/tsi5.png')}`,
  //   },
  //   {
  //     src: `${require('../../assets/img/tsi6.png')}`,
  //   },
  //   {
  //     src: `${require('../../assets/img/tsi7.jpg')}`,
  //   },
  // ];

  return (
    <>
      <Helmet>
        <title>TSI | Sementes Batavo</title>
        <meta
          name="description"
          content="A Sementes Batavo é uma marca da Frísia Cooperativa Agroindustrial. Sua história está relacionada à expansão do agronegócio no interior do Paraná e ao desenvolvimento do Plantio Direto."
        />
        <meta
          name="keywords"
          content="sementes, batavo, frísia, agroindustria, agronegocio, soja, trigo"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>
      <FixedHeader />
      <Container>
        <div className="banner">
          <img src={require('../../assets/img/tsibanner.jpg')} alt="" />
        </div>
        <h2>
          Produzindo <span>Mais</span> e com <span>Melhor</span> Qualidade
        </h2>
        <h5>TSI - Tratamento de Sementes Industrial</h5>
        <div className="tratamento">
          <div className="text">
            <h2>
              Tratamento de
              <span> Sementes Industrial</span>
            </h2>
            <p>
              O tratamento de sementes é uma das mais importantes práticas de
              manejo integrado de pragas, ajudando os agricultores a produzir
              mais e com melhor qualidade.
            </p>
            <p>
              Durante o tratamento, é indispensável a utilização de polímeros
              para adesão dos produtos às sementes.
            </p>
          </div>
          <div className="img">
            <img src={require('../../assets/img/tsi.jpeg')} alt="" />
          </div>
          <div className="line" />
        </div>
        <Vantagens>
          <h2>
            Confira algumas vantagens de utilizar <span>sementes com TSI:</span>
          </h2>
          <div className="cards">
            <div>
              <div className="detail">
                <ImCheckboxChecked />
                <div className="line" />
              </div>
              <p>Conveniência;</p>
            </div>
            <div>
              <div className="detail">
                <ImCheckboxChecked />
                <div className="line" />
              </div>
              <p>Melhor recobrimento da semente;</p>
            </div>
            <div>
              <div className="detail">
                <ImCheckboxChecked />
                <div className="line" />
              </div>
              <p>Maior aderência do produto químico;</p>
            </div>
            <div>
              <div className="detail">
                <ImCheckboxChecked />
                <div className="line" />
              </div>
              <p>Maior fluidez da semente;</p>
            </div>
            <div>
              <div className="detail">
                <ImCheckboxChecked />
                <div className="line" />
              </div>
              <p>Menor risco tóxico ao operador;</p>
            </div>
            <div>
              <div className="detail">
                <ImCheckboxChecked />
                <div className="line" />
              </div>
              <p>Diminuição do número de embalagens.</p>
            </div>
          </div>
        </Vantagens>
        {/* <Imagens>
          <h2>Imagens</h2>
          <h5>Tratamento de Sementes Industrial</h5>
          <div className="images">
            <div
              onClick={() => {
                setModalIsOpen(!modalisopen);
                setSelectedIndex(0);
              }}
            >
              <img src={require('../../assets/img/tsi1.jpg')} alt="" />
            </div>
            <div
              onClick={() => {
                setModalIsOpen(!modalisopen);
                setSelectedIndex(1);
              }}
            >
              <img src={require('../../assets/img/tsi2.png')} alt="" />
            </div>
            <div
              onClick={() => {
                setModalIsOpen(!modalisopen);
                setSelectedIndex(2);
              }}
            >
              <img src={require('../../assets/img/tsi3.png')} alt="" />
            </div>
            <div
              onClick={() => {
                setModalIsOpen(!modalisopen);
                setSelectedIndex(3);
              }}
            >
              <img src={require('../../assets/img/tsi4.jpg')} alt="" />
            </div>
            <div
              onClick={() => {
                setModalIsOpen(!modalisopen);
                setSelectedIndex(4);
              }}
            >
              <img src={require('../../assets/img/tsi5.png')} alt="" />
            </div>
            <div
              onClick={() => {
                setModalIsOpen(!modalisopen);
                setSelectedIndex(5);
              }}
            >
              <img src={require('../../assets/img/tsi6.png')} alt="" />
            </div>
            <div
              onClick={() => {
                setModalIsOpen(!modalisopen);
                setSelectedIndex(6);
              }}
            >
              <img src={require('../../assets/img/tsi7.jpg')} alt="" />
            </div>
          </div>
        </Imagens>

        {/* Gateway lightbox
        <ModalGateway>
          {modalisopen && (
            <Modal onClose={() => setModalIsOpen(!modalisopen)}>
              <Lightbox views={images} currentIndex={selectedIndex} />
            </Modal>
          )}
        </ModalGateway> */}
      </Container>
      <Footer />
    </>
  );
}

import axios from 'axios';

const SERVER_API_URL = 'https://painel.sementesbatavo.com.br/wp-json/wp/v2/';

const createApiInstance = () => {
  const config = {
    baseURL: SERVER_API_URL,
  };

  const instance = axios.create(config);

  // instance.interceptors.request.use(async (config) => {
  //   const token = localStorage.getItem('sementesToken');

  //   if (config.headers) {
  //     if (token) {
  //       config.headers.Authorization = `Bearer ${token}`;
  //     }

  //     config.headers['x-url-origin'] = window.location.href;
  //   }

  //   return config;
  // });

  return instance;
};

const apiPainel = createApiInstance();

export default apiPainel;

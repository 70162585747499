import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as MinusRoundIconSvg } from '../../assets/img/white-minus-round-icon.svg';
import { ReactComponent as PlusRoundIconSvg } from '../../assets/img/white-plus-round-icon.svg';
import oracleCRMApi from '../../services/api_externa/oracle_crm_api';

const CollapsibleTable = ({ secondHeaderItems, headerItems, contractName, isInvertLineColor, isDontHaveMaximumWidth, style }) => {

  const [tableItems, setTableItems] = useState([]);

  async function getItemsOfOportunities() {
    const res = await oracleCRMApi.get(
      `/opportunities/${contractName}/child/ChildRevenue`
    );
    if (res.data.Success === 'false') {
      return console.log('error', res);
    } else {
      setTableItems(res.data.items);
    }
  }

  useEffect(() => {
    getItemsOfOportunities();
  });

  const [isShowTableItems, setIsShowTableItems] = useState(false);

  return (
    <div style={{ marginBottom: '8px' }}>
    <StyledTable isInvertLineColor={isInvertLineColor} isDontHaveMaximumWidth={isDontHaveMaximumWidth} style={style}>
      <table style={{ width: '100%' }}>
        <thead>
        <StyledTableRowHeader onClick={() => setIsShowTableItems(!isShowTableItems)}>
          {headerItems?.map((headerItem, index) => {
            if(index === 2){
              return (
                <th key={`header-item-${index}`}>
                  <StyledTextHeader>{`Valor: R$ ${tableItems.reduce(
                    (accumulator, currentValue) => accumulator + currentValue.RevnAmount,
                    0
                  ).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`}</StyledTextHeader>
                </th>
              )
            } else {
              return (
                <th key={`header-item-${index}`}>
                  <StyledTextHeader className={`${index === 0 ? 'firstCell' : ''}`}>{headerItem}</StyledTextHeader>
                  {index === headerItems.length-1 && (isShowTableItems ? <MinusRoundIconSvg /> : <PlusRoundIconSvg />)}
                </th>
              )
            }
          }
          )}
        </StyledTableRowHeader>
        </thead>
      </table>
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
          {isShowTableItems && (
            secondHeaderItems?.map((headerItem, index) => (
              <th key={`header-item-${index}`}>
                <StyledTextHeader className={`${index === 0 ? 'firstCell' : ''}`}>{headerItem}</StyledTextHeader>
              </th>
            ))
          )}
            </tr>
        </thead>

        <tbody>
          {isShowTableItems && (
            <>
              {tableItems.map(line => {
                return [line.Description, line.UOMCode, line.Quantity.toLocaleString(), `R$ ${line.UnitPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`, `R$ ${line.RevnAmount.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`, line.TSI_c ? "Sim" : "Não"]
                }).map((tableRow, indexRow) => (
                    <StyledTableRow key={`row-${indexRow}`}>
                      {tableRow.map((tableItem, indexItem) => (
                        <td key={`item-${indexItem}`}>{tableItem}</td>
                      ))}
                    </StyledTableRow>
                  )
            )}
            </>
          )}
        </tbody>
      </table>
    </StyledTable>
    {isShowTableItems && (
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
        <StyledAproveButton>Aprovar <span role="img" aria-label="green check">&#9989;</span></StyledAproveButton>
        <StyledRejectButton>Rejeitar <span role="img" aria-label="red cross">&#10060;</span></StyledRejectButton>
      </div>
    )}
    </div>
  );
};

const StyledTableRowHeader = styled.tr`
  height: 40px;
  color: #fcfcfc;
  cursor: pointer;
  th {
    background-color: #032762;
    font-weight: normal;
  }
  th:nth-child(1) {
    border-radius: 16px 0 0 16px;
  }
  th:nth-last-child(1) {
    line-height: 0px;
    text-align: right;
    padding-right: 20px;
    border-radius: 0 16px 16px 0;
  }
`;

const StyledTextHeader = styled.span`
  &.firstCell {
    margin-left: 10px;
  }
`;

export const StyledTable = styled.div`
  min-width: 375px;
  width: 100%;
  font-size: 14px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  tr {
    background-color: white;
  }
  table tbody tr:nth-child(odd) {
    background-color: #D7DAE2;
  }
`;

export const StyledTableRow = styled.tr`
  height: 40px;
  color: #4e6ba7;

  td {
    text-align: left;
    padding-left: 19px;
  }

  td:nth-child(2) {
    text-align: center;
    padding-left: 0;
  }

  td:nth-child(1) {
    color: #404040;
    font-size: 16px;
  }

  td:nth-last-child(1) {
    text-align: right;
    padding-right: 20px;
    padding-left: 2px;
  }

  &.last-line {
    td:nth-child(1) {
      border-radius: 0 0 0 16px;
    }

    td:nth-last-child(1) {
      border-radius: 0 0 16px 0;
    }
  }
`;

export const StyledBreakTableRow = styled.tr`
  border: 1px black solid;
  height: 0px;
`;

export const StyledAproveButton = styled.button`
  top: 658px;
  left: 1363px;
  width: 100px;
  height: 28px;
  background: #032762 0% 0% no-repeat padding-box;
  opacity: 1;
  font-size: 15px/25px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  border-radius: 15px;
  cursor: pointer;
`;

export const StyledRejectButton = styled.button`
  top: 658px;
  left: 1472px;
  width: 99px;
  height: 28px;
  border: 1px solid #707070;
  opacity: 1;
  font-size: 15px/25px;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  border-radius: 15px;
  cursor: pointer;
`;


export default CollapsibleTable;

import axios from 'axios';

const dados_cadastrais = axios.create({
  baseURL:
    'https://frisiaintegration-idvjwsau7j9l-gr.integration.ocp.oraclecloud.com/ic/api/integration/v1/flows/rest/API_PORTALSE_DADOS_CADASTRA/1.0',
  auth: {
    username: 'oic.invoke',
    password: 'Pa$$w0rdFr151@',
  },
});

// user: 80.798.499/0001-63
// password: 123456

export default dados_cadastrais;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, Grid, TextField } from '@material-ui/core';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { motion } from 'framer-motion';
import { Container, HomeContainer } from './styles';
import FixedHeader from '../../../components/HeaderAreaRestrita/FixedHeader';
import Sidebar from '../../../components/Sidebar';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/FooterAreaRestrita/Footer';
import SidebarMobile from '../../../components/SidebarMobile';
import LoaderSpinner from '../../../components/LoaderSpinner';
import indicatorsApi from '../../../services/api_externa/dados_indicadores';

function Cadastro() {
  const isMobile = useMediaQuery('(max-width:980px)');
  const [scrollNav, setScrollNav] = useState(false);

  const [dados, setDados] = useState();
  const history = useHistory();
  const partyID = localStorage.getItem('party_id');

  useEffect(() => {
    async function getDados() {
      const res = await indicatorsApi.getDados(partyID);
      if (res.data.Success === 'false') {
        history.push('/area-restrita');
      } else {
        return res;
      }
    }
    getDados()
      .then((res) => {
        if (res !== undefined) {
          setDados(res.data);
        }
      })
      .catch((res) => {
        history.push('/area-restrita');
      });
  }, []);

  let token = localStorage.getItem('token');

  if (token === 'null') {
    history.push('/area-restrita');
  }

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y < prevPos.y;
      if (isShow !== scrollNav) setScrollNav(isShow);
    },
    [scrollNav],
    null,
    true,
    0
  );

  return (
    <>
      <Helmet>
        <title>Sementes Batavo</title>
        <meta
          name="description"
          content="Conheça as nossas cultivares e leve mais qualidade para o seu
          plantio"
        />
        <meta
          name="keywords"
          content="sementes, batavo, frísia, agroindustria, agronegocio, soja, trigo"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot1.png'}
        />
      </Helmet>
      {!!dados ? (
        <>
          ({isMobile ? '' : <FixedHeader />}
          <Container>
            <Sidebar />
            <HomeContainer>
              {isMobile ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: scrollNav ? 1 : 0 }}
                  transition={{ opacity: { duration: 0.2 } }}
                  className="navbar-div"
                >
                  <Navbar dadosCadastrais={dados} />
                </motion.div>
              ) : (
                <Navbar dadosCadastrais={dados} />
              )}
              {isMobile ? <SidebarMobile /> : ''}

              {!!dados && (
                <div className="dados-cadastrais">
                  <h3>Dados Cadastrais</h3>

                  <div className="dados-pessoais">
                    <Grid container spacing={4}>
                      {!!dados.nr_cpf_cnpj && (
                        <Grid item xs={5}>
                          <TextField
                            fullWidth
                            disabled
                            variant="outlined"
                            label="CPF ou CNPJ"
                            defaultValue={dados.nr_cpf_cnpj}
                          />
                        </Grid>
                      )}
                      {!!dados.nm_cliente && (
                        <Grid item xs={7}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Nome do cliente"
                            disabled
                            defaultValue={dados.nm_cliente}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </div>
                  <div className="dados-contato">
                    <h4>Dados de contato</h4>

                    <Grid container spacing={4}>
                      {!!dados.nr_cep && (
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="CEP"
                            disabled
                            defaultValue={dados.nr_cep}
                          />
                        </Grid>
                      )}
                      {!!dados.nm_logradouro && (
                        <Grid item xs={6}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Endereço"
                            disabled
                            defaultValue={dados.nm_logradouro}
                          />
                        </Grid>
                      )}

                      {!!dados.ds_complemento && (
                        <Grid item xs={3}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Complemento"
                            disabled
                            defaultValue={dados.ds_complemento}
                          />
                        </Grid>
                      )}
                      {!!dados.nm_cidade && (
                        <Grid item xs={4}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Cidade"
                            disabled
                            defaultValue={dados.nm_cidade}
                          />
                        </Grid>
                      )}

                      {!!dados.sg_estado && (
                        <Grid item xs={2}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Estado"
                            disabled
                            defaultValue={dados.sg_estado}
                          />
                        </Grid>
                      )}
                      {!!dados.nr_celular && (
                        <Grid item xs={4}>
                          <TextField
                            type="text"
                            fullWidth
                            variant="outlined"
                            label="Telefone"
                            disabled
                            defaultValue={`${dados.nr_celular}`}
                          />
                        </Grid>
                      )}

                      {!!dados.ds_email && (
                        <Grid item xs={6}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="E-mail"
                            disabled
                            defaultValue={dados.ds_email}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </div>
                </div>
              )}
            </HomeContainer>
          </Container>
          <Footer restrictedArea />){' '}
        </>
      ) : (
        <LoaderSpinner />
      )}
    </>
  );
}

export default Cadastro;

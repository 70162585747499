import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Carousel } from 'antd';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
// import Lightbox, { Modal, ModalGateway } from 'react-images';

import { Container, Sobre, Curiosidades } from './style';

import FixedHeader from '../../components/Header/FixedHeader';
import Footer from '../../components/Footer/Footer';

export default function Historia() {
  // const [modalisopen, setModalIsOpen] = useState(false);
  // const [selectedIndex, setSelectedIndex] = useState(0);
  const banner = useRef();
  const bannerSettings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    autoplay: false,
  };

  // const images = [
  //   {
  //     src: `${require('../../assets/img/historia2.jpg')}`,
  //   },
  //   {
  //     src: `${require('../../assets/img/historia3.jpg')}`,
  //   },
  //   {
  //     src: `${require('../../assets/img/historia4.jpg')}`,
  //   },
  // ];

  return (
    <>
      <Helmet>
        <title>História | Sementes Batavo</title>
        <meta
          name="description"
          content="A Sementes Batavo é uma marca da Frísia Cooperativa Agroindustrial. Sua história está relacionada à expansão do agronegócio no interior do Paraná e ao desenvolvimento do Plantio Direto."
        />
        <meta
          name="keywords"
          content="sementes, batavo, frísia, agroindustria, agronegocio, soja, trigo"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>
      <FixedHeader />
      <Container>
        <div className="banner">
          <img src={require('../../assets/img/historiabanner.jpg')} alt="" />
        </div>
        <Sobre>
          <h2>
            Sementes Batavo e o <span>Plantio Direto</span>
          </h2>
          <h5>História</h5>
          <p>
            A Sementes Batavo é uma marca da Frísia Cooperativa Agroindustrial.
            Sua história está relacionada à expansão do agronegócio no interior
            do Paraná e ao desenvolvimento do Plantio Direto.
          </p>
          <div className="carousel">
            <Carousel ref={banner} {...bannerSettings} effect="fade">
              <div className="slider">
                <div className="img">
                  <img src={require('../../assets/img/historia1.jpg')} alt="" />
                </div>
                <div className="text">
                  <h5>1911</h5>
                  <p>
                    As primeiras famílias holandesas se estabelecem nos Campos
                    Gerais, no Paraná, motivadas por um plano de colonização. O
                    contrato de trabalho incluía uma casa de morada, dois bois,
                    um arado, seis vacas leiteiras, sementes e adubos.
                  </p>
                </div>
              </div>
              <div className="slider">
                <div className="img">
                  <img
                    src={require('../../assets/img/hitoria1925.jpg')}
                    alt=""
                  />
                </div>
                <div className="text">
                  <h5>1925</h5>
                  <p>
                    Sete colonos se unem e criam uma das primeiras cooperativas
                    de produção no Brasil. Três anos depois, da sociedade nasceu
                    a marca Batavo.
                  </p>
                </div>
              </div>
              <div className="slider">
                <div className="img">
                  <img
                    src={require('../../assets/img/historia1947.jpg')}
                    alt=""
                  />
                </div>
                <div className="text">
                  <h5>1947</h5>
                  <p>
                    Com a chegada de novos imigrantes, a Batavo inicia o
                    processo de introdução da cultura mecanizada e aprimoramento
                    genético nas suas atividades. A Cooperativa volta seus
                    trabalhos para a produção agropecuária, buscando atender ao
                    seu quadro social na comercialização, aquisição de insumos e
                    assistência técnica.
                  </p>
                </div>
              </div>
              <div className="slider">
                <div className="img">
                  <img
                    src={require('../../assets/img/historia1972.jpg')}
                    alt=""
                  />
                </div>
                <div className="text">
                  <h5>1972</h5>
                  <p>
                    Os pioneiros encontram na técnica de Plantio Direto uma
                    solução para as dificuldades com o solo na região dos Campos
                    Gerais, inovação que deu resultados extremamente positivos e
                    virou marco na história da agroindústria no Brasil. No fim
                    da década, foi criado o primeiro laboratório voltado à
                    análise de sementes e a primeira sementeira. No mesmo
                    período nasceu a Sementes Batavo. A experiência no Plantio
                    Direto trouxe reconhecimento à marca, a qual foi batizada
                    como “A Semente do Plantio Direto”.
                  </p>
                </div>
              </div>
              <div className="slider">
                <div className="img">
                  <img
                    src={require('../../assets/img/historia1984.jpg')}
                    alt=""
                  />
                </div>
                <div className="text">
                  <h5>1984</h5>
                  <p>
                    Da necessidade de ampliação na pesquisa, amparo e divulgação
                    tecnológica agropecuária surge a Fundação ABC, com o apoio
                    das cooperativas Batavo, Capal, Castrolanda e Cooperativa
                    Central de Laticínios do Paraná.
                  </p>
                </div>
              </div>
              <div className="slider">
                <div className="img">
                  <img
                    src={require('../../assets/img/historia2008.jpg')}
                    alt=""
                  />
                </div>
                <div className="text">
                  <h5>2008</h5>
                  <p>
                    A Batavo deixa de produzir sementes em Carambeí e inaugura a
                    nova UBS (Unidade de Beneficiamento de Sementes) em Ponta
                    Grossa, dentro da unidade industrial, favorecendo a
                    logística para os cooperados na entrega da produção.
                  </p>
                </div>
              </div>
              <div className="slider">
                <div className="img">
                  <img
                    src={require('../../assets/img/historia2014.jpg')}
                    alt=""
                  />
                </div>
                <div className="text">
                  <h5>2014</h5>
                  <p>
                    Já com a consolidação do mercado externo e a crescente
                    demanda pela marca das Sementes Batavo, a cooperativa
                    inaugura sua segunda UBS (Unidade de Beneficiamento de
                    Sementes), no município de Tibagi, proporcionando aumento da
                    produção e melhoria nos processos internos.
                  </p>
                </div>
              </div>
              <div className="slider">
                <div className="img">
                  <img
                    src={require('../../assets/img/historia2015.jpg')}
                    alt=""
                  />
                </div>
                <div className="text">
                  <h5>2015</h5>
                  <p>
                    A Batavo Cooperativa Agroindustrial decide mudar sua
                    denominação social para Frísia Cooperativa Agroindustrial. A
                    história e a essência permanecem as mesmas e agora são
                    refletidas no nome na instituição.
                  </p>
                </div>
              </div>
              <div className="slider">
                <div className="img">
                  <img src={require('../../assets/img/tsi4.jpg')} alt="" />
                </div>
                <div className="text">
                  <h5>2021</h5>
                  <p>
                    A Frísia inaugura o novo Laboratório de Análise de Sementes,
                    o maior do Paraná. Localizado em Ponta Grossa, o LAS conta
                    com 600 m² de área e capacidade para analisar 10 mil
                    amostras por ano.
                  </p>
                </div>
              </div>
            </Carousel>
            <div className="bg" />
            <div className="btns">
              <button type="button" onClick={() => banner.current.prev()}>
                <IoIosArrowBack />
              </button>
              <button type="button" onClick={() => banner.current.next()}>
                <IoIosArrowForward />
              </button>
            </div>
          </div>
        </Sobre>
        <Curiosidades>
          <div className="text">
            <h2>Curiosidades</h2>
            <p>
              ‘Frísia’ remete a uma província ao norte da Holanda, fazendo
              menção às famílias de imigrantes que chegaram à região na década
              de 1940.
            </p>
            <p>
              A produtividade da soja plantada pelos cooperados hoje é de cerca
              de 15% superior à de outros produtores do Estado do Paraná. Esse
              número é reflexo da utilização de tecnologia das propriedades da
              região e da utilização de sementes de qualidade produzidas nas
              duas UBS da Sementes Batavo.
            </p>
            <p>
              O Brasil já atingiu o primeiro milhão de hectares de Plantio
              Direto e desde os anos 1970 até hoje a Semente Batavo permanece
              auxiliando o produtor do campo a alcançar melhores resultados de
              produtividade.
            </p>
            <div className="line" />
          </div>
          <div className="bg">
            <img src={require('../../assets/img/curiosidades-bg.jpg')} alt="" />
          </div>
        </Curiosidades>
        {/* <Images>
          <h2>Imagens</h2>
          <h5>Nossa história</h5>
          <div className="images">
            <div
              onClick={() => {
                setModalIsOpen(!modalisopen);
                setSelectedIndex(0);
              }}
            >
              <img src={require('../../assets/img/historia2.jpg')} alt="" />
            </div>
            <div
              onClick={() => {
                setModalIsOpen(!modalisopen);
                setSelectedIndex(1);
              }}
            >
              <img src={require('../../assets/img/historia3.jpg')} alt="" />
            </div>
            <div
              onClick={() => {
                setModalIsOpen(!modalisopen);
                setSelectedIndex(2);
              }}
            >
              <img src={require('../../assets/img/historia4.jpg')} alt="" />
            </div>
          </div>
        </Images> */}

        {/* Gateway lightbox */}
        {/* <ModalGateway>
          {modalisopen && (
            <Modal onClose={() => setModalIsOpen(!modalisopen)}>
              <Lightbox views={images} currentIndex={selectedIndex} />
            </Modal>
          )}
        </ModalGateway> */}
      </Container>
      <Footer />
    </>
  );
}

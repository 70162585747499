import axios from 'axios';

const api = axios.create({
  baseURL:
    'https://frisiaintegration-idvjwsau7j9l-gr.integration.ocp.oraclecloud.com:443/ic/api/integration/v1/flows/rest/API_PORTALSEME_RESET_PASS/1.0',
  auth: {
    username: 'oic.invoke',
    password: 'Pa$$w0rdFr151@',
  },
});

export default async function recuperar_senha(user) {
  const pattern = /\d+/g;

  if (user.length <= 0) {
    return;
  }

  const formatedUser = user.match(pattern).join('');

  try {
    const res = await api.get('getAcesso', {
      params: {
        Account: formatedUser,
      },
    });
    if (res.data.Success === 'true') {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
}

// 05616489999
// user: 80.798.499/0001-63
// password: 123456

import styled from 'styled-components';

import logo from '../../assets/logo.svg';

export const Navbar = styled.div`
  position: absolute;
  z-index: 2;
  top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: all ease 0.5s;
  padding: 0 7.5%;

  @media (max-width: 1440px) {
    padding: 0 5%;
  }

  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  @media (max-width: 960px) {
    top: 0px;
    padding-top: 40px;
  }

  @media (max-width: 815px) {
    background-color: ${(props) => (props.isOpen ? '#fff' : 'transparent')};
  }

  @media (max-width: 750px) {
    padding-top: 20px;
  }

  .logo {
    display: flex;
    width: 130px;
    height: auto;

    @media (max-width: 960px) {
      position: relative;
      z-index: 1;
    }

    @media (max-width: 815px) {
      background-image: ${(props) => (props.isOpen ? `url(${logo})` : '')};
      background-size: cover;
      background-repeat: no-repeat;
    }

    @media (max-width: 750px) {
      width: 100px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;

      @media (max-width: 815px) {
        opacity: ${(props) => (props.isOpen ? '0' : '1')};
      }
    }
  }

  .hamburger {
    display: none;
    padding: 0;

    @media (max-width: 960px) {
      display: inline-block;
      position: relative;
      z-index: 1;
    }
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: #fff;
  }

  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #fff;

    @media (max-width: 815px) {
      background-color: #002762;
    }
  }

  nav {
    transition: all ease 0.5s;
    position: relative;
    z-index: 0;

    @media (max-width: 960px) {
      position: absolute;
      top: ${(props) => (props.isOpen ? '100%' : '-500px')};
      padding: 20px;
      width: 100%;
      left: 0;
    }

    @media (max-width: 815px) {
      background-color: #fff;
    }

    ul {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;

      @media (max-width: 960px) {
        justify-content: center;
      }

      @media (max-width: 815px) {
        flex-direction: column;
      }

      .social {
        display: flex;
        margin: 0 0 0 15px;
        padding-left: 15px;

        @media (max-width: 1050px) {
          padding-left: 10px;
        }

        @media (max-width: 815px) {
          margin: 5px 0 0 0;
          padding-left: 0;
          border-left: none;
          border-top: 1px solid #002762;
          padding-top: 15px;
        }

        a {
          font-size: 21px;
          margin: 0 10px;

          :after {
            display: none;
          }

          :hover {
            transform: scale(1.1);
            color: #aeca0b;
            svg {
              fill: #adbf00;
            }
          }
        }
      }

      li {
        margin: 0 15px;

        @media (max-width: 1050px) {
          margin: 0 10px;
        }

        @media (max-width: 960px) {
          margin: 0 15px;
        }

        @media (max-width: 878px) {
          margin: 0 10px;
        }

        @media (max-width: 815px) {
          margin: 5px 0;
        }

        a {
          display: flex;
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          line-height: 24px;
          text-decoration: none;
          transition: all ease 0.5s;
          position: relative;
          justify-content: center;

          @media (max-width: 815px) {
            color: #002762;
          }

          @media (max-width: 750px) {
            font-size: 16px;
            line-height: 22px;
          }

          :hover {
            :after {
              width: 100%;
            }
          }

          :after {
            content: '';
            width: 0%;
            height: 4px;
            background-color: #adbf00;
            position: absolute;
            bottom: -6px;
            transition: all ease 0.5s;
            border-radius: 30px;

            @media (max-width: 815px) {
              left: 0;
            }
          }
        }
      }
    }
  }
`;

export const FixedNavbar = styled.header`
  position: fixed;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  height: 74px;

  @media (max-width: 960px) {
    z-index: ${(props) => (props.isOpen ? '99' : '9')};
  }

  .container {
    width: 1200px;
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
  }

  .logo {
    display: flex;
    height: 40px;

    @media (max-width: 960px) {
      position: relative;
      z-index: 1;
    }

    @media (max-width: 750px) {
      width: 100px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .hamburger {
    display: none;
    padding: 0;

    @media (max-width: 960px) {
      display: inline-block;
      position: relative;
      z-index: 1;
    }
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: #002762;
  }

  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #002762;
  }

  nav {
    transition: all ease 0.5s;
    position: relative;
    z-index: 0;
    background-color: #fff;
    height: 100%;

    @media (max-width: 960px) {
      position: absolute;
      top: ${(props) => (props.isOpen ? '100%' : '-500px')};
      padding: 20px;
      width: 100%;
      left: 0;
    }

    ul {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
      height: 100%;

      @media (max-width: 960px) {
        justify-content: center;
      }

      @media (max-width: 815px) {
        flex-direction: column;
      }

      .social {
        display: flex;
        margin: 0 0 0 15px;
        padding-left: 15px;
        width: 90px;
        justify-content: space-between;

        a {
          :hover {
            color: #6f96b7;
            :after {
              width: 0%;
            }
          }
        }
        @media (max-width: 1050px) {
          padding-left: 10px;
        }

        @media (max-width: 815px) {
          margin: 5px 0 0 0;
          padding-left: 0;
          border-left: none;
          border-top: 1px solid #002762;
          padding-top: 15px;
        }
      }

      li {
        margin: 0 15px;
        height: 100%;
        align-content: center;

        @media (max-width: 1050px) {
          margin: 0 10px;
        }

        @media (max-width: 960px) {
          margin: 0 15px;
        }

        @media (max-width: 878px) {
          margin: 0 10px;
        }

        @media (max-width: 815px) {
          margin: 5px 0;
        }

        a {
          display: flex;
          font-size: 18px;
          font-weight: bold;
          color: #555555;
          line-height: 24px;
          text-decoration: none;
          transition: all ease 0.5s;
          position: relative;
          justify-content: center;
          align-items: center;
          letter-spacing: 2px;

          @media (max-width: 750px) {
            font-size: 16px;
            line-height: 22px;
          }

          :hover {
            color: #aeca0b;
            svg {
              fill: #aeca0b;
            }

            :after {
              width: 100%;
            }
          }

          :after {
            content: '';
            width: 0%;
            height: 4px;
            background-color: #adbf00;
            position: absolute;
            bottom: -6px;
            transition: all ease 0.5s;
            border-radius: 30px;

            @media (max-width: 815px) {
              left: 0;
            }
          }
        }

        .dropdown {
          position: absolute;
          width: 200px;
          display: flex;
          display: none;
          background-color: #fff;
          flex-direction: column;
          top: 65px;
          align-items: flex-start;
          height: auto;
          li a {
            font-size: 12px;
            font-weight: 400;
            color: #555555;
            width: 100%;
            justify-content: flex-start;
            :hover {
              :after {
                width: 0%;
              }
            }
          }
          li {
            border-top: 1px solid #f1f1f1;
            width: 100%;
            margin: 0;
            display: flex;
            padding-left: 15px;
            height: 40px;
          }

          li:hover {
            background-color: #f1f1f1;
          }

          li:first-child {
            border-top: none;
          }
        }
      }
      .active-drop:hover .dropdown {
        display: flex;
      }
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  grid-area: sidebar;
  height: 100%;

  padding-top: 40px;
  background: #032762;
  position: relative;
  top: 0;
  left: 0;

  @media (max-width: 960px) {
    display: none;
  }

  .menu-option {
    background: #43425d;
    display: flex;
    align-items: center;
    padding: 12px;
    margin: 8px 0px;

    svg {
      color: #a5a4bf;
      font-size: 18px;

      > path {
        stroke: #a5a4bf;
      }

      &:visited {
        color: #fff;
      }
    }

    span {
      margin-left: 8px;
      font-size: 18px;
      color: #fff;
    }
  }

  .navlink-active {
    .menu-option {
      background: #43425d;
      display: flex;
      align-items: center;
      padding: 12px;
      margin: 8px 0px;
      border-left: solid 8px #adbf22;

      svg {
        color: #adbf22;
        font-size: 18px;

        > path {
          stroke: #adbf22;
        }

        &:visited {
          color: #fff;
        }
      }

      span {
        margin-left: 8px;
        font-size: 18px;
        color: #adbf22;
      }
    }
  }
`;

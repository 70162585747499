import axios from 'axios';

const oracleCRMApi = axios.create({
  baseURL:
    'https://efln.fa.us6.oraclecloud.com/crmRestApi/resources/11.13.18.05',
  auth: {
    username: 'integracao2',
    password: 'KB^8gk#M4',
  },
});

export default oracleCRMApi;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from '@material-ui/core';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { motion } from 'framer-motion';
import Loader from 'react-loader-spinner';
import {
  Backdrop,
  Button,
  Fade,
  FormControl,
  Modal,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
} from '@material-ui/core';
import { AiFillCloseCircle, AiOutlineDownload } from 'react-icons/ai';
import { BsEye } from 'react-icons/bs';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import {
  Container,
  HomeContainer,
  FilterWrapper,
  SelectCrop,
  useStyles,
  useStyles1,
  StyledTitleContainer,
} from './styles';

import FixedHeader from '../../../components/HeaderAreaRestrita/FixedHeader';
import Sidebar from '../../../components/Sidebar';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/FooterAreaRestrita/Footer';
import SidebarMobile from '../../../components/SidebarMobile';
import LoaderSpinner from '../../../components/LoaderSpinner';
import indicatorsApi from '../../../services/api_externa/dados_indicadores';
import contratosApi from '../../../services/api_externa/consulta_contratos';
import consulta_anexos_contratos from '../../../services/api_externa/consulta_anexos_contratos';
import { MenuItem, Select } from '@material-ui/core';

function Contrato() {
  const classes = useStyles();
  const classes1 = useStyles1();
  const isMobile = useMediaQuery('(max-width:900px)');
  const [openView, setOpenView] = useState(false);
  const [scrollNav, setScrollNav] = useState(false);
  const [loadingOpenModal, setLoadingOpenModal] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [itemDetails, setItemDetails] = useState();

  const [dados, setDados] = useState();
  const [contratos, setContratos] = useState();
  const [cropList, setcropList] = useState([]);

  const history = useHistory();

  const partyID = localStorage.getItem('party_id');
  useEffect(() => {
    async function getDados() {
      const res = await indicatorsApi.getDados(partyID);
      if (res.data.Success === 'false') {
        history.push('/area-restrita');
      } else {
        return res;
      }
    }
    getDados()
      .then((res) => {
        if (res !== undefined) {
          setDados(res.data);
        }
      })
      .catch((res) => {
        history.push('/area-restrita');
      });

    async function getSafras() {
      const res = await indicatorsApi.getSafras(partyID);
      return res.data;
    }
    getSafras().then((res) => {
      if (res !== undefined) {
        setcropList(res);
      }
    });
  }, []);

  const [chosenCrop, setChosenCrop] = useState('20212022');

  useEffect(() => {
    async function getContratos() {
      const res = await contratosApi.getContratos(partyID, chosenCrop);
      if (res.data.Success === 'false') {
        history.push('/area-restrita');
      } else {
        return res;
      }
    }
    getContratos().then((res) => {
      if (res !== undefined) {
        setContratos(res.data);
      }
    });
  }, [chosenCrop]);

  let token = localStorage.getItem('token');

  if (token === 'null') {
    history.push('/area-restrita');
  }

  const handleCloseView = () => {
    if (loadingOpenModal) {
      return;
    }
    setOpenView(false);
    setCurrentItem(undefined);
    setItemDetails(undefined);
  };

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y < prevPos.y;
      if (isShow !== scrollNav) setScrollNav(isShow);
    },
    [scrollNav],
    null,
    true,
    0
  );

  const handleOpenView = async (item) => {
    console.log(item);
    // setLoadingOpenModal(true);
    setOpenView(true);
    setCurrentItem(item);
    const details = await contratosApi.getContratosDetalhes(
      partyID,
      item.id_ordem_venda
    );

    // if (attachment.data.Success === 'false') {
    //   history.push('/area-restrita');
    // }
    setItemDetails(details.data);
    setLoadingOpenModal(false);
  };

  const toBRL = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <>
      <Helmet>
        <title>Sementes Batavo</title>
        <meta
          name="description"
          content="Conheça as nossas cultivares e leve mais qualidade para o seu
          plantio"
        />
        <meta
          name="keywords"
          content="sementes, batavo, frísia, agroindustria, agronegocio, soja, trigo"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot1.png'}
        />
      </Helmet>
      {!!dados ? (
        <>
          ({isMobile ? '' : <FixedHeader />}
          <Container>
            <Sidebar />
            <HomeContainer>
              {isMobile ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: scrollNav ? 1 : 0 }}
                  transition={{ opacity: { duration: 0.2 } }}
                  className="navbar-div"
                >
                  <Navbar dadosCadastrais={dados} />
                </motion.div>
              ) : (
                <Navbar dadosCadastrais={dados} />
              )}
              {isMobile ? <SidebarMobile /> : ''}

              <div className="title-container">
                <div className="empty" />
                <FormControl className={classes.formControl}></FormControl>
              </div>
              <StyledTitleContainer>
                <div className="indicadores-wrapper">
                  <div className="title-container">
                    <h2>Contratos</h2>
                  </div>
                </div>
                <SelectCrop>
                  <Select
                    labelId="label-select-safra"
                    id="select-safra"
                    label="Safras"
                    width={200}
                    value={chosenCrop}
                    onChange={(e) => setChosenCrop(e.target.value)}
                  >
                    {cropList.map((cropItem) => {
                      return (
                        <MenuItem value={cropItem.cd_safra}>
                          {`Safra ${cropItem.ds_safra}`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </SelectCrop>
              </StyledTitleContainer>
              <FilterWrapper>
                {contratos ? (
                  <MaterialTable
                    localization={{
                      body: {
                        emptyDataSourceMessage: 'Nenhum registro para exibir',
                      },
                      toolbar: {
                        searchTooltip: 'Pesquisar',
                        searchPlaceholder: 'Pesquisar',
                      },
                      pagination: {
                        labelRowsSelect: 'linhas',
                        labelDisplayedRows: '{count} de {from}-{to}',
                        firstTooltip: 'Primeira página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Próxima página',
                        lastTooltip: 'Última página',
                      },
                    }}
                    icons={tableIcons}
                    title="Meus Contratos"
                    columns={[
                      {
                        maxWidth: 72,
                        title: 'Detalhes',
                        sorting: false,
                        editable: 'never',
                        render: (rowData) =>
                          rowData && (
                            <Button onClick={() => handleOpenView(rowData)}>
                              <BsEye size={29} color="#7AB2FD" />
                            </Button>
                          ),
                      },
                      {
                        maxWidth: 72,
                        title: 'Núm.',
                        field: 'nr_ordem_venda',
                        type: 'numeric',
                        filtering: false,
                      },
                      {
                        title: 'Valor',
                        field: 'vl_total',
                        type: 'currency',
                        currencySetting: { currencyCode: 'BRL' },
                        filtering: false,
                      },
                      {
                        title: 'Qtd Negociada',
                        field: 'qt_negociada',
                        type: 'numeric',
                        filtering: false,
                      },
                      {
                        title: 'Qtd Carregada',
                        field: 'qt_realizada',
                        type: 'numeric',
                        filtering: false,
                      },
                      {
                        title: 'Emissão',
                        field: 'dt_ordem_venda',
                        type: 'date',
                        filtering: false,
                      },
                      {
                        title: 'Pagamento',
                        field: 'ds_condicao_pagamento',
                        type: 'string',
                        filtering: false,
                      },
                      {
                        title: 'Status',
                        field: 'ds_cust_ubs_status_ordem_venda',
                        type: 'string',
                        filtering: false,
                      },
                    ]}
                    data={contratos}
                    options={{
                      tableLayout: 'fixed',
                      headerStyle: {
                        zIndex: 1,
                      },
                      rowStyle: (x) => {
                        if (x.tableData.id % 2) {
                          return { backgroundColor: '#eaeaef' };
                        } else {
                          return { backgroundColor: '#f7f7f7' };
                        }
                      },
                    }}
                    components={{
                      Container: (props) => <Paper {...props} elevation={0} />,
                    }}
                  />
                ) : (
                  <h1 style={{ fontSize: '20px' }}>
                    Carregando ou não existem dados para serem exibidos...
                  </h1>
                )}
              </FilterWrapper>
            </HomeContainer>
          </Container>
          <Footer restrictedArea />
          <Modal
            aria-labelledby="modal-nota-fiscal"
            aria-describedby="modal-nota-fiscal-descricao"
            className={classes1.modal}
            open={openView}
            onClose={handleCloseView}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            {!!currentItem && (
              <Fade in={openView}>
                {loadingOpenModal ? (
                  <>
                    <div
                      className="loader-holder"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <img
                        src={require('../../../assets/logo.svg')}
                        alt="Sementes Batavo"
                        style={{ marginBottom: '25px' }}
                      />
                      <Loader
                        type="Oval"
                        width={50}
                        height={50}
                        color="#adbf22"
                        style={{ display: 'flex' }}
                      />
                      <p style={{ color: '#adbf22', marginTop: '25px' }}>
                        Carregando informações do contrato
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes1.paper}>
                      <div className={classes1.topNav}>
                        <h3>
                          <strong>Visualização do Contrato</strong>
                        </h3>
                        <div className={classes1.menuOptions}>
                          <Button
                            endIcon={<AiFillCloseCircle />}
                            onClick={handleCloseView}
                            className={classes1.menuButtonExit}
                          >
                            Fechar
                          </Button>
                        </div>
                      </div>
                      <div className={classes1.paperInner}>
                        <div className={classes1.paperInnerGrid}>
                          <div className={classes1.paperInnerFlexbox}>
                            {!!itemDetails && itemDetails.nr_ordem_venda && (
                              <>
                                <p>Número do Contrato</p>
                                <TextField
                                  disabled
                                  variant="outlined"
                                  defaultValue={itemDetails.nr_ordem_venda}
                                />
                              </>
                            )}
                          </div>
                          {!!itemDetails
                            ? itemDetails.length > 0 && (
                                <>
                                  <div className={classes1.topNav}>
                                    <h3
                                      style={{
                                        paddingBottom: 15,
                                        margin: 'auto',
                                      }}
                                    >
                                      <strong>Itens</strong>
                                    </h3>
                                  </div>
                                  <div className={classes1.paperInnerFlexbox}>
                                    {itemDetails.map((item, id) => (
                                      <>
                                        <Accordion style={{ width: '100%' }}>
                                          <AccordionSummary
                                            style={{ minHeight: 'none' }}
                                            // expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                          >
                                            <Typography>
                                              Item: {item.cd_produto}
                                            </Typography>
                                          </AccordionSummary>
                                          <AccordionDetails
                                            style={{ flexDirection: 'column' }}
                                          >
                                            <Typography
                                              style={{ width: '100%' }}
                                            >
                                              <p style={{ paddingTop: 10 }}>
                                                Produto
                                              </p>
                                              <TextField
                                                disabled
                                                variant="outlined"
                                                defaultValue={item.ds_produto}
                                              />
                                            </Typography>
                                            <Typography
                                              style={{ width: '100%' }}
                                            >
                                              <p style={{ paddingTop: 10 }}>
                                                Pedido
                                              </p>
                                              <TextField
                                                disabled
                                                variant="outlined"
                                                defaultValue={
                                                  item.qt_pedido_ordem_venda
                                                }
                                              />
                                            </Typography>
                                            <Typography
                                              style={{ width: '100%' }}
                                            >
                                              <p style={{ paddingTop: 10 }}>
                                                Realizada
                                              </p>
                                              <TextField
                                                disabled
                                                variant="outlined"
                                                defaultValue={
                                                  item.qt_realizada_ordem_venda
                                                }
                                              />
                                            </Typography>
                                            <Typography
                                              style={{ width: '100%' }}
                                            >
                                              <p style={{ paddingTop: 10 }}>
                                                Saldo
                                              </p>
                                              <TextField
                                                disabled
                                                variant="outlined"
                                                defaultValue={`${item.qt_saldo_ordem_venda}`}
                                              />
                                            </Typography>
                                            <Typography
                                              style={{ width: '100%' }}
                                            >
                                              <p style={{ paddingTop: 10 }}>
                                                Custo Unitário
                                              </p>
                                              <TextField
                                                disabled
                                                variant="outlined"
                                                defaultValue={toBRL.format(
                                                  item.vl_unitario_venda
                                                )}
                                              />
                                            </Typography>
                                            <Typography
                                              style={{ width: '100%' }}
                                            >
                                              <p style={{ paddingTop: 10 }}>
                                                Custo Total
                                              </p>
                                              <TextField
                                                disabled
                                                variant="outlined"
                                                defaultValue={toBRL.format(
                                                  item.vl_total_linha
                                                )}
                                              />
                                            </Typography>
                                            <Typography
                                              style={{ width: '100%' }}
                                            >
                                              <div
                                                className={
                                                  classes.paperInnerSecondFlexbox
                                                }
                                                style={{
                                                  justifyContent: 'center',
                                                  marginTop: '15px',
                                                }}
                                              >
                                                <div
                                                  className={
                                                    classes.paperInnerFlexbox
                                                  }
                                                >
                                                  <Button
                                                    startIcon={
                                                      <AiOutlineDownload />
                                                    }
                                                    variant="outlined"
                                                    className={
                                                      classes.imprimirButton
                                                    }
                                                    onClick={() => {
                                                      window.open(
                                                        item.link_pdf
                                                      );
                                                    }}
                                                  >
                                                    Baixar PDF
                                                  </Button>
                                                </div>
                                              </div>
                                            </Typography>
                                          </AccordionDetails>
                                        </Accordion>
                                      </>
                                    ))}
                                  </div>
                                </>
                              )
                            : ''}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Fade>
            )}
          </Modal>
          )
        </>
      ) : (
        <LoaderSpinner />
      )}
    </>
  );
}

export default Contrato;

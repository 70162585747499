import React from 'react';
import { Helmet } from 'react-helmet';

import { Container } from './style';

import FixedHeader from '../../components/Header/FixedHeader';
import Footer from '../../components/Footer/Footer';

export default function Parceiros() {
  return (
    <>
      <Helmet>
        <title>Parceiros | Sementes Batavo</title>
        <meta
          name="description"
          content="A Sementes Batavo é uma marca da Frísia Cooperativa Agroindustrial. Sua história está relacionada à expansão do agronegócio no interior do Paraná e ao desenvolvimento do Plantio Direto."
        />
        <meta
          name="keywords"
          content="sementes, batavo, frísia, agroindustria, agronegocio, soja, trigo"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.jpg'}
        />
      </Helmet>
      <FixedHeader />
      <Container>
        <div className="banner">
          <img src={require('../../assets/img/parceirosbanner.jpg')} alt="" />
        </div>
        <h2>
          Boa Genética é essencial para a <span>Produtividade</span>
        </h2>
        <h5>Parceiros</h5>
        <p>
          Semente se faz no campo e, além de cuidados no plantio, bom manejo,
          controle de percevejos, colheita no momento certo e com equipamento de
          qualidade, uma boa genética é essencial para a produtividade.
        </p>
        <p>
          A Sementes Batavo é parceira das principais detentoras de genéticas de
          soja e trigo do Brasil e tem uma capacidade de produção que chega a
          800 mil sacas por ano. Conheça a relação completa de cultivares
          produzidos pela Sementes Batavo e leve mais qualidade para o seu
          plantio.
        </p>
        <div className="cards">
          <a
            href="http://www.tmg.agr.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={require('../../assets/img/tmg.jpg')} alt="TMG Logo" />
          </a>
          <a
            href="https://www.syngenta.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('../../assets/img/syngenta.jpg')}
              alt="Syngenta Logo"
            />
          </a>
          <a
            href="https://www.monsoy.com.br/pt-br.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('../../assets/img/monsoy-1.jpg')}
              alt="Monsoy Logo"
            />
          </a>
          <a
            href="https://www.basf.com/br/pt.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={require('../../assets/img/basf.jpg')} alt="Basf Logo" />
          </a>
          <a
            href="https://biotrigo.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('../../assets/img/biotrigo.jpg')}
              alt="Biotrigo Logo"
            />
          </a>
          <a
            href="https://www.facebook.com/Coodetec/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('../../assets/img/coodetec.jpg')}
              alt="Coodetec Logo"
            />
          </a>
          <a
            href="https://www.facebook.com/niderasementes/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('../../assets/img/nidera.jpg')}
              alt="Nidera Logo"
            />
          </a>
          <a
            href="https://www.ftsementes.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('../../assets/img/ft-sementes.jpg')}
              alt="FT Sementes Logo"
            />
          </a>
          <a
            href="https://www.brasmaxgenetica.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('../../assets/img/brasmax.jpg')}
              alt="Brasmax Logo"
            />
          </a>
          <a
            href="https://www.embrapa.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('../../assets/img/embrapa.jpg')}
              alt="Embrapa Logo"
            />
          </a>
          <a
            href="https://www.bayer.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={require('../../assets/img/bayer.jpg')} alt="Bayer Logo" />
          </a>
          <a
            href="http://www.orsementes.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('../../assets/img/or-sementes.jpg')}
              alt="OR Sementes Logo"
            />
          </a>
          <a
            href="http://www.hogenetica.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('../../assets/img/ho-genetica.jpg')}
              alt="HO Logo"
            />
          </a>
        </div>
      </Container>
      <Footer />
    </>
  );
}

import styled, { keyframes } from 'styled-components';

export const ContainerMap = styled.div`
  .cls-1 {
    font-size: 5.92px;
  }

  .cls-1,
  .cls-2,
  .cls-3 {
    fill: #fff; // branco
    font-family: Effra-Bold, Effra;
    font-weight: 700;
  }

  .cls-4 {
    stroke-miterlimit: 10;
  }

  .cls-4,
  .cls-5 {
    fill: none;
    stroke: #1d1d1b;
    stroke-width: 0.28px;
  }

  .cls-2 {
    font-size: 12.96px;
  }

  .cls-6 {
    fill: #1a4682; // azul
  }

  .cls-3 {
    font-size: 9.43px;
  }

  .cls-7 {
    fill: #b1b91f; // verde
  }

  .invisible {
    display: none;
  }
`;

import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const Container = styled.div`
  padding-top: 90px;
  width: 100%;
  /* height: 100%; */
  background: #f7fbfd;
  position: relative;

  display: grid;
  grid-template-areas: 'sidebar home home';
  grid-template-columns: 260px 1fr 1fr;

  @media (max-width: 1080px) {
    grid-template-columns: 160px 1fr 1fr;
  }

  @media (max-width: 960px) {
    padding-top: 0px;
    grid-template-columns: 1fr;
    grid-template-areas: 'home';
  }
`;

export const HomeContainer = styled.div`
  grid-area: home;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .navbar-div {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
  }

  .dados-cadastrais {
    background: #fff;
    padding: 30px 50px;
    margin: 50px;
    /* max-width: 1120px; */

    @media (max-width: 980px) {
      margin-top: 150px;
      width: 90%;

      .MuiGrid-container {
        flex-direction: column;
      }

      .MuiGrid-grid-xs-4 {
        width: 100%;
        max-width: 100%;
      }
    }

    h3 {
      font-size: 24px;
      font-weight: bold;
      color: #4d4f5c;
    }

    h4 {
      position: relative;
      color: #4d4f5c;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 30px;

      display: flex;
      align-items: center;

      &::after {
        position: absolute;
        right: 0;
        width: 80%;
        height: 1px;
        background: #eaf0f4;
        content: '';

        @media (max-width: 980px) {
          display: none;
        }
      }
    }

    .botoes-pessoa {
      margin: 30px 0px;

      button {
        cursor: pointer;
        margin-right: 12px;
        background: #fff;
        padding: 8px 12px;
        border: solid 1px #adbf22;
        border-radius: 8px;
        color: #adbf22;
        font-weight: bold;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        transition: all ease 0.3s;

        &:hover {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
          transition: all ease 0.3s;
        }
      }

      .active {
        margin-right: 12px;
        background: #adbf22;
        padding: 8px 12px;
        border: 0;
        border-radius: 8px;
        color: #fff;
        font-weight: bold;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      }
    }

    .dados-contato {
      margin-top: 50px;

      @media (max-width: 980px) {
        .MuiGrid-container {
          flex-direction: column;
        }

        .MuiGrid-item {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
`;

export const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
  },
}));

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { TextField, Button } from '@material-ui/core';
import InputMask from 'react-input-mask';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Container } from './styles';

import FixedHeader from '../../../components/Header/FixedHeader';
import Footer from '../../../components/FooterAreaRestrita/Footer';

import mudar_senha from '../../../services/api_externa/mudar_senha';

function AreaRestrita() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [alertMinChar, setAlertMinChar] = useState(false);
  const [alertInvalidCode, setAlertInvalidCode] = useState(false);
  const [alertPassword, setAlertPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      code: '',
      password: '',
      verification: '',
    },

    validationSchema: Yup.object({
      dado: Yup.string().required(
        '*Por favor informe seu e-mail ou número de telefone'
      ),
    }),
  });

  const checkPassword = (pass1, pass2) => {
    setAlertMinChar(false);
    setAlertPassword(false);
    if (pass1.length <= 5) {
      setAlertMinChar(true);
      return false;
    }

    if (pass1 !== pass2) {
      setAlertPassword(true);
      return false;
    }

    if (pass1 === pass2) {
      return true;
    }
  };

  const handleSubmit = async () => {
    const samePassword = checkPassword(
      formik.values.password,
      formik.values.verification
    );
    if (samePassword) {
      setAlertInvalidCode(false);
      setLoading(true);
      const res = await mudar_senha.post(
        `postNewPassword?Code=${formik.values.code}&NewPassword=${formik.values.password}`
      );
      setLoading(false);
      if (res.data.Success === 'false') {
        setAlertInvalidCode(true);
      } else if (res.data.Success === 'true') {
        history.push('/area-restrita/');
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Sementes Batavo</title>
        <meta
          name="description"
          content="Conheça as nossas cultivares e leve mais qualidade para o seu
          plantio"
        />
        <meta
          name="keywords"
          content="sementes, batavo, frísia, agroindustria, agronegocio, soja, trigo"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot1.png'}
        />
      </Helmet>
      <FixedHeader />
      <Container>
        <div className="card">
          <h1>Está quase lá!</h1>
          <h3>
            Informe o código recebido em seu e-mail e a senha que deve conter no
            mínimo 6 caracteres:
          </h3>

          <form onSubmit={handleSubmit}>
            <InputMask
              name="code"
              mask="9999999"
              maskChar={null}
              value={formik.values.code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  type="text"
                  label="Código de Verificação"
                />
              )}
            </InputMask>
            <TextField
              type="password"
              name="password"
              label="Senha"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <TextField
              type="password"
              name="verification"
              label="Confirme a senha"
              value={formik.values.verification}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {alertPassword ? (
              <p style={{ color: 'red' }}>Senhas não conferem!</p>
            ) : (
              ''
            )}
            {alertInvalidCode ? (
              <p style={{ color: 'red' }}>Código incorreto!</p>
            ) : (
              ''
            )}
            {alertMinChar ? (
              <p style={{ color: 'red' }}>
                A senha informada não contém a quantidade mínima de caracteres!
              </p>
            ) : (
              ''
            )}
            <div className="button-container">
              <Button type="button" onClick={handleSubmit} disabled={loading}>
                {loading ? 'Aguarde...' : 'Próximo'}
              </Button>
            </div>
          </form>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default AreaRestrita;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;

  .menu-wrapper {
    width: 100%;
    padding: 12px;
    background: #032762;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    img {
      width: 100px;
    }

    span {
      color: #fff;
      font-weight: bold;
    }
  }

  button {
    .hamburger-inner,
    .hamburger.is-active {
      background: #fff !important;

      &::after {
        background: #fff !important;
      }

      &::before {
        background: #fff !important;
      }
    }
  }

  .menu-list {
    position: absolute;
    display: block;
    width: 100%;
    background: #032762;
    transform: translateY(-300%);
    transition: all ease 0.5s;
  }

  .menu-active {
    margin-top: -10px;
    transform: translateY(0%);
    transition: all ease 0.5s;
  }

  .menu-option {
    background: #43425d;
    display: flex;
    align-items: center;
    padding: 12px;
    margin: 8px 0px;

    svg {
      color: #a5a4bf;
      font-size: 18px;

      > path {
        stroke: #a5a4bf;
      }

      &:visited {
        color: #fff;
      }
    }

    span {
      margin-left: 8px;
      font-size: 18px;
      color: #fff;
    }
  }

  .navlink-active {
    .menu-option {
      background: #43425d;
      display: flex;
      align-items: center;
      padding: 12px;
      margin: 8px 0px;
      border-left: solid 8px #adbf22;

      svg {
        color: #adbf22;
        font-size: 18px;

        > path {
          stroke: #adbf22;
        }

        &:visited {
          color: #fff;
        }
      }

      span {
        margin-left: 8px;
        font-size: 18px;
        color: #adbf22;
      }
    }
  }
`;

import { createGlobalStyle } from 'styled-components';
import '../../node_modules/hamburgers/dist/hamburgers.css';
import smallArrow from '../assets/img/smallArrow.svg';
import bigArrow from '../assets/img/bigArrow.svg';
import 'antd/es/notification/style/css';
import 'antd/es/carousel/style/css';
import 'antd/es/table/style/css';
import 'antd/es/pagination/style/css';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: 100%;
    /* overflow-x: hidden; */
    scroll-behavior: smooth;
    position: relative;
  }

  .MuiSelect-root, .MuiSelect-select, .MuiSelect-selectMenu, .MuiSelect-outlined {
    display: flex;
    align-items: center;
    height: 56px;
  }

  body {
    background: #fff;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: 'Palanquin', sans-serif;

    .hkn-ball-button {
      z-index: 90 !important;
    }
  }

  h2 {
    font-size: 50px;
    line-height: 43px;
    font-weight: bold;
    color: #002762;

    @media (max-width: 750px) {
      font-size: 35px;
      line-height: 30px;
    }

    span {
      color: #B1C20F;
    }
  }

  h5 {
    font-family: 'Allura', cursive;
    font-size: 50px;
    line-height: 63px;
    color: #afc007;
    margin-bottom: 10px;

    @media (max-width: 750px) {
      font-size: 35px;
      line-height: 48px;
    }
  }

  .arrowbtn {
    display: flex;
    background-color: #fff;
    border-radius: 46px;
    width: fit-content;
    padding: 3px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: none;
    cursor: pointer;

    :hover {
      .arrow {
        width: 94px;
        height: 12px;
        background-image: url(${bigArrow});
      }
    }

    p {
      font-size: 22px !important;
      line-height: 28px !important;
      font-weight: bold;
      color: #fff !important;
      margin-bottom: 0 !important;
      margin: 0;
      padding: 10px 25px;
      background-color: #adbf00;
      border-radius: 46px 0 0 46px;

      @media (max-width: 750px) {
        font-size: 18px !important;
        line-height: 24px !important;
      }
    }

    .arrow {
      margin: 0 15px;
      width: 21px;
      height: 10px;
      background-image: url(${smallArrow});
      background-size: cover;
      transition: width ease 0.3s;
    }
  }

  .loading {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;


    .loader {
      border: 10px solid #f3f3f3; /* Light grey */
      border-top: 10px solid #ADBF00; /* Blue */
      border-radius: 50%;
      width: 80px;
      height: 80px;
      animation: spin 2s linear infinite;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }

  .nodisplay {
    display: none !important;
    opacity: 0;
  }

  .dontshow {
    opacity: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .show {
    opacity: 1 !important;
    z-index: 10 !important;
  }

  @keyframes STAR {
    0% {
      background: #000001;
      height: 1px;
      width: 1px;
      box-shadow: 0px 0px 10px white;
    }
    10% {
      background: #b0e5de;
    }
    20% {
      background: #b0e5cc;
    }
    30% {
      background: #cce5b0;
    }
    40% {
      background: #dae5b0;
    }
    50% {
      background: #e5ddb0;
      width: 4px;
      height: 4px;
      box-shadow: 0px 0px 10px #d3bd3d;
    }
    100% {
      width: 1px;
      height: 1px;
      background: #000001;
      box-shadow: 0px 0px 10px white;
    }
  }

  .star {
    height: 1px;
    width: 1px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px white;
    background: #000001;
    position: fixed;
  }

  .star.pulse {
    animation: STAR 2s infinite; /* Safari 4+ */
  }
`;

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { HiSupport } from 'react-icons/hi';
import { FaReceipt, FaFileSignature, FaUserEdit } from 'react-icons/fa';
import { RiBarChart2Fill } from 'react-icons/ri';
import { AiOutlineBarcode } from 'react-icons/ai';
// import { GrSchedules } from 'react-icons/gr';

import { Container } from './styles';

function SidebarMobile(props) {
  const [openMenu2, setOpenMenu2] = useState(false);

  return (
    <Container>
      <div className="menu-wrapper">
        <img
          src={require('../../assets/logo-branca.svg')}
          alt="Logo Sementes Batavo"
        />
        <span>Área restrita</span>
        <button
          className={`hamburger hamburger--collapse ${
            openMenu2 ? 'is-active' : ''
          }`}
          type="button"
          onClick={() => setOpenMenu2(!openMenu2)}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </div>
      <div className={`menu-list ${openMenu2 && 'menu-active'}`}>
        <NavLink to="/area-restrita/home" activeClassName="navlink-active">
          <div className="menu-option">
            <RiBarChart2Fill size={20} />
            <span>Indicadores</span>
          </div>
        </NavLink>

        <NavLink to="/area-restrita/aprovacao-contrato" activeClassName="navlink-active">
          <div className="menu-option">
            <AiOutlineBarcode />
            <span>Aprovação de Contrato</span>
          </div>
        </NavLink>

        <NavLink to="/area-restrita/contrato" activeClassName="navlink-active">
          <div className="menu-option">
            <FaFileSignature />
            <span>Contratos</span>
          </div>
        </NavLink>

        <NavLink
          to="/area-restrita/notas-fiscais"
          activeClassName="navlink-active"
        >
          <div className="menu-option">
            <FaReceipt />
            <span>Notas Fiscais</span>
          </div>
        </NavLink>

        {/* <NavLink
          to="/area-restrita/agendamento"
          activeClassName="navlink-active"
        >
          <div className="menu-option">
            <GrSchedules />
            <span>Agendamento</span>
          </div>
        </NavLink> */}

        <NavLink to="/area-restrita/cadastro" activeClassName="navlink-active">
          <div className="menu-option">
            <FaUserEdit />
            <span>Dados Cadastrais</span>
          </div>
        </NavLink>

        <NavLink to="/area-restrita/sacc" activeClassName="navlink-active">
          <div className="menu-option">
            <HiSupport size={20} />
            <span>Solicitações</span>
          </div>
        </NavLink>
      </div>
    </Container>
  );
}

export default SidebarMobile;

import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  background: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  padding: 38px 75px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    position: fixed;
    bottom: 0;
    z-index: 99;
    border-top: solid 1px #ebebf2;
    padding: 4px 20px;
    justify-content: center;
  }

  .container-wrapper {
    width: 100%;
    /* max-width: 1120px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 960px) {
      max-width: 100%;
      justify-content: center;
    }
  }

  .container-indicadores {
    width: 100%;
    /* max-width: 1120px; */
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 960px) {
      max-width: 100%;
      justify-content: center;
    }
  }

  .MuiPaper-root {
    width: 100% !important;
    margin: auto;

    @media (max-width: 960px) {
      width: 95% !important;
      height: 70vh;
      margin: auto;
      overflow: scroll;
    }
  }

  .MuiFormControl-root {
    margin: auto;
    width: 50%;

    .MuiOutlinedInput-root {
      border-radius: 27px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

      .MuiInputAdornment-positionEnd {
        margin-right: 8px;
      }

      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
    }
  }

  .options-container {
    align-self: flex-end;
    justify-self: flex-end;
    display: flex;
    align-items: center;

    @media (max-width: 960px) {
      width: 100%;
      justify-content: space-evenly;
    }

    .notifications {
      div {
        z-index: 2;
      }

      .notification-preview {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);
        padding: 10px 0;

        p {
          word-wrap: break-word;
          white-space: pre-wrap;
          margin: unset;
        }

        .BsDot-green {
          color: yellowgreen;
          font-size: 35px;
        }

        .BsDot-grey {
          color: black;
          opacity: 0.04;
          font-size: 35px;
        }
      }
    }

    .chat {
      div {
        z-index: 2;
      }

      .container-chat {
        width: 100%;

        @media (max-width: 960px) {
          /* width: 100% !important;
          height: 85vh; */
        }

        .text-chat-content {
          display: flex;
          flex-direction: column;

          .input-content {
            display: flex;
            width: 100%;

            .sender {
              width: 20%;
              align-self: flex-end;
              border: none;
              background-color: #bace25;
              color: white;
              padding: 5px;
              margin: 5px;
              border-radius: 4px;
              align-self: center;
              cursor: pointer;
              transition: all ease 0.5s;

              :hover {
                padding: 15px 5px;
              }
            }

            .user-input {
              width: 100%;
              border: none;
              background-color: #fefefe;
              padding: 15px;
              margin: 5px;
              border-radius: 4px;

              textarea {
                word-wrap: break-word;
                white-space: pre-wrap;
              }
            }
          }

          .user-chat {
            width: fit-content;
            word-wrap: break-word;
            white-space: pre-wrap;
            margin: unset;
            background-color: #e6eff4;
            padding: 15px;
            border-radius: 4px;
            margin: 5px;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
          }

          .bot-chat {
            width: fit-content;
            word-wrap: break-word;
            white-space: pre-wrap;
            margin: unset;
            background-color: rgba(0, 0, 0, 0.04);
            padding: 15px;
            border-radius: 4px;
            margin: 5px;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
          }
        }

        .text-chat-header {
          position: relative;
          display: flex;
          flex-direction: row;
          padding: 25px;
          width: 100%;

          @media (max-width: 960px) {
            flex-direction: column-reverse;
          }

          h1 {
            margin: unset;
          }

          .close {
            display: flex;
            align-items: center;
            align-self: center;
            margin-left: 20%;
            max-height: 30px;
            color: red;
            position: relative;
            background-color: #f7fbfd;
            border: none;
            border-radius: 4px;
            padding: 0 10px;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
            cursor: pointer;
            transition: all ease 0.5s;

            @media (max-width: 960px) {
              margin-left: unset;
              width: 27%;
              align-self: end;
            }

            :hover {
              color: #f7fbfd;
              background-color: red;
            }

            .RiCloseCircleFill {
              margin-left: 5px;
            }
          }
        }
      }
    }

    .line {
      width: 1px;
      margin: 0 8px;
      height: 28px;
      background: #ebebf2;
      content: '';

      @media (max-width: 960px) {
        display: none;
      }
    }

    .profile-picture {
      display: flex;
      margin-left: 12px;

      @media (max-width: 960px) {
        margin-left: 0;
      }

      img {
        width: 58px;
        height: 58px;
        border-radius: 100%;
        position: relative;
        object-fit: contain;
        object-position: center;
      }
    }
  }
`;

export const useStyles = makeStyles(() => ({
  iconSupport: {
    color: '#a5a4bf',
  },
  buttonProfile: {
    fontDecoration: 'none',
    fontSize: '16px',
    fontWeight: '300',
    fontVariant: 'normal',
    textTransform: 'none',
    color: '#43425D',
  },
  popper: {
    background: '#Fff',
    zIndex: 5,
    boxShadow: '0px 4px 6px rgba(0,0,0,0.16)',
  },
  indicador: {
    display: 'none',
  },
}));

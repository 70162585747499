import React, { useState, useEffect, forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { motion } from 'framer-motion';
import Loader from 'react-loader-spinner';
import {
  Backdrop,
  Button,
  Fade,
  Modal,
  TextField,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AiOutlineDownload, AiFillCloseCircle } from 'react-icons/ai';
import { BsEye } from 'react-icons/bs';

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import {
  Container,
  HomeContainer,
  FilterWrapper,
  SelectCrop,
  useStyles,
  useStyles1,
  StyledTitleContainer,
} from './styles';

import FixedHeader from '../../../components/HeaderAreaRestrita/FixedHeader';
import Sidebar from '../../../components/Sidebar';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/FooterAreaRestrita/Footer';
import SidebarMobile from '../../../components/SidebarMobile';
import LoaderSpinner from '../../../components/LoaderSpinner';

import dados_cadastrais from '../../../services/api_externa/dados_cadastrais';
import taxesApi from '../../../services/api_externa/consulta_notas_fiscais';
import consulta_anexos_nfs from '../../../services/api_externa/consulta_anexos_nfs';
import { MenuItem, Select } from '@material-ui/core';
import indicatorsApi from '../../../services/api_externa/dados_indicadores';

function NotasFiscais() {
  const classes = useStyles();
  const classes1 = useStyles1();
  const isMobile = useMediaQuery('(max-width:900px)');
  const [scrollNav, setScrollNav] = useState(false);
  const [open, setOpen] = useState(false);

  const [itemDetails, setItemDetails] = useState();
  const [loadingOpenModal, setLoadingOpenModal] = useState(false);
  const [dados, setDados] = useState();
  const [notas, setNotas] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const history = useHistory();
  const [cropList, setcropList] = useState([]);
  const [chosenCrop, setChosenCrop] = useState('20212022');
  const partyID = localStorage.getItem('party_id');

  useEffect(() => {
    indicatorsApi.getDados(partyID).then((res) => {
      setDados(res.data);
    });

    async function getSafras() {
      const res = await indicatorsApi.getSafras(partyID);
      return res.data;
    }
    getSafras().then((res) => {
      if (res !== undefined) {
        setcropList(res);
      }
    });
  }, []);

  useEffect(() => {
    async function getNotasFiscais() {
      const res = await taxesApi.getTaxes(partyID, chosenCrop);
      return res;
    }
    getNotasFiscais().then((res) => {
      if (res !== undefined) {
        setNotas(res.data);
      }
    });
  }, [chosenCrop]);

  let token = localStorage.getItem('token');

  if (token === 'null') {
    history.push('/area-restrita');
  }

  const handleClose = () => {
    if (loadingOpenModal) {
      return;
    }
    setOpen(false);
    setSelectedItem(undefined);
    setItemDetails(undefined);
  };

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y < prevPos.y;
      if (isShow !== scrollNav) setScrollNav(isShow);
    },
    [scrollNav],
    null,
    true,
    0
  );

  const handleViewNF = async (data) => {
    // setLoadingOpenModal(true);
    // const nota = notas.items.filter((nota) => nota.NrNF === data.NrNF);
    setOpen(true);
    setSelectedItem(data);
    console.log(selectedItem);
    const details = await taxesApi.getTaxesDetalhes(
      partyID,
      data.customer_trx_id
    );

    console.log(details.data);
    setItemDetails(details.data);
    setLoadingOpenModal(false);
  };

  const toBRL = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <>
      <Helmet>
        <title>Sementes Batavo</title>
        <meta
          name="description"
          content="Conheça as nossas cultivares e leve mais qualidade para o seu
          plantio"
        />
        <meta
          name="keywords"
          content="sementes, batavo, frísia, agroindustria, agronegocio, soja, trigo"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot1.png'}
        />
      </Helmet>
      {!!dados ? (
        <>
          ({isMobile ? '' : <FixedHeader />}
          <Container>
            <Sidebar />
            <HomeContainer>
              {isMobile ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: scrollNav ? 1 : 0 }}
                  transition={{ opacity: { duration: 0.2 } }}
                  className="navbar-div"
                >
                  <Navbar dadosCadastrais={dados} />
                </motion.div>
              ) : (
                <Navbar dadosCadastrais={dados} />
              )}
              {isMobile ? <SidebarMobile /> : ''}

              <StyledTitleContainer>
                <div className="indicadores-wrapper">
                  <div className="title-container">
                    <h2>Notas Fiscais</h2>
                  </div>
                </div>
                <SelectCrop>
                  <Select
                    labelId="label-select-safra"
                    id="select-safra"
                    label="Safras"
                    width={200}
                    value={chosenCrop}
                    onChange={(e) => setChosenCrop(e.target.value)}
                  >
                    {cropList.map((cropItem) => {
                      return (
                        <MenuItem value={cropItem.cd_safra}>
                          {`Safra ${cropItem.ds_safra}`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </SelectCrop>
              </StyledTitleContainer>

              <FilterWrapper>
                {notas ? (
                  <MaterialTable
                    localization={{
                      body: {
                        emptyDataSourceMessage: 'Nenhum registro para exibir',
                      },
                      toolbar: {
                        searchTooltip: 'Pesquisar',
                        searchPlaceholder: 'Pesquisar',
                      },
                      pagination: {
                        labelRowsSelect: 'linhas',
                        labelDisplayedRows: '{count} de {from}-{to}',
                        firstTooltip: 'Primeira página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Próxima página',
                        lastTooltip: 'Última página',
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
                      },
                    }}
                    icons={tableIcons}
                    title="Minhas Notas Fiscais"
                    columns={[
                      {
                        title: 'Detalhes',
                        field: 'internal_action',
                        sorting: false,
                        render: (rowData) =>
                          rowData && (
                            <Button onClick={() => handleViewNF(rowData)}>
                              <BsEye size={29} color="#7AB2FD" />
                            </Button>
                          ),
                      },
                      {
                        title: 'Requisição',
                        field: 'customer_trx_id',
                        type: 'numeric',
                      },
                      {
                        title: 'Número NFe',
                        field: 'nr_nf',
                        type: 'numeric',
                      },
                      {
                        title: 'Série NFe',
                        field: 'nr_serie_nf',
                        type: 'numeric',
                      },
                      {
                        title: '# Contrato',
                        field: 'nr_ordem_venda',
                        type: 'numeric',
                      },
                      {
                        title: 'Valor NFe',
                        field: 'vl_nf',
                        type: 'currency',
                        currencySetting: {
                          currencyCode: 'BRL',
                        },
                      },
                      {
                        title: 'Emissão',
                        field: 'dt_emissao',
                        type: 'date',
                      },

                      {
                        title: 'Vencimento',
                        field: 'dt_vencimento',
                        type: 'date',
                      },
                      {
                        title: 'Saldo Titulos',
                        field: 'saldo_titulos',
                        type: 'currency',
                        currencySetting: {
                          currencyCode: 'BRL',
                        },
                      },
                      {
                        title: 'Status Titulos',
                        field: 'ds_cust_status_parcela',
                        type: 'string',
                      },
                    ]}
                    data={notas}
                    options={{
                      pageSize: 20,
                      filtering: false,
                      tableLayout: 'auto',
                      headerStyle: {
                        zIndex: 1,
                      },
                      rowStyle: (x) => {
                        if (x.tableData.id % 2) {
                          return { backgroundColor: '#eaeaef' };
                        } else {
                          return { backgroundColor: '#f7f7f7' };
                        }
                      },
                    }}
                    components={{
                      Container: (props) => <Paper {...props} elevation={0} />,
                    }}
                  />
                ) : (
                  <h1 style={{ fontSize: '20px' }}>
                    Carregando ou não existem dados para serem exibidos...
                  </h1>
                )}
              </FilterWrapper>
            </HomeContainer>
          </Container>
          <Footer restrictedArea />
          <Modal
            aria-labelledby="modal-nota-fiscal"
            aria-describedby="modal-nota-fiscal-descricao"
            className={classes1.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            {!!selectedItem && (
              <Fade in={open}>
                {loadingOpenModal ? (
                  <>
                    <div
                      className="loader-holder"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <img
                        src={require('../../../assets/logo.svg')}
                        alt="Sementes Batavo"
                        style={{ marginBottom: '25px' }}
                      />
                      <Loader
                        type="Oval"
                        width={50}
                        height={50}
                        color="#adbf22"
                        style={{ display: 'flex' }}
                      />
                      <p style={{ color: '#adbf22', marginTop: '25px' }}>
                        Carregando informações da nota fiscal
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes1.paper}>
                      <div className={classes1.topNav}>
                        <h3>
                          <strong>Nota Fiscal: {selectedItem.NrNF}</strong>
                        </h3>
                        <div className={classes1.menuOptions}>
                          <Button
                            endIcon={<AiFillCloseCircle />}
                            variant="outlined"
                            color="secondary"
                            onClick={handleClose}
                            className={classes1.menuButtonExit}
                          >
                            Fechar
                          </Button>
                        </div>
                      </div>

                      <div className={classes1.paperInner}>
                        <h3 style={{ fontSize: 22 }}>
                          <strong>Resumo</strong>
                        </h3>
                        <div className={classes1.paperInnerGrid}>
                          {!!selectedItem && (
                            <div className={classes1.paperInnerFlexbox}>
                              <p>Valor da NF</p>
                              <TextField
                                variant="outlined"
                                size="small"
                                disabled
                                defaultValue={toBRL.format(selectedItem.vl_nf)}
                              />
                            </div>
                          )}
                          {!!selectedItem && (
                            <div className={classes1.paperInnerFlexbox}>
                              <p>Emissão</p>
                              <TextField
                                variant="outlined"
                                size="small"
                                disabled
                                defaultValue={selectedItem.dt_emissao}
                              />
                            </div>
                          )}
                          {!!selectedItem && (
                            <div className={classes1.paperInnerFlexbox}>
                              <p>Número NF</p>
                              <TextField
                                variant="outlined"
                                size="small"
                                disabled
                                defaultValue={selectedItem.nr_nf}
                              />
                            </div>
                          )}
                          {!!selectedItem && (
                            <div className={classes1.paperInnerFlexbox}>
                              <p>Série NF</p>
                              <TextField
                                variant="outlined"
                                size="small"
                                disabled
                                defaultValue={selectedItem.nr_serie_nf}
                              />
                            </div>
                          )}
                        </div>

                        <div className={classes1.paperInner}>
                          <div className={classes1.paperInnerGrid}>
                            <div className={classes1.paperInnerFlexbox}></div>
                          </div>
                        </div>
                        {!!itemDetails
                          ? itemDetails.length > 0 && (
                              <>
                                <div className={classes1.topNav}>
                                  <h3
                                    style={{
                                      paddingTop: 25,
                                      paddingBottom: 25,
                                    }}
                                  >
                                    <strong>Itens</strong>
                                  </h3>
                                </div>
                                <div className={classes1.paperInnerGrid}>
                                  {itemDetails.map((item, id) => (
                                    <>
                                      <Accordion>
                                        <AccordionSummary
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                        >
                                          <Typography>
                                            Item: {item.nr_nf}
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Typography style={{ width: '100%' }}>
                                            <p style={{ paddingTop: 10 }}>
                                              Produto
                                            </p>
                                            <TextField
                                              disabled
                                              variant="outlined"
                                              defaultValue={item.ds_produto}
                                              style={{ width: '100%' }}
                                            />
                                            <p style={{ paddingTop: 10 }}>
                                              Quantidade
                                            </p>
                                            <TextField
                                              disabled
                                              variant="outlined"
                                              defaultValue={item.qt_produto}
                                              style={{ width: '100%' }}
                                            />
                                            <p style={{ paddingTop: 10 }}>
                                              Custo Unitário
                                            </p>
                                            <TextField
                                              disabled
                                              variant="outlined"
                                              defaultValue={toBRL.format(
                                                item.vl_preco_unitario_venda
                                              )}
                                              style={{ width: '100%' }}
                                            />
                                            <p style={{ paddingTop: 10 }}>
                                              Custo Total
                                            </p>
                                            <TextField
                                              disabled
                                              variant="outlined"
                                              defaultValue={toBRL.format(
                                                item.vl_preco_total
                                              )}
                                              style={{ width: '100%' }}
                                            />

                                            <p style={{ paddingTop: 10 }}>
                                              Chave NFe
                                            </p>
                                            <TextField
                                              variant="outlined"
                                              disabled
                                              defaultValue={item.cd_chave_nf}
                                              style={{ width: '100%' }}
                                            />
                                            <div
                                              className={
                                                classes.paperInnerSecondFlexbox
                                              }
                                              style={{
                                                justifyContent: 'center',
                                                marginTop: '15px',
                                              }}
                                            >
                                              <div
                                                className={
                                                  classes.paperInnerFlexbox
                                                }
                                              >
                                                <Button
                                                  startIcon={
                                                    <AiOutlineDownload />
                                                  }
                                                  variant="outlined"
                                                  className={
                                                    classes.imprimirButton
                                                  }
                                                  onClick={() => {
                                                    window.open(item.link_pdf);
                                                  }}
                                                >
                                                  Baixar PDF
                                                </Button>
                                              </div>
                                              <div
                                                className={
                                                  classes.paperInnerFlexbox
                                                }
                                              >
                                                <Button
                                                  startIcon={
                                                    <AiOutlineDownload />
                                                  }
                                                  variant="outlined"
                                                  className={
                                                    classes.imprimirButton
                                                  }
                                                  onClick={() => {
                                                    window.open(item.link_xml);
                                                  }}
                                                >
                                                  Baixar XML
                                                </Button>
                                              </div>
                                            </div>
                                          </Typography>
                                        </AccordionDetails>
                                      </Accordion>
                                    </>
                                  ))}
                                </div>
                              </>
                            )
                          : ''}

                      </div>
                    </div>
                  </>
                )}
              </Fade>
            )}
          </Modal>
          )
        </>
      ) : (
        <LoaderSpinner />
      )}
    </>
  );
}

export default NotasFiscais;

import styled, { keyframes } from 'styled-components';

const heartBeat = keyframes`
    0%, 100%, 40%, 60% {
        transform: scale(1);
    }
    20%, 80% {
        transform: scale(1.3);
    }
`;

export const Contato = styled.section`
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  background: ${(props) => (props.restrictedArea ? '#fff' : '#032762')};

  .infos {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 590px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .logo {
      display: flex;
      width: 200px;
      height: auto;

      @media (max-width: 750px) {
        width: 150px;
        margin-left: 20px;
      }

      img {
        object-fit: contain;
        width: 100%;
        height: auto;
      }
    }

    .line {
      width: 1px;
      height: 100px;
      background-color: ${(props) =>
        props.restrictedArea ? '#032762' : '#fff'};
      position: relative;
      margin: 0 40px;

      @media (max-width: 590px) {
        width: 30px;
        height: 1px;
        margin: 20px 0;
        top: 0px;
      }
    }

    .endereco {
      display: flex;
      flex-direction: column;

      a,
      p {
        font-size: 18px;
        line-height: 20px;
        color: ${(props) => (props.restrictedArea ? '#032762' : '#fff')};
        text-decoration: none;
        margin: 0;

        @media (max-width: 750px) {
          font-size: 16px;
          line-height: 18px;
        }
      }

      p {
        font-weight: bold;
      }

      a {
        transition: all ease 0.5s;

        :hover {
          color: #adbf00;
        }
      }
    }

    .social {
      a {
        font-size: 28px;
        color: #adbf00;
        transition: all ease 0.5s;
        margin: 0 5px;
        padding: 0px 12px;

        border: solid 1px #adbf00;
        border-radius: 100%;

        @media (max-width: 750px) {
          font-size: 24px;
          padding: 0px 8px;
        }

        :hover {
          color: ${(props) => (props.restrictedArea ? '#032762' : '#fff')};
          border-color: ${(props) =>
            props.restrictedArea ? '#032762' : '#fff'};
        }
      }
    }
  }

  .bg-img {
    position: absolute;
    right: 0;
    z-index: 0;
    bottom: 330px;
    width: 67%;
    height: auto;

    @media (max-width: 1160px) {
      top: -125px;
      bottom: unset;
    }

    @media (max-width: 860px) {
      width: 60%;
    }

    @media (max-width: 590px) {
      top: -35px;
      width: 80%;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
`;

export const Direitos = styled.div`
  display: flex;
  padding: 12px 20px;
  max-width: 890px;
  margin: auto;
  justify-content: space-evenly;

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    max-width: 100%;
  }

  p,
  a {
    color: #000;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    margin: 0;
    font-family: 'Libre Franklin', sans-serif;
    font-weight: 300;

    @media (max-width: 750px) {
      font-size: 14px;
    }
  }

  a {
    @media (max-width: 900px) {
      margin-top: 15px;
    }
  }

  img {
    margin: 0 3px;
    height: 15px;

    animation-name: ${heartBeat};
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }
`;

import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

import { Contato, Direitos } from './style';

export default function Footer({ restrictedArea }) {
  return (
    <>
      <Contato id="contato" restrictedArea={restrictedArea}>
        <div className="infos">
          <a href="/" className="logo">
            {restrictedArea ? (
              <img
                src={require('../../assets/logo.svg')}
                alt="Sementes Batavo"
              />
            ) : (
              <img
                src={require('../../assets/logo-branca.svg')}
                alt="Sementes Batavo"
              />
            )}
          </a>
          <div className="line" />
          <div className="endereco">
            <p>Comercial Matriz – Carambeí (PR)</p>
            <a href="tel: +554232319325">42 3231-9325</a>
            <a
              href="https://www.google.com/maps/place/Sementes+Batavo/@-24.942671,-50.131763,15z/data=!4m2!3m1!1s0x0:0x37abf7eab0e9b6af?sa=X&ved=2ahUKEwiOwJOii4zsAhVLGLkGHXMhAH0Q_BIwHnoECBMQBg"
              target="_blank"
              rel="noopener noreferrer"
            >
              Av. dos Pioneiros, 2324 - Centro <br /> Caixa postal: 84145-000{' '}
              <br />
              Carambeí - PR
            </a>
          </div>
          <div className="line" />
          <div className="social">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/sementesbatavo/"
            >
              <FaFacebookF />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/sementesbatavo/"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </Contato>
      <Direitos>
        <p>©2021 Sementes Batavo. Todos os direitos reservados</p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://agenciaade.com.br/"
        >
          Desenvolvido com{' '}
          <img src={require('../../assets/img/heart.svg')} alt="Coração" /> por
          agenciaade.com.br
        </a>
      </Direitos>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { motion } from 'framer-motion';
import { useFormik } from 'formik';
import ReactFileReader from 'react-file-reader';
import Loader from 'react-loader-spinner';

import {
  Backdrop,
  Button,
  Fade,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
  Link,
  Paper,
} from '@material-ui/core';
import {
  AiFillFileAdd,
  AiFillCloseCircle,
  AiOutlinePaperClip,
} from 'react-icons/ai';
import { BsEye } from 'react-icons/bs';

import {
  Container,
  HomeContainer,
  FilterWrapper,
  TableWrapper,
  useStyles,
} from './styles';

import FixedHeader from '../../../components/HeaderAreaRestrita/FixedHeader';
import Sidebar from '../../../components/Sidebar';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/FooterAreaRestrita/Footer';
import SidebarMobile from '../../../components/SidebarMobile';
import LoaderSpinner from '../../../components/LoaderSpinner';

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import RequestsApi from '../../../services/api_externa/consulta_servicos';
import indicatorsApi from '../../../services/api_externa/dados_indicadores';

function Sacc() {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:950px)');
  const [scrollNav, setScrollNav] = useState(false);
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [chosenCategory, setChosenCategory] = useState();
  const [chosenService, setChosenService] = useState();
  const [loading, setLoading] = useState(false);
  const [disableServiceButton, setDisableServiceButton] = useState(true);
  const [alertError, setAlertError] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [dados, setDados] = useState();
  const [servicos, setServicos] = useState();
  const [currentItem, setCurrentItem] = useState();
  const [itemAttachment, setItemAttachment] = useState();
  const [message, setMessage] = useState();
  const [bodyAttachments, setBodyAttachments] = useState();
  const [loadingOpenModal, setLoadingOpenModal] = useState(false);
  const history = useHistory();

  const partyID = localStorage.getItem('party_id');

  const getDados = () => {
    indicatorsApi.getDados(partyID).then((res) => {
      setDados(res.data);
    });
  };

  useEffect(() => {
    getDados();

    RequestsApi.getTypesService().then((res) => {
      setServiceOptions(res.data);
    });
  }, []);

  useEffect(() => {
    async function getServicos() {
      const res = await RequestsApi.getRequests(partyID);
      return res;
    }

    getServicos().then((res) => {
      console.log(res);
      if (res !== undefined) {
        setServicos(res.data);
        setDisableServiceButton(false);
      }
    });
  }, []);

  let token = localStorage.getItem('token');

  if (token === 'null') {
    history.push('/area-restrita');
  }

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      filter: '',
    },
  });

  const handleFinishedPostService = () => {
    setBodyAttachments(undefined);
    formik.values.title = '';
    formik.values.description = '';
    setChosenCategory('');
    setLoading(false);
    handleClose();
  };

  const handlePublishService = () => {
    setAlertError(false);
    setSuccessAlert(false);

    if (
      formik.values.title.length === 0 ||
      formik.values.description.length === 0 ||
      !chosenCategory ||
      !chosenService
    ) {
      setAlertError(true);
      return;
    }
    setLoading(true);

    const bodyContent = {
      party_id: partyID,
      ds_titulo: formik.values.title,
      ds_descricao: formik.values.description,
      id_categoria: chosenCategory,
      id_atendimento: chosenService,
    };
    if (bodyAttachments !== undefined) {
      bodyContent.b64_anexo = bodyAttachments[0].content;
      bodyContent.nm_anexo = bodyAttachments[0].name;
    }

    RequestsApi.createRequest(bodyContent).then((res) => {
      if (res) {
        setSuccessAlert(true);
        handleFinishedPostService();
      }
    });
  };

  useEffect(() => {
    const handleFilterChange = () => {
      if (servicos) {
        if (formik.values.filter.length === 0) {
          const filteredData = servicos.items;
          return filteredData;
        }
        const filteredData = servicos.items.filter(
          (item) =>
            item.SrNumber.search(formik.values.filter.toUpperCase()) !== -1
        );
        return filteredData;
      }
    };
    handleFilterChange();
  }, [formik.values.filter]);

  const handleFiles = (files) => {
    const arrayFilesCompleted = [];

    for (let i = 0; i < files.fileList.length; i++) {
      const onlyBase64 = files.base64[i].split(',');
      arrayFilesCompleted.push({
        name: files.fileList[i].name,
        content: onlyBase64[1],
      });
    }
    setBodyAttachments(arrayFilesCompleted);
    // const json = JSON.stringify(arrayFilesCompleted);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenView = async (item) => {
    setLoadingOpenModal(true);
    setOpenView(true);
    setCurrentItem(item);
    const attachment = await RequestsApi.getDownload(item.id_solicitacao);
    const message = await RequestsApi.getRequestsAnswers(item.id_solicitacao);

    console.log('attachment', attachment);

    const blob = new Blob([attachment.data], {
      type: 'application/pdf',
    });
    setItemAttachment(URL.createObjectURL(blob));

    setMessage(message.data);
    setLoadingOpenModal(false);
  };

  const handleClose = () => {
    setOpen(false);
    setAlertError(false);
    setSuccessAlert(false);
  };

  const handleCloseView = () => {
    if (loadingOpenModal) {
      return;
    }
    setCurrentItem(undefined);
    setOpenView(false);
  };

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y < prevPos.y;
      if (isShow !== scrollNav) setScrollNav(isShow);
    },
    [scrollNav],
    null,
    true,
    0
  );

  const handleClearFiles = () => {
    setBodyAttachments(undefined);
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <>
      <Helmet>
        <title>Sementes Batavo</title>
        <meta
          name="description"
          content="Conheça as nossas cultivares e leve mais qualidade para o seu
          plantio"
        />
        <meta
          name="keywords"
          content="sementes, batavo, frísia, agroindustria, agronegocio, soja, trigo"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot1.png'}
        />
      </Helmet>
      {!!dados ? (
        <>
          {isMobile ? '' : <FixedHeader />}
          <Container>
            <Sidebar />
            <HomeContainer>
              {isMobile ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: scrollNav ? 1 : 0 }}
                  transition={{ opacity: { duration: 0.2 } }}
                  className="navbar-div"
                >
                  <Navbar dadosCadastrais={dados} />
                </motion.div>
              ) : (
                <Navbar dadosCadastrais={dados} />
              )}
              {isMobile ? <SidebarMobile /> : ''}

              <div className="title-wrapper">
                <div className="title-container">
                  <div className="empty" />
                </div>
              </div>

              <FilterWrapper>
                <div className="filters-container">
                  <div className="actions-container">

                    {!!servicos && (
                      <Button
                        className="button-open-modal"
                        type="button"
                        onClick={handleOpen}
                        disabled={disableServiceButton}
                      >
                        Criar Solicitação
                      </Button>
                    )}
                  </div>
                </div>

                <TableWrapper>
                  {servicos ? (
                    <MaterialTable
                      localization={{
                        body: {
                          emptyDataSourceMessage: 'Nenhum registro para exibir',
                        },
                        toolbar: {
                          searchTooltip: 'Pesquisar',
                          searchPlaceholder: 'Pesquisar',
                        },
                        pagination: {
                          labelRowsSelect: 'linhas',
                          labelDisplayedRows: '{count} de {from}-{to}',
                          firstTooltip: 'Primeira página',
                          previousTooltip: 'Página anterior',
                          nextTooltip: 'Próxima página',
                          lastTooltip: 'Última página',
                        },
                      }}
                      icons={tableIcons}
                      title="Minhas Solicitações de Atendimento"
                      columns={[
                        {
                          title: 'Detalhes',

                          sorting: false,
                          editable: 'never',
                          render: (rowData) =>
                            rowData && (
                              <Button onClick={() => handleOpenView(rowData)}>
                                <BsEye size={29} color="#7AB2FD" />
                              </Button>
                            ),
                        },
                        {
                          title: 'Protocolo',
                          field: 'id_solicitacao',
                          type: 'numeric',
                        },
                        {
                          title: 'Título',
                          field: 'ds_titulo',
                          type: 'string',
                        },
                        {
                          title: 'Data',
                          field: 'dt_criacao',
                          type: 'date',
                        },
                        {
                          title: 'Última alteração',
                          field: 'dt_alteracao',
                          type: 'date',
                        },
                        {
                          title: 'Atribuído a',
                          field: 'ds_atribuido',
                          type: 'string',
                        },
                        {
                          title: 'Status',
                          field: 'ds_status',
                          type: 'string',
                        },
                      ]}
                      data={servicos}
                      options={{
                        tableLayout: 'auto',
                        headerStyle: {
                          zIndex: 1,
                        },
                      }}
                      components={{
                        Container: (props) => (
                          <Paper {...props} elevation={0} />
                        ),
                      }}
                    />
                  ) : (
                    <h1 style={{ fontSize: '20px' }}>
                      Carregando ou não existem dados para serem exibidos...
                    </h1>
                  )}
                </TableWrapper>
              </FilterWrapper>
            </HomeContainer>
          </Container>
          <Footer restrictedArea />
          <Modal
            aria-labelledby="modal-nota-fiscal"
            aria-describedby="modal-nota-fiscal-descricao"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <>
                <div className={classes.paper}>
                  <div className={classes.topNav}>
                    <h3>
                      <strong>Criação de Serviços</strong>
                    </h3>
                    <div className={classes.menuOptions}>
                      <Button
                        startIcon={<AiFillFileAdd />}
                        className={classes.menuButton}
                        onClick={() => handlePublishService()}
                        disabled={loading}
                      >
                        {loading ? 'Aguarde...' : 'Salvar e fechar'}
                      </Button>
                      <Button
                        endIcon={<AiFillCloseCircle />}
                        onClick={handleClose}
                        className={classes.menuButtonExit}
                      >
                        Fechar
                      </Button>
                    </div>
                  </div>

                  {alertError ? (
                    <p style={{ color: 'red' }}>
                      Não foi possível realizar a solicitação.
                    </p>
                  ) : (
                    ''
                  )}

                  {successAlert ? (
                    <>
                      <p style={{ color: 'green' }}>
                        Sua solicitação foi enviada com sucesso!{' '}
                      </p>
                      <a href="/area-restrita/sacc">
                        Clique aqui para recarregar a página, ou feche e
                        continue navegando.
                      </a>
                    </>
                  ) : (
                    ''
                  )}

                  <div className={classes.paperInnerFlexbox}>
                    <p>Título</p>
                    <TextField
                      variant="outlined"
                      name="title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>

                  <div className={classes.paperInner}>
                    <div className={classes.paperInnerGrid}>
                      <div className={classes.paperInnerFlexbox}>
                        <p>Atendimento</p>
                        <FormControl
                          className={classes.formControl}
                          style={{ width: '100%' }}
                        >
                          <Select
                            style={{ height: '56px' }}
                            onChange={(e) => setChosenService(e.target.value)}
                            v
                            variant="outlined"
                            displayEmpty
                            value={chosenService}
                            fullWidth
                            className={classes.oportunidade}
                          >
                            <MenuItem value={''}>
                              <p></p>
                            </MenuItem>
                            {serviceOptions.map((item) => {
                              return (
                                <MenuItem value={item.id_atendimento}>
                                  {item.ds_atendimento}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>

                      <div className={classes.paperInnerFlexbox}>
                        <p>Categoria</p>
                        <FormControl
                          className={classes.formControl}
                          style={{ width: '100%' }}
                        >
                          <Select
                            style={{ height: '56px' }}
                            onChange={(e) => setChosenCategory(e.target.value)}
                            variant="outlined"
                            displayEmpty
                            value={chosenCategory}
                            fullWidth
                            className={classes.oportunidade}
                          >
                            <MenuItem value={''}>
                              <p></p>
                            </MenuItem>
                            {categoryOptions.map((item) => {
                              return (
                                <MenuItem value={item.id_categoria}>
                                  {item.ds_categoria}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>

                      <div className={classes.paperInnerFlexbox}>
                        <p>Descrição</p>
                        <TextField
                          variant="outlined"
                          name="description"
                          multiline
                          rows={5}
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>

                      <div className={classes.paperInnerFlexbox}>
                        <ReactFileReader
                          base64={true}
                          fileTypes={'*'}
                          multipleFiles={true}
                          handleFiles={handleFiles}
                        >
                          <Button className={classes.labelButton}>
                            <AiOutlinePaperClip color="#BACE25" size={28} />
                            Escolher anexos
                          </Button>
                        </ReactFileReader>
                        {!!bodyAttachments &&
                          bodyAttachments.map((item, id) => <p>{item.name}</p>)}
                      </div>

                      {!!bodyAttachments && (
                        <div className={classes.paperInnerFlexbox}>
                          <Button
                            className={classes.menuButtonExit}
                            onClick={() => handleClearFiles()}
                          >
                            Limpar anexos
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            </Fade>
          </Modal>
          <Modal
            aria-labelledby="modal-nota-fiscal-view-only"
            aria-describedby="modal-nota-fiscal-view-only"
            className={classes.modal}
            open={openView}
            onClose={handleCloseView}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            {!!currentItem && (
              <Fade in={openView}>
                {loadingOpenModal ? (
                  <>
                    <div
                      className="loader-holder"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <img
                        src={require('../../../assets/logo.svg')}
                        alt="Sementes Batavo"
                        style={{ marginBottom: '25px' }}
                      />
                      <Loader
                        type="Oval"
                        width={50}
                        height={50}
                        color="#adbf22"
                        style={{ display: 'flex' }}
                      />
                      <p style={{ color: '#adbf22', marginTop: '25px' }}>
                        Carregando informações do serviço
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.paper}>
                      <div className={classes.topNav}>
                        <h3>
                          <strong>Visualização do Serviço</strong>
                        </h3>
                        <div className={classes.menuOptions}>
                          <Button
                            endIcon={<AiFillCloseCircle />}
                            onClick={handleCloseView}
                            className={classes.menuButtonExit}
                          >
                            Fechar
                          </Button>
                        </div>
                      </div>

                      <div className={classes.paperInner}>
                        <div className={classes.paperInnerGrid}>
                          <div className={classes.paperInnerFlexbox}>
                            {currentItem.ds_titulo && (
                              <>
                                <p>Título</p>
                                <TextField
                                  disabled
                                  variant="outlined"
                                  defaultValue={currentItem.ds_titulo}
                                />
                              </>
                            )}
                            {currentItem.id_categoria && (
                              <>
                                <p>Categoria</p>
                                <TextField
                                  disabled
                                  variant="outlined"
                                  defaultValue={currentItem.id_categoria}
                                />
                              </>
                            )}
                            {!!currentItem.ds_descricao && (
                              <>
                                <p>Descrição</p>
                                <TextField
                                  disabled
                                  variant="outlined"
                                  multiline
                                  rows={5}
                                  defaultValue={currentItem.ds_descricao}
                                />
                              </>
                            )}
                          </div>
                          {!!itemAttachment ? (
                            <>
                              <div className={classes.topNav}>
                                <h3>Anexos</h3>
                              </div>
                              <div className={classes.paperInnerFlexbox}>
                                <Link
                                  download={currentItem.nm_anexo}
                                  href={itemAttachment}
                                  // href={`data:${itemAttachment};base64,application/pdf`}
                                  target="blank"
                                >
                                  {currentItem.nm_anexo}
                                </Link>
                              </div>
                            </>
                          ) : (
                            ''
                          )}
                          {!!message
                            ? message.length > 0 && (
                                <>
                                  <div className={classes.topNav}>
                                    <h3>Mensagens</h3>
                                  </div>
                                  <div className={classes.paperInnerFlexbox}>
                                    {message.map((item, id) => (
                                      <>
                                        <p>{`${item.nome} - ${item.data}`}</p>
                                        <TextField
                                          disabled
                                          variant="outlined"
                                          multiline
                                          defaultValue={item.resposta}
                                        />
                                      </>
                                    ))}
                                  </div>
                                </>
                              )
                            : ''}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Fade>
            )}
          </Modal>
        </>
      ) : (
        <LoaderSpinner />
      )}
    </>
  );
}

export default Sacc;

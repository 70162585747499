import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowDownSvg } from '../../assets/img/gray-icon-arrow-down.svg';

const GenericDropdown = ({
  label,
  placeholder,
  listShowDropdown,
  errorText,
  isResetValue,
  keyToShowInDropdown,
  className,
  disabled,
  defaultValue,
  getSelectedValue,
}) => {
  const [selectedItem, setSelectedItem] = useState(placeholder ?? '');
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  useEffect(() => {
    if (selectedItem != null && selectedItem !== placeholder) {
      getSelectedValue(selectedItem);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (isResetValue) {
      setSelectedItem(placeholder ?? '');
    }
  }, [isResetValue]);

  useEffect(() => {
    if (defaultValue != null) {
      setSelectedItem(defaultValue);
    }
  }, [defaultValue]);

  return (
    <StyledContainer className={className}>
      <StyledDiv
        onClick={() => {
          if (!disabled) {
            setIsShowDropdown(!isShowDropdown);
          }
        }}
        onBlur={() => setIsShowDropdown(false)}
        isShowDropdown={isShowDropdown}
        tabIndex={0}
      >
        <StyledInnerContainer className={`${label != null ? '' : 'margin-top'}`}>
          <div>
            <StyledLabel>{label}</StyledLabel>
            <StyledText className={`${selectedItem !== placeholder ? '' : 'style-placeholder'}`}>
              {keyToShowInDropdown != null && selectedItem !== placeholder ? selectedItem[keyToShowInDropdown] : selectedItem}
            </StyledText>
          </div>

          <StyledIconContainer className={`${isShowDropdown ? 'rotate' : ''}`}>
            <ArrowDownSvg />
          </StyledIconContainer>
        </StyledInnerContainer>

        {isShowDropdown && (
          <StyledList>
            {listShowDropdown.map((item, index) => (
              <StyledItem key={`${item}-${index}`} onClick={() => setSelectedItem(item)}>
                {keyToShowInDropdown != null ? item[keyToShowInDropdown] : item}
              </StyledItem>
            ))}
          </StyledList>
        )}
      </StyledDiv>
      <StyledErrorMessage>{errorText}</StyledErrorMessage>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative;
  height: 58px;
  width: 172px;
`;

const StyledDiv = styled.div`
  position: absolute;
  min-height: 38px;
  text-align: left;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f1f1f1;
  z-index: 1;
  border: 1px solid #D7DAE2;
  width: 172px;
  height: 38px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 3px #00000033;
  border: 1px solid #D7DAE2;
  border-radius: 4px;
  opacity: 1;
`;

const StyledInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 10px 0 10px;

  &.margin-top {
    margin-top: 6px;
  }
`;

const StyledLabel = styled.span`
  display: block;
  font-size: 10px;
  text-transform: uppercase;
`;

const StyledText = styled.div`
  color: #656565;
  font-size: 16px;

  &.style-placeholder {
    font-size: 12px;
    opacity: 0.5;
  }
`;

const StyledIconContainer = styled.div`
  margin-left: 3px;
  @keyframes arrow_animation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

  &.rotate {
    animation: arrow_animation 0.3s 0s;
    transform: rotate(180deg);
  }
`;

const StyledList = styled.ul`
  @keyframes list_animation {
    from {
      height: 0;
    }
    to {
      height: 80px;
    }
  }

  color: #656565;
  font-size: 16px;
  list-style-type: none;
  padding: 5px;
  overflow: auto;
  z-index: 10;
  background-color: white;

  li:nth-last-child(1) {
    border: none;
  }

  animation: list_animation 0.3s 0s;
  max-height: 200px;
`;

const StyledItem = styled.li`
  max-width: 70%;
  border-bottom: 1px solid #4e6ba7;
  padding: 3px 0;
`;

const StyledErrorMessage = styled.span`
  position: absolute;
  bottom: 0;
  left: 1px;

  color: red;
  font-size: 12px;
  color: red;
`;

export default GenericDropdown;

import styled from 'styled-components';

import logo from '../../assets/logo.svg';

export const Navbar = styled.div`
  position: absolute;
  z-index: 2;
  top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: all ease 0.5s;
  padding: 0 7.5%;

  @media (max-width: 1440px) {
    padding: 0 5%;
  }

  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  @media (max-width: 960px) {
    top: 0px;
    padding-top: 40px;
  }

  @media (max-width: 815px) {
    background-color: ${(props) => (props.isOpen ? '#fff' : 'transparent')};
  }

  @media (max-width: 750px) {
    padding-top: 20px;
  }

  .logo {
    display: flex;
    width: 130px;
    height: auto;

    @media (max-width: 960px) {
      position: relative;
      z-index: 1;
    }

    @media (max-width: 815px) {
      background-image: ${(props) => (props.isOpen ? `url(${logo})` : '')};
      background-size: cover;
      background-repeat: no-repeat;
    }

    @media (max-width: 750px) {
      width: 100px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;

      @media (max-width: 815px) {
        opacity: ${(props) => (props.isOpen ? '0' : '1')};
      }
    }
  }

  .hamburger {
    display: none;
    padding: 0;

    @media (max-width: 960px) {
      display: inline-block;
      position: relative;
      z-index: 1;
    }
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: #fff;
  }

  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #fff;

    @media (max-width: 815px) {
      background-color: #002762;
    }
  }

  nav {
    transition: all ease 0.5s;
    position: relative;
    z-index: 0;

    @media (max-width: 960px) {
      position: absolute;
      top: ${(props) => (props.isOpen ? '100%' : '-500px')};
      padding: 20px;
      width: 100%;
      left: 0;
    }

    @media (max-width: 815px) {
      background-color: #fff;
    }

    ul {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;

      @media (max-width: 960px) {
        justify-content: center;
      }

      @media (max-width: 815px) {
        flex-direction: column;
      }

      .social {
        display: flex;
        margin: 0 0 0 15px;
        padding-left: 15px;
        border-left: 1px solid #fff;

        @media (max-width: 1050px) {
          padding-left: 10px;
        }

        @media (max-width: 815px) {
          margin: 5px 0 0 0;
          padding-left: 0;
          border-left: none;
          border-top: 1px solid #002762;
          padding-top: 15px;
        }

        a {
          font-size: 21px;
          margin: 0 10px;

          :after {
            display: none;
          }

          :hover {
            transform: scale(1.1);

            svg {
              fill: #adbf00;
            }
          }
        }
      }

      li {
        margin: 0 15px;

        @media (max-width: 1050px) {
          margin: 0 10px;
        }

        @media (max-width: 960px) {
          margin: 0 15px;
        }

        @media (max-width: 878px) {
          margin: 0 10px;
        }

        @media (max-width: 815px) {
          margin: 5px 0;
        }

        a {
          display: flex;
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          line-height: 24px;
          text-decoration: none;
          transition: all ease 0.5s;
          position: relative;
          justify-content: center;

          @media (max-width: 815px) {
            color: #002762;
          }

          @media (max-width: 750px) {
            font-size: 16px;
            line-height: 22px;
          }

          :hover {
            :after {
              width: 100%;
            }
          }

          :after {
            content: '';
            width: 0%;
            height: 4px;
            background-color: #adbf00;
            position: absolute;
            bottom: -6px;
            transition: all ease 0.5s;
            border-radius: 30px;

            @media (max-width: 815px) {
              left: 0;
            }
          }
        }
      }
    }
  }
`;

export const FixedNavbar = styled.header`
  position: fixed;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 25px 7.5% 10px 7.5%;
  background-color: #fff;
  border-bottom: 1px solid #002762;

  @media (max-width: 1440px) {
    padding: 25px 5% 10px 5%;
  }

  @media (max-width: 1200px) {
    padding: 20px 20px 10px 20px;
  }

  @media (max-width: 815px) {
    padding: 10px 20px;
  }

  @media (max-width: 960px) {
    z-index: ${(props) => (props.isOpen ? '99' : '9')};
  }

  .logo {
    display: flex;
    width: 110px;
    height: auto;

    @media (max-width: 960px) {
      position: relative;
      z-index: 1;
    }

    @media (max-width: 750px) {
      width: 100px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .hamburger {
    display: none;
    padding: 0;

    @media (max-width: 960px) {
      display: inline-block;
      position: relative;
      z-index: 1;
    }
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: #002762;
  }

  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #002762;
  }

  nav {
    transition: all ease 0.5s;
    position: relative;
    z-index: 0;
    background-color: #fff;

    @media (max-width: 960px) {
      position: absolute;
      top: ${(props) => (props.isOpen ? '100%' : '-500px')};
      padding: 20px;
      width: 100%;
      left: 0;
    }

    ul {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;

      @media (max-width: 960px) {
        justify-content: center;
      }

      @media (max-width: 815px) {
        flex-direction: column;
      }

      .social {
        display: flex;
        margin: 0 0 0 15px;
        padding-left: 15px;
        border-left: 1px solid #002762;

        @media (max-width: 1050px) {
          padding-left: 10px;
        }

        @media (max-width: 815px) {
          margin: 5px 0 0 0;
          padding-left: 0;
          border-left: none;
          border-top: 1px solid #002762;
          padding-top: 15px;
        }

        a {
          font-size: 21px;
          margin: 0 10px;

          :after {
            display: none;
          }

          :hover {
            transform: scale(1.1);

            svg {
              fill: #adbf00;
            }
          }
        }
      }

      li {
        margin: 0 15px;

        @media (max-width: 1050px) {
          margin: 0 10px;
        }

        @media (max-width: 960px) {
          margin: 0 15px;
        }

        @media (max-width: 878px) {
          margin: 0 10px;
        }

        @media (max-width: 815px) {
          margin: 5px 0;
        }

        a {
          display: flex;
          font-size: 18px;
          font-weight: bold;
          color: #002762;
          line-height: 24px;
          text-decoration: none;
          transition: all ease 0.5s;
          position: relative;
          justify-content: center;
          align-items: center;

          @media (max-width: 750px) {
            font-size: 16px;
            line-height: 22px;
          }

          :hover {
            :after {
              width: 100%;
            }
          }

          :after {
            content: '';
            width: 0%;
            height: 4px;
            background-color: #adbf00;
            position: absolute;
            bottom: -6px;
            transition: all ease 0.5s;
            border-radius: 30px;

            @media (max-width: 815px) {
              left: 0;
            }
          }
        }
      }
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1200px) {
    padding-top: 92.17px;
  }

  @media (max-width: 815px) {
    padding-top: 82.17px;
  }

  @media (max-width: 750px) {
    padding-top: 76.61px;
  }

  .banner {
    height: 300px;
    width: 100%;
    margin-bottom: 65px;

    @media (max-width: 750px) {
      height: 200px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  h2 {
    padding: 0 20px;
    text-align: center;
  }

  p {
    font-size: 20px;
    line-height: 37px;
    color: #1d1d1d;
    margin-bottom: 40px;
    max-width: 1050px;
    text-align: center;
    padding: 0 20px;

    @media (max-width: 750px) {
      font-size: 16px;
      margin-bottom: 25px;
      line-height: 33px;
    }
  }

  .cards {
    margin-top: 65px;
    margin-bottom: 200px;
    display: grid;
    grid-template-columns: repeat(4, 24%);
    grid-auto-rows: 165px;
    grid-column-gap: 15px;
    grid-row-gap: 35px;
    max-width: 1050px;
    width: 100%;
    padding: 0 20px;

    @media (max-width: 900px) {
      grid-template-columns: repeat(3, 31%);
    }

    @media (max-width: 750px) {
      margin-top: 35px;
      margin-bottom: 0px;
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(2, 48%);
    }

    @media (max-width: 400px) {
      grid-template-columns: 200px;
      grid-row-gap: 20px;
      justify-content: center;
    }

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      border: 2px solid #002762;
      transition: all ease 0.5s;
      border-radius: 25px;

      img {
        object-fit: contain;
        width: 100%;
        height: auto;
      }

      :hover {
        border: 2px solid #adbf00;
      }
    }
  }
`;

import axios from 'axios';

const consulta_historico_volume = axios.create({
  baseURL:
    'https://frisiaintegration-idvjwsau7j9l-gr.integration.ocp.oraclecloud.com/ic/api/integration/v1/flows/rest/API_PORTALSE_HISTORIC_VOLUME/1.0',
  auth: {
    username: 'oic.invoke',
    password: 'Pa$$w0rdFr151@',
  },
});

// user: 80.798.499/0001-63
// password: 123456

export default consulta_historico_volume;

import React from 'react';
import { Helmet } from 'react-helmet';
import { ImCheckboxChecked } from 'react-icons/im';

import { Container, Vantagens } from './style';

import FixedHeader from '../../components/Header/FixedHeader';
import Footer from '../../components/Footer/Footer';

export default function Qualidade() {
  return (
    <>
      <Helmet>
        <title>Qualidade | Sementes Batavo</title>
        <meta
          name="description"
          content="A Sementes Batavo é uma marca da Frísia Cooperativa Agroindustrial. Sua história está relacionada à expansão do agronegócio no interior do Paraná e ao desenvolvimento do Plantio Direto."
        />
        <meta
          name="keywords"
          content="sementes, batavo, frísia, agroindustria, agronegocio, soja, trigo"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>
      <FixedHeader />
      <Container>
        <div className="banner">
          <img src={require('../../assets/img/qualidadebanner.jpg')} alt="" />
        </div>
        <h2>
          Sementes Batavo qualidade está em nosso <span>DNA</span>
        </h2>
        <h5>Qualidade</h5>
        <div className="secao-impar">
          <div className="text">
            <h2>
              Qualidade <br /> na
              <span> produção</span>
            </h2>
            <p>
              A marca acompanha e leva aos agricultores os avanços da pesquisa
              na área de melhoramento vegetal e de biotecnologia.
            </p>
            <p>
              Além de estar ao lado do produtor rural em momentos inovadores,
              como nos anos 1970, com o início do Plantio Direto, a Sementes
              Batavo trabalha sempre de olho no futuro. Enquanto uma safra está
              em curso, ela está plantando e beneficiando sementes que serão
              utilizadas na safra seguinte, sempre selecionando as melhores
              variedades e buscando novas variedades para oferecer ao mercado.
            </p>
            <p>
              As safras de soja dos cooperados, entre os anos de 2014 e 2018,
              demostraram produtividade 15% superior às de outros produtores do
              Estado do Paraná.
            </p>
          </div>
          <div className="img">
            <img
              src={require('../../assets/img/qualidade-producao.jpg')}
              alt=""
            />
          </div>
          <div className="line" />
        </div>
        <div className="secao-par">
          <div className="img">
            <img src={require('../../assets/img/tsi.png')} alt="" />
          </div>
          <div className="text">
            <h2>
              Qualidade no análise: <span>LAS e UBS</span>
            </h2>
            <p>
              O Laboratório de Análise de Sementes (LAS), localizado em Ponta
              Grossa, é o maior do Paraná. O LAS conta com uma área de 600 m² e
              capacidade para analisar 10 mil amostras por ano, com previsão de
              expansão para 20 mil. Certificado pelo Ministério da Agricultura,
              Pecuária e Abastecimento (MAPA), realiza testes como de
              germinação, pureza, Peso de Mil Sementes (PMS), vigor, NGMO e
              tetrazólio. Análises fundamentais para garantir a qualidade do
              produto final das Sementes Batavo.
            </p>
          </div>
          <div className="line" />
        </div>
        <div className="secao-impar">
          <div className="text">
            <h2>
              Qualidade no
              <br />
              <span>beneficiamento</span>
            </h2>
            <p>
              Os Campos Gerais têm condições favoráveis para a produção de
              sementes de soja e trigo. A região, de altitude acima de 900
              metros, proporciona clima ameno no momento da colheita e as
              temperaturas mais baixas garantem a qualidade no armazenamento da
              semente.
            </p>
            <p>
              A capacidade de expedição das duas Unidades de Beneficiamento de
              Sementes é de 20 mil sacas por dia. Estrutura que atende as
              demandas dos cooperados da Frísia Cooperativa Agroindustrial e dos
              principais players das Regiões Sul e Centro-Oeste do País.
            </p>
            <p>
              A estrutura conta ainda com armazéns com isolamento térmico, que
              proporcionam um controle de temperatura mais baixo, mantendo a
              qualidade da semente; e resfriamento de sementes, para garantir a
              longevidade do vigor, redução de pragas e patógenos.
            </p>
          </div>
          <div className="img">
            <img
              src={require('../../assets/img/qualidade-armazenagem.jpg')}
              alt=""
            />
          </div>
          <div className="line" />
        </div>
        <div className="secao-par">
          <div className="img">
            <img
              src={require('../../assets/img/qualidade-sementes.jpg')}
              alt=""
            />
          </div>
          <div className="text">
            <h2 className="sementes">
              Qualidade das <span>sementes</span>
            </h2>
            <p>
              Para se ter alto potencial produtivo em uma lavoura se faz
              necessário uma boa implantação do campo, um stand correto e
              plantas distribuídas de modo uniforme. Além disso, a utilização de
              sementes de alta qualidade fisiológica é fundamental, já que são
              elas as responsáveis por plantas vigorosas.
            </p>
            <p>
              A qualidade de uma semente é composta por diferentes atributos,
              dentre eles, o fisiológico, que considera a germinação (percentual
              de sementes que originam plântulas) e o vigor (a soma de atributos
              que conferem à semente o potencial para uma emergência rápida e
              uniforme).
            </p>
          </div>
          <div className="line" />
        </div>
        <Vantagens>
          <h2>
            Confira algumas <span>vantagens</span> de se utilizar sementes de{' '}
            <span>alto vigor:</span>
          </h2>
          <div className="cards">
            <div>
              <div className="detail">
                <ImCheckboxChecked />
                <div className="line" />
              </div>
              <p>
                Melhor geminação e emergência, mesmo sob estresse, como:
                profundidade na semeadura, veranicos, assoreamento, ataque de
                fungos e frio;
              </p>
            </div>
            <div>
              <div className="detail">
                <ImCheckboxChecked />
                <div className="line" />
              </div>
              <p>Stand adequando com plântulas vigorosas;</p>
            </div>
            <div>
              <div className="detail">
                <ImCheckboxChecked />
                <div className="line" />
              </div>
              <p>
                Plantas que emergem mais cedo têm vantagens competitivas sobre
                as que emergem mais tarde;
              </p>
            </div>
            <div>
              <div className="detail">
                <ImCheckboxChecked />
                <div className="line" />
              </div>
              <p>Primeiras folhas trifoliadas maiores;</p>
            </div>
            <div>
              <div className="detail">
                <ImCheckboxChecked />
                <div className="line" />
              </div>
              <p>Início de processo fotossintético mais cedo e eficiente;</p>
            </div>
            <div>
              <div className="detail">
                <ImCheckboxChecked />
                <div className="line" />
              </div>
              <p>Maior taxa de crescimento das plantas;</p>
            </div>
            <div>
              <div className="detail">
                <ImCheckboxChecked />
                <div className="line" />
              </div>
              <p>Maior acúmulo de matéria seca pelas plantas;</p>
            </div>
            <div>
              <div className="detail">
                <ImCheckboxChecked />
                <div className="line" />
              </div>
              <p>
                Plantas com melhor estrutura de produção: maior área foliar e
                melhor sistema radicular;
              </p>
            </div>
            <div>
              <div className="detail">
                <ImCheckboxChecked />
                <div className="line" />
              </div>
              <p>Maior rendimento de grãos – kg/ha;</p>
            </div>
            <div>
              <div className="detail">
                <ImCheckboxChecked />
                <div className="line" />
              </div>
              <p>
                Plantas com maior capacidade de produção de vagens e de grãos
                por vagens.
              </p>
            </div>
          </div>
        </Vantagens>
      </Container>
      <Footer />
    </>
  );
}

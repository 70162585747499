import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 150px;
  padding-bottom: 75px;
  width: 100%;
  min-height: 500px;
  background: #f7fbfd;

  display: flex;
  align-items: center;
  justify-content: center;

  .card {
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 70px 100px;

    @media (max-width: 590px) {
      padding: 50px 20px;
      margin: 0 20px;
    }

    h3 {
      color: #4d4f5c;
      opacity: 0.5;
    }

    form {
      margin-top: 30px;
      display: flex;
      flex-direction: column;

      .MuiFormControl-root {
        margin-bottom: 30px;
        span {
          font-size: 25px;
        }
      }

      span {
        font-size: 14px;
      }

      .termos-text {
        font-size: 12px;

        a {
          font-size: 12px;
        }
      }

      .password-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 450px) {
          flex-direction: column-reverse;
          align-items: flex-start;
        }
      }

      a {
        font-size: 14px;
        color: #43425d;
        text-decoration: underline;
        transition: all ease 0.3s;

        &:hover {
          color: #bace25;
          transition: all ease 0.3s;
        }
      }

      .button-container {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 450px) {
          flex-direction: column;
        }

        button {
          cursor: pointer;
          background: #bace25;
          border: 0;
          border-radius: 4px;
          width: 100%;
          padding: 8px 12px;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          margin-right: 8px;
          color: #fff;
          font-size: 18px;
          transition: all ease 0.3s;

          @media (max-width: 450px) {
            width: 100%;
            margin-right: 0px;
            margin-bottom: 16px;
          }

          &:hover {
            transform: translateY(5px);
            opacity: 0.7;
            transition: all ease 0.3s;
          }
        }

        a {
          margin-left: 8px;
          width: 50%;
          background: #fff;
          padding: 8px 12px;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          font-size: 18px;
          text-decoration: none;
          text-align: center;

          @media (max-width: 450px) {
            width: 100%;
            margin-left: 0px;
          }

          &:hover {
            transform: translateY(5px);
            transition: all ease 0.3s;
          }
        }
      }
    }
  }
`;

import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const Container = styled.div`
  padding-top: 90px;
  width: 100%;
  /* height: 100%; */
  background: #f7fbfd;

  display: grid;
  grid-template-areas: 'sidebar home home';
  grid-template-columns: 260px 1fr 1fr;

  @media (max-width: 1080px) {
    grid-template-columns: 160px 1fr 1fr;
  }

  @media (max-width: 960px) {
    padding-top: 0px;
    grid-template-columns: 1fr;
    grid-template-areas: 'home';
    width: 100vw;
  }
`;

export const HomeContainer = styled.div`
  grid-area: home;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    width: 100vw;
  }

  .navbar-div {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
  }

  .title-wrapper {
    margin-top: 50px;
    margin-bottom: 40px;
    width: 90%;
    max-width: 1120px;

    @media (max-width: 960px) {
      margin-top: 120px;
    }

    .title-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .empty {
        content: '';
      }

      .MuiOutlinedInput-root {
        background: #fff;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);

        .MuiOutlinedInput-notchedOutline {
          border-color: #d7dae2;
        }
      }
    }
  }
`;

export const FilterWrapper = styled.div`
  background: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  width: 90%;
  /* max-width: 1140px; */
  margin-bottom: 90px;
  padding: 16px 40px;

  @media (max-width: 960px) {
    max-width: 620px;
  }

  @media (max-width: 600px) {
    padding: 16px;
    width: 95%;
    margin-bottom: 50px;
  }

  h2 {
    font-size: 24px;
    color: #4d4f5c;
  }

  .filters-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;

    @media (max-width: 960px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    em2 {
      font-style: normal;
      font-size: 12px;
      color: #e2e1e6;
      opacity: 1;
    }

    .MuiOutlinedInput-adornedStart {
      padding-left: 0;
      height: 100%;
    }

    .MuiOutlinedInput-inputAdornedStart {
      &::placeholder {
        opacity: 1;
        font-size: 12px;
        color: #e2e1e6;
      }
    }

    .MuiInputAdornment-root {
      max-height: 100%;
      max-width: 100%;
      border-radius: 8px 0px 0px 8px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 38px;
        margin: 0 12px;
      }
    }

    .MuiOutlinedInput-root {
      width: 100%;
      height: 38px;
      border: 0;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);

      .MuiSelect-select:focus {
        background-color: transparent;
      }

      .MuiSelect-outlined.MuiSelect-outlined {
        padding-left: 12px;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
        border-radius: 8px;
      }
    }

    .MuiOutlinedInput-inputAdornedStart {
      padding-left: 12px;
    }
  }
`;

export const TableWrapper = styled.div`
  margin-bottom: 30px;
  /* overflow-x: scroll; */

  .MuiTableContainer-root {
    &::-webkit-scrollbar {
      bottom: 0;
      position: absolute;
      visibility: visible;
      opacity: 1;
      background: rgba(155, 155, 155, 0.2);
      border-radius: 0px 0px 5px 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(155, 155, 155, 0.4);
      border-radius: 5px;
    }
  }

  /* .MuiTable-root {
    min-width: 1160px;
    max-width: 1160px;
  } */

  tbody tr:nth-child(2n) {
    background: #f7f7f7;
  }

  tbody tr:nth-child(2n + 1) {
    background: #eaeaef;
  }

  thead tr {
    background: transparent;
  }

  img {
    margin-right: 12px;
  }
`;

export const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
  },
  filterIcon: {
    background: '#a5a4bf',
    height: '100%',
    width: '50px',
    margin: '0',
  },
  filterIconFocus: {
    background: '#ADBF22',
    height: '100%',
    width: '50px',
    margin: '0',
  },
  table: {
    marginTop: '30px',
    marginBottom: '0px',
  },
  tableBody: {
    boxShadow: '2px 3px 6px rgba(0,0,0,0.16)',
  },
  noFilter: {
    padding: '70px 0px',
    background: '#fff',
    color: '#D9D9D9',
    fontWeight: 'bold',
    fontSize: '22px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#F7FBFD',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  topNav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  menuOptions: {
    marginTop: '25px',
    '@media(max-width: 600px)': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
  },
  menuButton: {
    margin: '4px 8px',
  },
  imprimirButton: {
    color: '#adbf00',
    margin: '0 8px',
    root: {
      '&:hover': {
        backgroundColor: 'rgba(173, 191, 0, 0.3)',
      },
    },
  },
  paperInner: {
    marginTop: '40px',
    maxWidth: '1080px',
    maxHeight: '400px',
    overflowY: 'scroll',
    backgroundColor: '#FFF',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    borderRadius: '8px',
    '& ::-webkit-scrollbar': {
      width: '10px',
    },
    '& ::-webkit-scrollbar-thumb': {
      background: '#D6D5DB',
      borderRadius: '9px',
    },
    '@media (max-width: 600px)': {
      maxWidth: '100%',
      marginTop: '20px',
    },
  },
  paperInnerTitle: {
    paddingTop: '30px',
    fontSize: '22px',
    padding: '0 20px',
    alignSelf: 'flex-start',
  },
  paperInnerSecondSection: {
    background: '#F7F7F7',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperInnerGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    marginBottom: '16px',
    padding: '0 20px',
    '@media (max-width: 600px)': {
      gridTemplateColumns: '0.95fr',
    },
  },
  paperInnerSecondFlexbox: {
    paddingBottom: '16px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '@media(max-width: 600px)': {
      padding: '0px 20px',
      paddingBottom: '16px',
    },
  },
  paperInnerFlex: {
    padding: '0 20px',
    paddingBottom: '16px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  paperInnerFlexbox: {
    margin: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media(max-width: 600px)': {
      width: '100%',
    },
    '& p': {
      width: '40%',
      textAlign: 'right',
      margin: 0,
      marginRight: '16px',
      padding: 0,
    },
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  empty: {
    content: '',
    width: '100%',
  },
  innerFlexboxInput: {
    background: '#fff',
  },
  calendarInputContainer: {
    padding: 'unset',
    paddingLeft: '7px',
    height: '40px',
  },
  calendarInput: {
    padding: 'unset',
    paddingLeft: '7px',
  },
  calendarInputIcon: {
    background: '#E6EFF4',
    margin: '0',
    height: '100%',
    maxHeight: 'unset',
    width: '60px',
    display: 'flex',
    borderRadius: '0px 5px 5px 0px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  oportunidade: {
    height: '40px',
    '& .MuiSelect-select': {
      padding: '0px 0px',
      paddingLeft: '14px',
    },
    '& .MuiSvgIcon-root': {
      background: '#E6EFF4',
      height: '100%',
      width: '50px',
      top: 0,
      right: 0,
      '& path': {
        color: '#032762',
      },
    },
  },
}));

export const useStyles1 = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
  },
  filterIcon: {
    background: '#a5a4bf',
    height: '100%',
    width: '50px',
    margin: '0',
  },
  filterIconFocus: {
    background: '#ADBF22',
    height: '100%',
    width: '50px',
    margin: '0',
  },
  table: {
    marginTop: '30px',
    marginBottom: '0px',
  },
  tableBody: {
    boxShadow: '2px 3px 6px rgba(0,0,0,0.16)',
  },
  noFilter: {
    padding: '70px 0px',
    background: '#fff',
    color: '#D9D9D9',
    fontWeight: 'bold',
    fontSize: '22px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .makeStyles-paper-9': {
      borderRadius: '8px',
    },
  },
  paper: {
    backgroundColor: '#F7FBFD',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: 'scroll',
    height: '90vh',
    // '@media (max-width: 960px)': {
    // },
  },
  topNav: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
    '& h3': {
      fontSize: '22px',
      margin: '0',
      padding: '0',
    },
  },
  menuOptions: {
    padding: '8px',
    '@media(max-width: 960px)': {
      marginTop: '15px',
    },
    '@media(max-width: 600px)': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
    'MuiButton-root': {
      '&:hover': 'opacity: 0.7',
    },
  },
  menuButton: {
    margin: '4px',
    backgroundColor: '#1467EF',
    color: '#fff',
    fontSize: '14px',
    padding: '6px 12px',
    textTransform: 'unset',
    transition: 'all ease 0.5s',
    '&:hover': {
      backgroundColor: 'rgba(20, 103, 239, 0.7)',
    },
    root: {},
  },
  menuButtonExit: {
    margin: '4px',
    fontSize: '14px',
    padding: '6px 12px',
    color: '#fff',
    backgroundColor: '#FF0000',
    textTransform: 'unset',
    transition: 'all ease 0.5s',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 0, 0.7)',
    },
    root: {},
  },
  imprimirButton: {
    backgroundColor: '#adbf00',
    color: '#fff',
    margin: '0 4px',
    fontSize: '14px',
    padding: '12px',
    textTransform: 'unset',
    transition: 'all ease 0.5s',
    '&:hover': {
      backgroundColor: 'rgba(173, 191, 0, 0.7)',
      root: {},
    },
  },
  paperInner: {
    marginTop: '10px',
    // padding: theme.spacing(2, 4, 3),
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& ::-webkit-scrollbar': {
      width: '10px',
    },
    '& ::-webkit-scrollbar-thumb': {
      background: '#D6D5DB',
      borderRadius: '9px',
    },
    '@media (max-width: 600px)': {
      maxWidth: '100%',
      marginTop: '20px',
      maxHeight: '100%',
      overflowY: 'scroll',
    },
  },
  paperInnerGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    marginBottom: '16px',
    '@media (max-width: 600px)': {
      gridTemplateColumns: '1fr',
      paddingRight: '8px',
      height: '60vh',
    },
  },
  paperInnerFlex: {
    padding: '0 20px',
    paddingBottom: '16px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  paperInnerFlexbox: {
    margin: '8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '@media(max-width: 600px)': {
      width: '100%',
    },
    '& p': {
      width: '100%',
      textAlign: 'left',
      margin: '0px 0px',
      padding: 0,
    },
    '& .MuiTextField-root': {
      width: '100%',
    },
  },

  paperInnerFlexboxButtons: {
    width: '100%',
    display: 'flex',
  },

  oportunidade: {
    height: '40px',
    '& .MuiSelect-select': {
      padding: '0px 0px',
      paddingLeft: '14px',
    },
    '& .MuiSvgIcon-root': {
      background: '#E6EFF4',
      height: '100%',
      width: '50px',
      top: 0,
      right: 0,
      '& path': {
        color: '#032762',
      },
    },
  },

  labelButton: {
    color: '#BACE25',
    backgroundColor: '#fff',
    padding: '4px 12px',
    marginBottom: '10px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    margin: 'auto',
    marginRight: '10px',
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    cursor: 'pointer',
    transition: 'all ease 0.5s',
    '&:hover': {
      opacity: '0.7',
    },
  },

  fileInput: {
    color: '#FE1C1C',
    backgroundColor: '#fff',
    borderRadius: '4px',
    border: 'none',
    padding: '4px 12px',
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    margin: 'auto',
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    transition: 'all ease 0.5s',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.7',
    },
  },

  inputFilter2: {
    '@media (max-width: 960px)': {
      marginTop: '15px',
    },
  },
}));

export const SelectCrop = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  place-content: flex-end;
  padding-right: 50px;
  padding-bottom: 17px;
  gap: 12px;
  h3 {
    font-size: 24px;
  }
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  h2 {
    font-size: 32px;
    min-width: 185px;
  }
`;

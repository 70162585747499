/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { motion } from 'framer-motion';
import { MenuItem, Select } from '@material-ui/core';
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { Container, HomeContainer } from './styles';
import FixedHeader from '../../../components/HeaderAreaRestrita/FixedHeader';
import Sidebar from '../../../components/Sidebar';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/FooterAreaRestrita/Footer';
import SidebarMobile from '../../../components/SidebarMobile';
import LoaderSpinner from '../../../components/LoaderSpinner';
import indicatorsApi from '../../../services/api_externa/dados_indicadores';
import dados_cadastrais from '../../../services/api_externa/dados_cadastrais';
import consulta_historico_volume from '../../../services/api_externa/consulta_historico_volume';

function Home() {
  const isMobile = useMediaQuery('(max-width:900px)');
  const isMobile2 = useMediaQuery('(max-width:600px)');
  const [scrollNav, setScrollNav] = useState(false);
  const [cropList, setcropList] = useState([]);
  const [chosenCrop, setChosenCrop] = useState('20212022');

  const [dados, setDados] = useState();
  const [indicadores, setIndicadores] = useState();
  const [volumeHistory, setVolumeHistory] = useState();
  const history = useHistory();

  const partyID = localStorage.getItem('party_id');

  useEffect(() => {
    async function getDados() {
      const res = await indicatorsApi.getDados(partyID);
      if (res.data.Success === 'false') {
        history.push('/area-restrita');
      } else {
        return res;
      }
    }
    getDados()
      .then((res) => {
        if (res !== undefined) {
          setDados(res.data);
        }
      })
      .catch((res) => {
        history.push('/area-restrita');
      });
  }, []);

  useEffect(() => {
    async function getSafras() {
      const res = await indicatorsApi.getSafras(partyID);
      return res.data;
    }
    getSafras().then((res) => {
      if (res !== undefined) {
        setcropList(res);
      }
    });

    async function getIndicadores() {
      const res = await indicatorsApi.generalIndicators(partyID);
      if (res.data.Sucess === 'false') {
        history.push('/area-restrita');
      } else {
        return res;
      }
    }
    getIndicadores().then((res) => {
      console.log(res);
      if (res !== undefined) {
        setIndicadores(res.data);
      }
    });
  }, []);

  useEffect(() => {
    indicatorsApi.volumeIndicators(partyID, chosenCrop).then((res) => {
      if (res !== undefined) {
        setVolumeHistory(res.data);
      }
    });
  }, [chosenCrop]);

  let token = localStorage.getItem('token');

  if (token === 'null') {
    history.push('/area-restrita');
  }

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y < prevPos.y;
      if (isShow !== scrollNav) setScrollNav(isShow);
    },
    [scrollNav],
    null,
    true,
    0
  );


  return (
    <>
      <Helmet>
        <title>Sementes Batavo</title>
        <meta
          name="description"
          content="Conheça as nossas cultivares e leve mais qualidade para o seu
          plantio"
        />
        <meta
          name="keywords"
          content="sementes, batavo, frísia, agroindustria, agronegocio, soja, trigo"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot1.png'}
        />
      </Helmet>
      {!!dados ? (
        <>
          {isMobile ? '' : <FixedHeader />}
          <Container>
            <Sidebar />
            <HomeContainer>
              {isMobile ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: scrollNav ? 1 : 0 }}
                  transition={{ opacity: { duration: 0.2 } }}
                  className="navbar-div"
                >
                  <Navbar isIndicadores={true} dadosCadastrais={dados} />
                </motion.div>
              ) : (
                <Navbar isIndicadores={true} dadosCadastrais={dados} />
              )}
              {isMobile ? <SidebarMobile /> : ''}

              <div className="indicadores-wrapper">
                <div className="title-container">
                  <h2>Indicadores</h2>
                </div>

                <div className="indicadores-container">
                  <div className="card">
                    <div className="card-title">
                      <div>
                        <img
                          src={require('../../../assets/img/contractIcon.svg')}
                          alt="Ícone de contrato"
                        />
                        <h3>Contratos Negociados</h3>
                      </div>
                      <HiOutlineDotsHorizontal color="#A4AFB7" size={32} />
                    </div>
                    {!!indicadores && (
                      <div className="card-content">
                        <ul>
                          <li>
                            <a href="/area-restrita/contrato">
                              Contratos: {indicadores.contratosNegocTotal}
                            </a>
                          </li>
                          <li>
                            <a href="/area-restrita/contrato">
                              Em Aberto: {indicadores.contratosNegocAberto}
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>

                  <div className="card">
                    <div className="card-title">
                      <div>
                        <img
                          src={require('../../../assets/img/volumeIcon.svg')}
                          alt="Ícone de saco de grãos"
                        />
                        <h3>Volume em Aberto</h3>
                      </div>
                      <HiOutlineDotsHorizontal color="#A4AFB7" size={32} />
                    </div>
                    {!!indicadores && (
                      <div className="card-content">
                        <ul>
                          <li>
                            <a href="/area-restrita/contrato">
                              Bag Negociado: {indicadores.totalQtBagNegociado}
                            </a>
                          </li>
                          <li>
                            <a href="/area-restrita/contrato">
                              Saca Negociado: {indicadores.totalQtSacaNegociado}
                            </a>
                          </li>
                          <li>
                            <a href="/area-restrita/contrato">
                              Bag Realizado: {indicadores.totalQtBagRealizado}
                            </a>
                          </li>
                          <li>
                            <a href="/area-restrita/contrato">
                              Saca Realizado: {indicadores.totalQtSacaRealizado}
                            </a>
                          </li>
                          <li>
                            <a href="/area-restrita/contrato">
                              Bag Disponível: {indicadores.totalQtBagDisponivel}
                            </a>
                          </li>
                          <li>
                            <a href="/area-restrita/contrato">
                              Saca Disponível:{' '}
                              {indicadores.totalQtSacaDisponivel}
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>

                  <div className="card">
                    <div className="card-title">
                      <div>
                        <img
                          src={require('../../../assets/img/financeiroIcon.svg')}
                          alt="Ícone de dinheiro"
                        />
                        <h3>Financeiro</h3>
                      </div>
                      <HiOutlineDotsHorizontal color="#A4AFB7" size={32} />
                    </div>
                    {!!indicadores && (
                      <div className="card-content">
                        <ul>
                          <li>
                            <a href="/area-restrita/notas-fiscais">
                              Títulos Pagos: {indicadores.titulosPagos}
                            </a>
                          </li>
                          <li className="title-aberto">
                            <a href="/area-restrita/notas-fiscais">
                              Títulos em Aberto: {indicadores.titulosEmAberto}
                            </a>
                          </li>
                          <li className="title-vencido">
                            <a href="/area-restrita/notas-fiscais">
                              Títulos Vencidos: {indicadores.titulosVencidos}
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="grafico-container">
                <div className="grafico-title">
                  <h2>Histórico de volume</h2>
                  <div>
                    <h2>Cultivares</h2>

                    <Select
                      labelId="label-select-safra"
                      id="select-safra"
                      label="Safras"
                      fullWidth
                      value={chosenCrop}
                      onChange={(e) => setChosenCrop(e.target.value)}
                    >
                      {cropList.map((cropItem) => {
                        return (
                          <MenuItem value={cropItem.cd_safra}>
                            {`Safra ${cropItem.ds_safra}`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </div>
                {isMobile2
                  ? !!volumeHistory && (
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          data={volumeHistory.map((item) => {
                            return {
                              ...item,
                              TotalContratado: item.vl_total_contratado,
                              TotalCarregado: item.vl_total_carregado,
                            };
                          })}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <XAxis
                            dataKey="Variedade"
                            label={{ fontWeight: 'bold' }}
                          />
                          <Tooltip />
                          <Legend
                            verticalAlign="top"
                            align="left"
                            height={100}
                            iconType="circle"
                            iconSize={29}
                          />
                          <Bar dataKey="TotalContratado" fill="#8884d8" />
                          <Bar dataKey="TotalCarregado" fill="#82ca9d" />
                        </BarChart>
                      </ResponsiveContainer>
                    )
                  : !!volumeHistory && (
                      <ResponsiveContainer width="100%" height={500}>
                        <BarChart
                          data={volumeHistory.map((item) => {
                            return {
                              ...item,
                              TotalContratado: item.vl_total_contratado,
                              TotalCarregado: item.vl_total_carregado,
                            };
                          })}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid
                            strokeDasharray="3 0"
                            vertical={false}
                          />
                          <XAxis
                            dataKey="ds_produto"
                            label={{ fontWeight: 'bold' }}
                          />
                          <YAxis />
                          <Tooltip />
                          <Legend
                            verticalAlign="top"
                            align="left"
                            height={100}
                            iconType="circle"
                            iconSize={29}
                          />
                          <Bar dataKey="TotalContratado" fill="#8884d8"></Bar>
                          <Bar dataKey="TotalCarregado" fill="#82ca9d" />
                        </BarChart>
                      </ResponsiveContainer>
                    )}
              </div>
            </HomeContainer>
          </Container>
          <Footer restrictedArea />
        </>
      ) : (
        <LoaderSpinner />
      )}
    </>
  );
}

export default Home;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from '@material-ui/core';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import CollapsibleTable from  '../../../components/CollapsibleTable/CollapsibleTable';
import GenericDropdown from '../../../components/GenericDropdown/GenericDropdown';

import {
  Container,
  HomeContainer,
  FilterWrapper,
  ComponentsWrapper
} from './styles';

import FixedHeader from '../../../components/HeaderAreaRestrita/FixedHeader';
import Sidebar from '../../../components/Sidebar';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/FooterAreaRestrita/Footer';
import SidebarMobile from '../../../components/SidebarMobile';
import LoaderSpinner from '../../../components/LoaderSpinner';
import oracleCRMApi from '../../../services/api_externa/oracle_crm_api';
import dados_cadastrais from '../../../services/api_externa/dados_cadastrais';

function AproveContract() {
  const isMobile = useMediaQuery('(max-width:900px)');
  const [scrollNav, setScrollNav] = useState(false);
  const [chosenCrop, setChosenCrop] = useState();
  const [searchTerm, setSearchTerm] = useState('');

  const [showOpportunities, setShowOpportunities] = useState();
  const [allOpportunities, setAllOpportunities] = useState();
  const [crop, setCrop] = useState([]);

  const [dados, setDados] = useState();
  const history = useHistory();

  async function getDados() {
    const res = await dados_cadastrais.get(
      `getCadastro?Token=${localStorage.getItem('token')}`
    );
    if (res.data.Success === 'false') {
      history.push('/area-restrita');
    } else {
      return res;
    }
  }

  async function getPartyId(matricula) {
      const res = await oracleCRMApi.get(
        `accounts?q=OrganizationDEO_Matricula_c=${matricula}`
      );
      if (res.data.Success === 'false') {
        history.push('/area-restrita');
      } else {
        getOpportunities(res.data.items[0].PartyId);
      }
    
  }

  async function getOpportunities(PartyId) {
    const res = await oracleCRMApi.get(
      `/opportunities?q=SalesMethod=UBS;TargetPartyId=${PartyId}`
    );
    if (res.data.Success === 'false') {
      history.push('/area-restrita');
    } else {
      setShowOpportunities(res.data.items);
      setAllOpportunities(res.data.items);
      setCrop(res.data.items.map(item => item.Safra_c).filter((v, i, a) => a.indexOf(v) === i).map(item => `Safra ${item}`))
    }
  
}

  const callAPIs = async () => {
    await getDados().then((res) => {
      if (res !== undefined) {
        setDados(res.data);
        getPartyId(res.data.OrganizationDEO_Matricula_c);
      }
    });
  }

  useEffect(() => {
    callAPIs();
  }, []);

  useEffect(() => {
    if(chosenCrop === 'Todas as Safras'){
      setShowOpportunities(allOpportunities);
    } else if (chosenCrop) {
      setShowOpportunities(allOpportunities.filter(item => item.Safra_c === chosenCrop?.replace('Safra ','')))
    }
  }, [chosenCrop]);

  useEffect(() => {
    if (searchTerm !== '') {
      const filteredData = allOpportunities.filter(contract => {
        return `Contrato ${contract.Name} ${contract.TargetPartyName} Data de vencimento: ${new Date(contract.DataDeValidade_c).toLocaleDateString()}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setShowOpportunities(filteredData);
    } else {
      setShowOpportunities(allOpportunities);
    }
  }, [searchTerm]);

  let token = localStorage.getItem('token');

  if (token === 'null') {
    history.push('/area-restrita');
  }

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y < prevPos.y;
      if (isShow !== scrollNav) setScrollNav(isShow);
    },
    [scrollNav],
    null,
    true,
    0
  );


  return (
    <>
      <Helmet>
        <title>Sementes Batavo</title>
        <meta
          name="description"
          content="Conheça as nossas cultivares e leve mais qualidade para o seu
          plantio"
        />
        <meta
          name="keywords"
          content="sementes, batavo, frísia, agroindustria, agronegocio, soja, trigo"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot1.png'}
        />
      </Helmet>
      {!!dados ? (
        <>
          ({isMobile ? '' : <FixedHeader />}
          <Container>
            <Sidebar />
            <HomeContainer>
              {isMobile ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: scrollNav ? 1 : 0 }}
                  transition={{ opacity: { duration: 0.2 } }}
                  className="navbar-div"
                >
                  <Navbar dadosCadastrais={dados} />
                </motion.div>
              ) : (
                <Navbar dadosCadastrais={dados} />
              )}
              {isMobile ? <SidebarMobile /> : ''}

              <div className="title-wrapper">
                <div className="title-container">
                  <h2>Pedidos</h2>
                  <div className="empty" />
                </div>
              </div>

                {showOpportunities ? (
                  <ComponentsWrapper>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <StyledInput placeholder='Localizar'
            onChange={e => setSearchTerm(e.target.value)}
            />
                      <GenericDropdown
                        defaultValue={'Todas as Safras'}
                        listShowDropdown={['Todas as Safras', ...crop]}
                        getSelectedValue={setChosenCrop}
                      />
                    </div>
                  {showOpportunities?.map((contract, index) => (
                    <CollapsibleTable
                      headerItems={[`Contrato ${contract.Name}`, `${contract.TargetPartyName}`, `Valor: ${contract.SaldoAtual2_c}`, `Data de vencimento: ${new Date(contract.DataDeValidade_c).toLocaleDateString()}`, '']}
                      contractName={contract.OptyNumber}
                      secondHeaderItems={['Produto', 'UDM', 'Volume', 'Valor unitário (kg)', 'Preço Final', 'Fórmula TSI']}
                      
                      key={`tabel-collapsible-${index}`}
                      isInvertLineColor
                    />
                  ))}
                  </ComponentsWrapper>
                ) : (
                <FilterWrapper>
                  <h1 style={{ fontSize: '20px' }}>
                    Carregando ou não existem dados para serem exibidos...
                  </h1>
                </FilterWrapper>
                )}
            </HomeContainer>
          </Container>
          <Footer restrictedArea />
         
          )
        </>
      ) : (
        <LoaderSpinner />
      )}
    </>
  );
}

export const StyledInput = styled.input`
  top: 330px;
  left: 313px;
  width: 520px;
  height: 32px;
  background: #FEFEFE 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  opacity: 1;
  border: none;
  margin-bottom: 26px;
  padding-left: 15px;
`;

export default AproveContract;

import axios from 'axios';

const mudar_senha = axios.create({
  baseURL:
    'https://frisiaintegration-idvjwsau7j9l-gr.integration.ocp.oraclecloud.com/ic/api/integration/v1/flows/rest/API_PORTALSEME_NEW_PASSWORD/1.0',
  auth: {
    username: 'oic.invoke',
    password: 'Pa$$w0rdFr151@',
  },
});

export default mudar_senha;

// 05616489999
// user: 80.798.499/0001-63
// password: 123456

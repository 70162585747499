import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { TextField, Button } from '@material-ui/core';
import InputMask from 'react-input-mask';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Container } from './styles';

import FixedHeader from '../../../components/Header/FixedHeader';
import Footer from '../../../components/FooterAreaRestrita/Footer';

import recuperar_senha from '../../../services/api_externa/recuperar_senha';

function AreaRestrita() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);

  const formik = useFormik({
    initialValues: {
      dado: '',
    },

    validationSchema: Yup.object({
      dado: Yup.string().required(
        '*Por favor informe seu e-mail ou número de telefone'
      ),
    }),
  });

  const handleSubmit = async () => {
    setAlertError(false);
    setLoading(true);
    const res = await recuperar_senha(formik.values.dado);
    setLoading(false);
    if (!res) {
      setAlertError(true);
    } else if (res) {
      history.push('/area-restrita/recuperar-senha');
    }
  };

  return (
    <>
      <Helmet>
        <title>Sementes Batavo</title>
        <meta
          name="description"
          content="Conheça as nossas cultivares e leve mais qualidade para o seu
          plantio"
        />
        <meta
          name="keywords"
          content="sementes, batavo, frísia, agroindustria, agronegocio, soja, trigo"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot1.png'}
        />
      </Helmet>
      <FixedHeader />
      <Container>
        <div className="card">
          <h1>Faça seu primeiro acesso ou recupere sua senha!</h1>
          <h3>Digite seu CPF ou CNPJ.</h3>

          <form onSubmit={handleSubmit}>
            <InputMask
              name="dado"
              mask={
                formik.values.dado.length <= 14
                  ? '999.999.999-99?'
                  : '99.999.999/9999-99'
              }
              maskChar={null}
              formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
              value={formik.values.dado}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {(inputProps) => (
                <TextField {...inputProps} type="text" label="CPF/CNPJ" />
              )}
            </InputMask>
            {alertError ? (
              <p style={{ color: 'red' }}>CPF ou CNPJ errado!</p>
            ) : (
              ''
            )}
            <div className="button-container">
              <Button type="button" onClick={handleSubmit} disabled={loading}>
                {loading ? 'Aguarde...' : 'Próximo'}
              </Button>
            </div>
          </form>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default AreaRestrita;

import styled from 'styled-components';

import smallArrow from '../../assets/img/smallArrowWhite.svg';
import bigArrow from '../../assets/img/bigArrowWhite.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
`;

export const Banner = styled.section`
  position: relative;
  width: 100%;
  /* height: 100vh; */
  height: 800px;
  display: flex;
  align-items: flex-end;
  z-index: 10;

  @media (max-width: 1600px) {
    height: 700px;
  }

  @media (max-width: 750px) {
    height: 500px;
  }

  .img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .text {
    width: 100%;
    height: calc(100% - 126.63px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 7.5%;

    @media (max-width: 750px) {
      padding: 0 20px;
      height: 100%;
    }

    h1 {
      font-size: 60px;
      line-height: 50px;
      font-weight: bold;
      color: #fff;
      max-width: 375px;
      margin-bottom: 20px;

      @media (max-width: 750px) {
        font-size: 45px;
        line-height: 35px;
      }
    }

    p {
      font-size: 30px;
      line-height: 28px;
      max-width: 475px;
      margin-bottom: 40px;
      color: #fff;

      @media (max-width: 750px) {
        font-size: 20px;
        max-width: 305px;
        margin-bottom: 30px;
        line-height: 20px;
      }
    }
  }
`;

export const Cultivares = styled.section`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 85px 20px 20px 20px;
  margin-bottom: 280px;
  width: 100%;
  background-color: #fff;
  position: relative;
  z-index: 10;

  @media (max-width: 1250px) {
    padding: 50px 20px 0px 20px;
    margin-bottom: 180px;
    flex-direction: column-reverse;
  }

  @media (max-width: 750px) {
    margin-bottom: 100px;
  }

  .text {
    max-width: 400px;
    position: relative;

    @media (max-width: 1250px) {
      align-self: flex-start;
      left: 6%;
      padding-bottom: 40px;
    }

    @media (max-width: 660px) {
      left: 0px;
      border-bottom: 4px solid #adbf00;
    }

    h2 {
      margin-bottom: 45px;

      @media (max-width: 750px) {
        margin-bottom: 30px;
      }

      span {
        font-size: 30px;
        line-height: 25px;
        font-weight: 400;

        @media (max-width: 750px) {
          line-height: 15px;
          font-size: 20px;
        }
      }
    }

    p {
      font-size: 20px;
      line-height: 37px;
      color: #1d1d1d;
      margin: 0;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }

  .imagens {
    display: grid;
    grid-template-columns: repeat(2, 400px);
    grid-gap: 20px;
    padding-right: 325px;
    position: relative;
    padding-bottom: 55px;

    @media (max-width: 1600px) {
      padding-right: 165px;
      grid-template-columns: repeat(2, 300px);
    }

    @media (max-width: 1250px) {
      align-self: flex-end;
    }

    @media (max-width: 820px) {
      align-self: center;
      padding-bottom: 35px;
      padding-right: 0;
    }

    @media (max-width: 660px) {
      padding-bottom: 15px;
      grid-gap: 10px;
      grid-template-columns: repeat(2, 48%);
    }

    a {
      position: relative;
      z-index: 11;
      width: 100%;
      border-radius: 30px;
      height: auto;
      overflow: hidden;

      @media (max-width: 450px) {
        max-height: 235px;
      }

      :hover {
        .texto {
          opacity: 1;
        }

        .img {
          transform: scale(1.2);
        }
      }

      .img {
        width: 100%;
        height: 100%;
        transition: all ease 0.5s;

        img {
          border-radius: 30px;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .texto {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        background-color: rgba(0, 0, 0, 0.6);
        top: 0;
        left: 0;
        padding: 0 20px 20% 20px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        opacity: 0;
        transition: all ease 0.5s;

        @media (max-width: 750px) {
          padding: 20px;
        }

        p {
          font-size: 60px;
          color: #fff;
          font-weight: bold;
          margin: 0;
          position: relative;
          z-index: 1;
          display: flex;
          flex-direction: column;
          align-items: center;

          @media (max-width: 750px) {
            font-size: 50px;
          }

          @media (max-width: 500px) {
            font-size: 35px;
          }

          :after {
            content: '';
            position: absolute;
            height: 60px;
            width: 235px;
            background-color: #afc007;
            border-radius: 40px;
            bottom: -30px;
            z-index: -1;

            @media (max-width: 750px) {
              height: 40px;
              bottom: -5px;
              width: 120%;
            }

            @media (max-width: 500px) {
              height: 30px;
            }

            @media (max-width: 370px) {
              bottom: 0px;
              height: 20px;
            }
          }
        }
      }
    }

    .detail {
      position: absolute;
      right: 0;
      bottom: 0;

      @media (max-width: 1600px) {
        width: 400px;
      }

      @media (max-width: 820px) {
        right: -150px;
      }

      @media (max-width: 660px) {
        right: -65px;
        width: 65%;
      }

      img {
        object-fit: contain;
        width: 100%;
      }
    }
  }

  .line {
    position: absolute;
    width: 56%;
    height: 4px;
    background-color: #adbf00;
    bottom: 20px;
    left: calc(6% + 20px);

    @media (max-width: 660px) {
      display: none;
    }
  }
`;

export const Tsi = styled.section`
  padding: 0 20px 400px 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    padding: 0 10px 200px 10px;
    flex-direction: column;
  }

  @media (max-width: 980px) {
    padding: 0 10px 100px 10px;
  }

  @media (max-width: 550px) {
    padding: 0 10px 50px 10px;
  }

  .img {
    position: relative;
    border-radius: 50px;
    z-index: 1;
    right: -75px;
    width: 40%;
    height: 100%;
    top: 0;

    @media (max-width: 1300px) {
      right: -30px;
    }

    @media (max-width: 1000px) {
      align-self: flex-end;
      width: 60%;
      right: -5px;
    }

    img {
      border-radius: 50px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .texto {
    background-color: #002762;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    padding: 145px 20px 75px 13%;
    position: relative;
    left: -75px;
    top: 90px;
    width: 55%;

    @media (max-width: 1440px) {
      width: 60%;
    }

    @media (max-width: 1300px) {
      padding: 145px 20px 75px 8%;
      left: -30px;
    }

    @media (max-width: 1000px) {
      top: -100px;
      left: unset;
      width: 100%;
    }

    @media (max-width: 750px) {
      top: -60px;
      padding: 100px 20px 75px 20px;
    }

    @media (max-width: 380px) {
      top: -80px;
    }

    h2 {
      max-width: 675px;
      color: #fff;
      line-height: 52px;
      margin-bottom: 20px;

      @media (max-width: 750px) {
        font-size: 28px;
        line-height: 35px;
      }
    }

    p {
      font-size: 20px;
      color: #afc007;
      font-weight: 400;
      margin-bottom: 60px;

      @media (max-width: 750px) {
        font-size: 18px;
        margin-bottom: 40px;
      }
    }
  }

  .bg {
    position: absolute;
    bottom: 0;
    left: -20px;
    z-index: -1;

    @media (max-width: 980px) {
      height: 50%;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;

export const Historia = styled.section`
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 280px;
  padding: 0 20px;

  @media (max-width: 980px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 750px) {
    margin-bottom: 100px;
  }

  .img {
    position: absolute;
    right: -30px;
    top: -155px;
    border-radius: 50px;
    height: calc(100% + 155px);
    width: 55%;

    @media (max-width: 1440px) {
      width: 50%;
    }

    @media (max-width: 980px) {
      top: -80px;
      position: relative;
      width: 70%;
      align-self: flex-start;
      right: unset;
    }

    @media (max-width: 550px) {
      top: -40px;
      width: 100%;
    }

    img {
      border-radius: 50px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .texto {
    max-width: 630px;
    margin-top: 75px;
    left: 7.5%;
    position: relative;
    width: 35%;

    @media (max-width: 1440px) {
      width: 41%;
      left: 5%;
    }

    @media (max-width: 980px) {
      width: 100%;
      left: unset;
      margin-top: 0;
    }

    p {
      font-size: 20px;
      line-height: 37px;
      color: #1d1d1d;
      margin: 0;
      margin-bottom: 70px;

      @media (max-width: 750px) {
        font-size: 16px;
        margin-bottom: 40px;
        line-height: 30px;
      }
    }

    .arrowbtn {
      background-color: #adbf00;

      :hover {
        .arrow {
          background-image: url(${bigArrow});
        }
      }

      p {
        background-color: #fff;
        color: #adbf00 !important;
      }

      .arrow {
        background-image: url(${smallArrow});
      }
    }
  }
`;

export const Depoimentos = styled.section`
  width: 85%;
  margin: auto;
  background-color: #f8fbfd;
  border-radius: 50px;
  padding: 50px 40px 110px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 230px;

  @media (max-width: 1730px) {
    width: 97%;
    padding: 50px 20px 110px 20px;
  }

  @media (max-width: 750px) {
    width: 95%;
    margin-bottom: 100px;
    padding: 50px 20px 30px 20px;
  }

  h2 {
    margin: 0;
    text-align: center;
  }

  .carousel {
    width: 100%;
    display: flex;
    margin-top: 40px;
    align-items: center;
    position: relative;

    @media (max-width: 750px) {
      margin-top: 20px;
    }

    button {
      height: fit-content;
      border: none;
      background-color: transparent;
      cursor: pointer;
      position: absolute;
      z-index: 2;
      left: -20px;

      :hover {
        svg {
          fill: #afc007;
        }
      }

      svg {
        height: 45px;
        width: auto;
        fill: #002762;
        transition: all ease 0.5s;
      }
    }

    button:last-child {
      left: unset;
      right: -20px;
    }
  }

  .ant-carousel {
    width: 95%;
    margin: auto;
  }

  .slider {
    display: flex !important;
    justify-content: center;
    padding-left: 62.5px;
    position: relative;

    @media (max-width: 550px) {
      padding-left: 0;
      flex-direction: column;
    }

    .avatar {
      border-radius: 100%;
      width: 125px;
      height: 125px;
      position: absolute;
      left: 6.5%;
      top: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      @media (max-width: 1730px) {
        left: 4.5%;
      }

      @media (max-width: 1440px) {
        left: 2%;
      }

      @media (max-width: 550px) {
        position: relative;
        top: 0;
        left: unset;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }

      :after {
        content: '';
        position: absolute;
        width: 4px;
        height: 120px;
        background-color: #adbf00;
        bottom: -120px;

        @media (max-width: 550px) {
          bottom: unset;
          width: 120px;
          height: 4px;
          left: 125px;
        }
      }
    }

    .text {
      width: 83%;
      background-color: #fff;
      border-radius: 79px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 50px 20px;
      position: relative;
      height: 100%;

      @media (max-width: 1730px) {
        width: 90%;
      }

      @media (max-width: 1440px) {
        width: 95%;
      }

      @media (max-width: 550px) {
        padding: 80px 25px 50px 25px;
        width: 100%;
        top: -62.5px;
      }

      .nome {
        font-size: 24px;
        line-height: 32px;
        color: #002762;
        font-weight: bold;
        margin-bottom: 40px;
        max-width: 70%;
        text-align: center;

        @media (max-width: 750px) {
          font-size: 22px;
          margin-bottom: 30px;
          line-height: 30px;
        }
      }

      p {
        font-size: 18px;
        line-height: 33px;
        color: #313131;
        margin: 0;
        max-width: 75%;

        @media (max-width: 750px) {
          font-size: 16px;
          line-height: 30px;
        }

        @media (max-width: 550px) {
          max-width: 100%;
        }
      }
    }
  }
`;

export const Representantes = styled.section`
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 200px;
  padding: 0 20px;

  @media (max-width: 1720px) {
    width: 95%;
  }

  @media (max-width: 1440px) {
    width: 100%;
  }

  @media (max-width: 1150px) {
    flex-direction: column;
  }

  @media (max-width: 750px) {
    margin-bottom: 40px;
  }

  .text {
    max-width: 360px;
    position: relative;

    @media (max-width: 1150px) {
      align-self: flex-start;
      margin-bottom: 40px;
      left: 5%;
    }

    @media (max-width: 750px) {
      left: 0;
    }

    h2 {
      margin: 0;
    }

    .line {
      width: 170%;
      height: 4px;
      background-color: #adbf00;
      margin: 65px 0;

      @media (max-width: 1440px) {
        width: 150%;
      }

      @media (max-width: 750px) {
        margin: 40px 0;
      }

      @media (max-width: 750px) {
        width: 100%;
      }
    }

    img {
      width: 310px;
      height: auto;

      @media (max-width: 1440px) {
        width: 250px;
      }

      @media (max-width: 750px) {
        width: 150px;
      }
    }
  }

  .mapa {
    position: relative;
    top: -40px;
    height: auto;

    @media (max-width: 1330px) {
      top: unset;
      width: 37%;
    }

    @media (max-width: 1150px) {
      width: 50%;
    }

    @media (max-width: 750px) {
      width: 70%;
    }

    @media (max-width: 750px) {
      width: 100%;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: auto;
    }
  }

  .cards {
    @media (max-width: 1150px) {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 40px;
    }

    @media (max-width: 515px) {
      flex-direction: column;
      width: fit-content;
    }

    p,
    a {
      font-size: 30px;
      line-height: 43px;
      font-weight: bold;
      margin: 0;
      font-family: 'Palanquin', sans-serif;
      position: relative;

      @media (max-width: 750px) {
        font-size: 20px;
        line-height: 33px;
      }

      span {
        font-size: 18px;
        position: absolute;
        top: 23px;
        font-weight: 400;

        @media (max-width: 750px) {
          top: 17px;
          font-size: 14px;
        }
      }
    }

    a {
      padding-bottom: 20px;
      padding-top: 10px;
      display: flex;
      width: 100%;
      border-radius: 0 0 20px 20px;
      justify-content: center;
      color: #fff;
    }

    .parana {
      margin-bottom: 10px;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .verde {
      border: 3px solid #adbf00;
      border-radius: 25px;

      @media (max-width: 1150px) {
        min-width: 313.59px;
      }

      @media (max-width: 750px) {
        min-width: 227.13px;
      }

      > div {
        padding: 20px 25px 15px 25px;
      }

      p {
        color: #adbf00;
      }

      a {
        background-color: #adbf00;

        :hover {
          color: #002762;
        }
      }
    }

    .azul {
      border: 3px solid #002762;
      border-radius: 25px;
      margin-top: 20px;

      @media (max-width: 1150px) {
        margin-top: 0;
        margin-left: 20px;
      }

      @media (max-width: 515px) {
        margin-top: 20px;
        margin-left: 0px;
      }

      > div {
        padding: 20px 25px 15px 25px;
      }

      p {
        color: #002762;
      }

      a {
        background-color: #002762;

        :hover {
          color: #adbf00;
        }
      }
    }
  }
`;
